import { SchoolSettings } from '../../../types';

export const defaultSchoolSettings: SchoolSettings = {
	evenOddTimetable: false,
	dates: [
		{
			year: '2021/2022',
			start: new Date(2021, 8, 1),
			end: new Date(2022, 5, 30),
			half: new Date(2022, 0, 28),
			holidays: [
				{
					title: 'Den české státnosti',
					start: new Date(2021, 8, 28),
					end: new Date(2021, 8, 28),
				},
				{
					title: 'Podzimní prázdniny a Den vzniku samostatného československého státu',
					start: new Date(2021, 9, 28),
					end: new Date(2021, 10, 1),
				},
				{
					title: 'Den boje za svobodu a demokracii',
					start: new Date(2021, 10, 17),
					end: new Date(2021, 10, 17),
				},
				{
					title: 'Vánoční prázdniny',
					start: new Date(2021, 11, 23),
					end: new Date(2022, 0, 3),
				},
				{
					title: 'Pololetní prázdniny',
					start: new Date(2022, 0, 29),
					end: new Date(2022, 0, 29),
				},
				{
					title: 'Jarní prázdniny',
					start: new Date(2022, 1, 20),
					end: new Date(2022, 1, 28),
				},
				{
					title: 'Velikonoční prázdniny + Velikonoční pondělí',
					start: new Date(2022, 3, 1),
					end: new Date(2022, 3, 5),
				},
				{
					title: 'Svátek práce',
					start: new Date(2022, 4, 1),
					end: new Date(2022, 4, 1),
				},
				{
					title: 'Den vítězství',
					start: new Date(2022, 4, 8),
					end: new Date(2022, 4, 8),
				},
				{
					title: 'Hlavní prázdniny',
					start: new Date(2022, 6, 1),
					end: new Date(2022, 8, 31),
				},
			],
			timeline: [],
		},
	],
};

export const defaultSchoolSubjects = [
	'5f3bef458a754a2d02f8e3ab',
	'5f3bef518a754a2d02f8e3ae',
	'600a8a3f472bd67a316a7b59',
];

export default {
	defaultSchoolSubjects,
	defaultSchoolSettings,
};
