import get from './get';
import getById from './getById';
import add from './add';
import remove from './remove';
import update from './update';
import search from './search';
import searchEshop from './searchEshop';

export default {
	get,
	getById,
	add,
	remove,
	update,
	search,
	searchEshop,
};
