import { ADD_SUBJECT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { Subject, AddSubjectResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addSubjectRequest = (data: Subject, token: string) => {
	return axios.put(`${reducerConfig.api}/subject/add`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addSubjectAction = (res: AddSubjectResponse) => {
	return {
		type: ADD_SUBJECT,
		payload: res,
	};
};

const addSubject = (data: Subject, token: string): any => {
	return (dispatch: any) => {
		return addSubjectRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addSubjectAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newJob;
		});
	};
};

export default addSubject;
