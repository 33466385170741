import { QuoteDocument } from '../../../../types';

export const ADD_QUOTE = 'ADD_QUOTE';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const REMOVE_QUOTE = 'REMOVE_QUOTE';
export const GET_QUOTES = 'GET_QUOTES';

export interface AddQuoteAction {
	type: typeof ADD_QUOTE;
	payload: {
		newQuote: QuoteDocument;
	};
}

export interface UpdateQuoteAction {
	type: typeof UPDATE_QUOTE;
	payload: {
		newQuote: QuoteDocument;
	};
}

export interface GetQuotesAction {
	type: typeof GET_QUOTES;
	payload: QuoteDocument[];
}

export interface RemoveQuoteAction {
	type: typeof REMOVE_QUOTE;
	payload: {
		success: boolean;
		quoteId: string;
	};
}

export type QuoteActionTypes =
	| AddQuoteAction
	| UpdateQuoteAction
	| GetQuotesAction
	| RemoveQuoteAction;
