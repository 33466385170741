import { ADD_BOOK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { Book, GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';
import { getBookPagesAction } from '../../bookPages/actions/get';

const addBookRequest = (
	book: Pick<Book, 'type' | 'language' | 'title' | 'subject'>,
	token: string
) => {
	return axios.put(`${reducerConfig.api}/book/add`, book, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const addBookAction = (res: GetUsersResponse) => {
	return {
		type: ADD_BOOK,
		payload: res.data.newBook,
	};
};

const addBook = (
	book: Pick<Book, 'type' | 'language' | 'title' | 'subject'>,
	token: string
): any => {
	return (dispatch: any) => {
		return addBookRequest(book, token).then((res: AxiosResponse) => {
			dispatch(addBookAction(res));
			if (res.data.newBookPages) {
				getBookPagesAction(res);
			}
			return res;
		});
	};
};

export default addBook;
