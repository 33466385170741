import { GuideState } from '../../../../types';
import { START_GUIDE } from '../constants';

const startGuide = (guide: GuideState) => {
	return {
		type: START_GUIDE,
		payload: guide,
	};
};

export default startGuide;
