import Types from '../../../../types';
import { SET_BOOK_ACTIVE } from '../constants';

export const setActiveBook = (activeBook?: Types.BookActiveProps) => {
	return {
		type: SET_BOOK_ACTIVE,
		payload: activeBook || null,
	};
};

export default setActiveBook;
