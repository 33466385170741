import { RuleType } from '../../types';

const rules: RuleType = {
	static: [
		// Dashboard
		'admin:dashboard',

		// School
		'admin:schools',
		'admin:schools:update:addTeacher', // Add teacher
		'admin:schools:update',

		// Classroom
		'admin:classrooms',
		'admin:classrooms:update:timetable',
		'admin:classrooms:update:teachingPlan',
		'admin:classrooms:pairing',
		'admin:classrooms:update:groups',
		'admin:classrooms:add',
		'admin:classroom:update:addStudent',

		// Subject
		'admin:subjects:get', // Get subjects

		// User
		'admin:profile',
		'admin:users',
		'admin:users:edit',
		'admin:users:addSubject',
		'admin:users:removeSubject',
		'admin:users:setDevice',
		'admin:users:getBySchool',
		'admin:users:addClassroom',
		'admin:users:removeClassroom',

		// Invite
		'admin:invite:add',

		// Teaching plan
		'voti:teachingPlans:get', // Get teaching plans

		// Devices
		'voti:device',
		'voti:devices:update',

		// Exams
		'voti:exams',

		// ExamResult
		'voti:examResult',
		'voti:examResult:save',

		// Tags
		'voti:tags:get',

		// Repeating
		'voti:repeating:add',
		'voti:repeating:get',

		// Homeworks
		'voti:homeworks:add',
		'voti:homeworks:get',
		'voti:homeworks:update',

		// Segment
		'voti:segments',
		'voti:segments:get',

		// Pusher
		'voti:pusher:answer',

		// Repeating
		'voti:repeating:add',
		'voti:repeating:get',
		'voti:repeating:update',

		// History
		'voti:history:get',

		// QuestionReport
		'voti:questionReport:add',
		'voti:questionReport:get',
		'voti:questionReport:resolve',
		'voti:questionReport:reject',

		// CustomExam
		'voti:customExams',
		'voti:customExams:add',
		'voti:customExams:remove',

		// Quote
		'voti:quotes:get',

		// Books
		'interaktiv:books:get',

		// BookCategories
		'interaktiv:bookCategory:get',

		// BookFeedbacks
		'interaktiv:bookFeedback:add',
	],
};

export default rules;
