import { RevisionDocument, ExamDocument, RevisionActions } from '../../../../types';

export const ADD_REVISION = 'ADD_REVISION';
export const GET_REVISIONS = 'GET_REVISIONS';
export const FETCH_REVISION = 'FETCH_REVISION';
export const UPDATE_REVISION = 'UPDATE_REVISION';

export interface AddRevisionAction {
	type: typeof ADD_REVISION;
	payload: {
		newRevision: RevisionDocument;
	};
}

export interface GetRevisionsAction {
	type: typeof GET_REVISIONS;
	payload: RevisionDocument[];
}

export interface FetchRevisionAction {
	type: typeof FETCH_REVISION;
	payload: RevisionDocument[];
}

export interface UpdateRevisionAction {
	type: typeof UPDATE_REVISION;
	payload: {
		success: boolean;
		newRevisions: RevisionDocument[];
		newExams: ExamDocument[];
		revisionId: string;
		action: RevisionActions;
	};
}

export type RevisionActionTypes =
	| AddRevisionAction
	| GetRevisionsAction
	| FetchRevisionAction
	| UpdateRevisionAction;
