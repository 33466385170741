import { RuleType } from '../../types';

const rules: RuleType = {
	static: [
		// Dashboard
		'admin:dashboard',

		// Profile
		'admin:profile',
		'admin:users:edit',

		// School
		'admin:schools',

		// Subject
		'admin:subjects:get',

		// Classroom
		'admin:classroom:update:addStudent',

		// Users
		'admin:users',

		// Devices
		'voti:devices:update',

		// Homeworks
		'voti:homeworks:get',
		'voti:homeworks:update',

		// Segments
		'voti:segments:get',

		// Pusher
		'voti:pusher:answer',

		// Repeating
		'voti:repeating:get',
		'voti:repeating:update',

		// Books
		'interaktiv:books:get',

		// BookCategories
		'interaktiv:bookCategory:get',

		// BookFeedbacks
		'interaktiv:bookFeedback:add',
	],
};

export default rules;
