import { BookDocument } from '../../../../types';

export const GET_BOOKS = 'GET_BOOKS';
export const ADD_BOOK = 'ADD_BOOK';
export const UPDATE_BOOK = 'UPDATE_BOOK';

export interface GetBooksAction {
	type: typeof GET_BOOKS;
	payload: {
		success: boolean;
		books: BookDocument[];
	};
}

export interface AddBookAction {
	type: typeof ADD_BOOK;
	payload: {
		success: boolean;
		newBook: BookDocument;
	};
}

export interface UpdateBookAction {
	type: typeof UPDATE_BOOK;
	payload: {
		success: boolean;
		newBook: BookDocument;
	};
}

export type BookActionTypes = GetBooksAction | AddBookAction | UpdateBookAction;
