import { ADD_BOOK_PAGES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const addBookPageRequest = (bookId: string, token: string) => {
	return axios.put(
		`${reducerConfig.api}/book/addPage`,
		{ bookId },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const addBookPageAction = (res: GetUsersResponse) => {
	return {
		type: ADD_BOOK_PAGES,
		payload: res.data,
	};
};

const addBookPage = (bookId: string, token: string): any => {
	return (dispatch: any) => {
		return addBookPageRequest(bookId, token).then((res: AxiosResponse) => {
			dispatch(addBookPageAction(res));
			return res;
		});
	};
};

export default addBookPage;
