import axios from 'axios';
import reducerConfig from '../../reducerConfig';

const claimVoucher = (voucherId: string, codeId: string, email: string) => {
	return axios.post(`${reducerConfig.api}/voucher/claim`, {
		voucherId,
		codeId,
		email,
	});
};

export default claimVoucher;
