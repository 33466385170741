import { SnackbarResponse } from '../../../types';
import { ADD_SNACKBAR, CLEAR_SNACKBAR, SnackbarActionTypes } from './constants';

const initialState: SnackbarResponse = [];

const users = (state = initialState, action: SnackbarActionTypes) => {
	switch (action.type) {
		case ADD_SNACKBAR:
			return [...state, ...action.payload];

		case CLEAR_SNACKBAR:
			return state.map(snackbar => {
				snackbar.shown = true;
				return snackbar;
			});

		default:
			return state;
	}
};

export default users;
