import { TeachingPlanDocument } from '../../../types';
import {
	ADD_TEACHING_PLAN,
	REMOVE_TEACHING_PLAN,
	UPDATE_TEACHING_PLAN,
	GET_TEACHING_PLANS,
	TeachingPlanActionTypes,
} from './constants';

const initialState: TeachingPlanDocument[] = [];

const teachingPlans = (state = initialState, action: TeachingPlanActionTypes) => {
	switch (action.type) {
		case GET_TEACHING_PLANS:
			return action.payload;

		case ADD_TEACHING_PLAN:
			return [...state, action.payload.newTeachingPlan];

		case REMOVE_TEACHING_PLAN:
			return state.filter(teachingPlan => teachingPlan._id !== action.payload.teachingPlanId);

		case UPDATE_TEACHING_PLAN:
			return state.map(teachingPlan => {
				if (teachingPlan._id === action.payload.newTeachingPlan._id) {
					return {
						...teachingPlan,
						...action.payload.newTeachingPlan,
					};
				}
				return teachingPlan;
			});

		default:
			return state;
	}
};

export default teachingPlans;
