import { GET_INVITES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetInvite, GetInviteResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getInviteRequest = (data: GetInvite) => {
	return axios.get(`${reducerConfig.api}/invite/get`, {
		params: data,
	});
};

const getTeachingPlanAction = (res: GetInviteResponse) => {
	return {
		type: GET_INVITES,
		payload: res.data,
	};
};

const getInvites = (data: GetInvite): any => {
	return (dispatch: any) => {
		return getInviteRequest(data).then((res: AxiosResponse) => dispatch(getTeachingPlanAction(res)));
	};
};

export default getInvites;
