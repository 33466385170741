import { AxiosResponse } from 'axios';
import { Socket } from 'socket.io-client';
import { Request as ExpressRequest, Response as ExpressResponse } from 'express';
import { Design } from 'react-email-editor';
import { Step as GuideStep } from 'intro.js-react';
import { Options } from 'intro.js';

/* ----------------
   Global override
----------------- */
declare global {
	namespace Express {
		interface Request {
			user?: {
				sub: string;
			};
		}
	}
}

/* ----------------
   CRO
----------------- */
export type CroSearchResult = {
	cin: string;
	city: string;
	country: string;
	name: string;
	redizo: string;
	street: string;
	id: number;
	zip: string;
};

/* ----------------
   Translation
----------------- */
export enum LanguagesList {
	sk = 'sk',
	cs = 'cs',
	ro = 'ro',
}
export type Languages = keyof typeof LanguagesList;

export type Translation = {
	lang: Languages;
	data: any;
};

export type TranslationDocument = Translation & MongoId;

export type TranslationValue = {
	value: string;
	pluralMode?: TranslationPluralModes;
	lang: Languages;
};

export type TranslationPath = { path: string; values: TranslationValue[]; plural?: boolean };

export type TranslationPluralModes = 'one' | 'more' | 'many';

/* ----------------
   Common
----------------- */
export type Projects = 'voti' | 'interaktiv' | 'central' | 'eshop';

export interface AuthContextInterface {
	authenticated: boolean;
	user: User;
	token: string;
	login: (
		email: string,
		password: string,
		project: Projects,
		language: Languages
	) => Promise<LoginResponse | undefined>;
	loginAs: (userId: string) => Promise<LoginResponse | undefined>;
	logout: () => void;
	silentAuth: () => void;
	setSession: (data: User) => void;
}

export type MinMaxType = {
	min?: number;
	max?: number;
};

export enum MinMaxOptionsList {
	lessThan = 'lessThan',
	moreThan = 'moreThan',
	fromTo = 'fromTo',
}
export type MinMaxOptions = keyof typeof MinMaxOptionsList;

export enum ComponentTypesList {
	text = 'text',
	image = 'image',
	audio = 'audio',
}
export type ComponentTypes = keyof typeof ComponentTypesList;

export enum AnswerTypesList {
	single = 'single',
	multi = 'multi',
	sort = 'sort',
}
export type AnswerTypes = keyof typeof AnswerTypesList;

export enum DaysList {
	sunday = 'sunday',
	monday = 'monday',
	tuesday = 'tuesday',
	wednesday = 'wednesday',
	thursday = 'thursday',
	friday = 'friday',
	saturday = 'saturday',
}
export type Days = keyof typeof DaysList;

export enum SchoolDaysList {
	monday = 'monday',
	tuesday = 'tuesday',
	wednesday = 'wednesday',
	thursday = 'thursday',
	friday = 'friday',
}
export type SchoolDays = keyof typeof DaysList;

export enum SchoolYears {
	prev = '2019/2020',
	this = '2021/2022',
	next = '2022/2023',
}

export type Cartesian = {
	x: number;
	y: number;
};

export type Years = '2019/2020' | '2020/2021' | '2021/2022';

export type Week = 'even' | 'odd';

export enum States {
	new = 'new',
	review = 'review',
	approved = 'approved',
}

export enum TerminalLogColors {
	Reset = '\x1b[0m%s\x1b[0m',
	Bright = '\x1b[1m%s\x1b[0m',
	Dim = '\x1b[2m%s\x1b[0m',
	Underscore = '\x1b[4m%s\x1b[0m',
	Blink = '\x1b[5m%s\x1b[0m',
	Reverse = '\x1b[7m%s\x1b[0m',
	Hidden = '\x1b[8m%s\x1b[0m',
	FgBlack = '\x1b[30m%s\x1b[0m',
	FgRed = '\x1b[31m%s\x1b[0m',
	FgGreen = '\x1b[32m%s\x1b[0m',
	FgYellow = '\x1b[33m%s\x1b[0m',
	FgBlue = '\x1b[34m%s\x1b[0m',
	FgMagenta = '\x1b[35m%s\x1b[0m',
	FgCyan = '\x1b[36m%s\x1b[0m',
	FgWhite = '\x1b[37m%s\x1b[0m',
	FgGray = '\x1b[90m%s\x1b[0m',
	BgBlack = '\x1b[40m%s\x1b[0m',
	BgRed = '\x1b[41m%s\x1b[0m',
	BgGreen = '\x1b[42m%s\x1b[0m',
	BgYellow = '\x1b[43m%s\x1b[0m',
	BgBlue = '\x1b[44m%s\x1b[0m',
	BgMagenta = '\x1b[45m%s\x1b[0m',
	BgCyan = '\x1b[46m%s\x1b[0m',
	BgWhite = '\x1b[47m%s\x1b[0m',
	BgGray = '\x1b[100m%s\x1b[0m',
}

export type QuestionStates = keyof typeof States;

export type ExerciseStates = QuestionStates | 'generated';

export type ExamStates = ExerciseStates;

export type Request = ExpressRequest;

export type Response = ExpressResponse;

export type SnackbarTypes = 'success' | 'error' | 'info';

export type Snackbar = {
	type: SnackbarTypes;
	title?: string;
	message: string;
	shown: boolean;
};
export type SnackbarResponse = Snackbar[];

export type Counter = {
	id: string;
	seq: number;
};

export type Shortid = {
	id: string;
	shortid: string;
};

export type MongoId = {
	_id: string;
};

export type CreatedBy = {
	createdBy: string;
};

export type CreatedDate = {
	createdDate: Date;
};

export type MongooseUpdate = { [key: string]: { [key: string]: any } };

export type MongooseReturnFields = { [key: string]: number };

export type MongooseSelect = { [key: string]: any };

export type MongooseOptions = {
	new?: boolean;
	fields?: MongooseReturnFields;
	arrayFilters?: { [key: string]: any }[];
};

export type MongooseSort = { [key: string]: 1 | -1 };

export type MongooseQuerySort = {
	page: number;
	pageSize: number;
	orderBy?: string;
	orderDirection?: 'asc' | 'desc';
};

export interface UpdateSchoolProps {
	data: UpdateSchool['data'];
	select: MongooseSelect;
	update: MongooseUpdate;
	options: MongooseOptions;
}

export type UpdateClassroomProps = Omit<UpdateSchoolProps, 'data'> & {
	data: UpdateClassroom['data'];
};

export interface UpdateExamProps {
	data: UpdateExam['data'];
	select: MongooseSelect;
	update: MongooseUpdate;
	options: MongooseOptions;
	userId: string;
}

export interface UpdateQuestionProps {
	data: UpdateQuestion['data'];
	select: MongooseSelect;
	update: MongooseUpdate;
	options: MongooseOptions;
	userId: string;
}

export interface UpdateTeachingPlanProps {
	data: UpdateTeachingPlan['data'];
	select: MongooseSelect;
	update: MongooseUpdate;
	fields: MongooseReturnFields;
}

export interface UpdateSegmentProps {
	data: UpdateSegment['data'];
	select: MongooseSelect;
	update: MongooseUpdate;
	fields: MongooseReturnFields;
}

export type MultiSelectData = MongoId & {
	title: string;
};

export type AncestorData = MongoId & {
	title: string;
	sort: number;
	hours: number;
	hasChildren: boolean;
	parent: string;
	segments: string[];
	part?: number;
};

export type TreeData = MongoId & {
	title: string;
	expanded?: boolean;
	sort: number;
	hours: number;
	hasChildren: boolean;
	segments: string[];
	children?: TreeData[];
};

export type ImageSizes = 'large' | 'medium' | 'thumbnail';

export type TimetableHours = {
	from: string;
	to: string;
};

export type ChapterHours = {
	[key: string]: {
		sum: number;
		hasChildren: boolean;
	};
};

export type VotiButtons = 'A' | 'B' | 'C' | 'D';

export type UsbDevice = {
	serial: number;
	fw: number;
	revision: number;
	cheksum: number;
	device_id: number;
	app_id: number;
	user_id: number;
	info?: DeviceDocument;
};

export type RfPairing = {
	userId: number;
	loggedIn: boolean;
	battery: number;
	skip?: boolean;
	rfError?: boolean;
};

export type DeviceRegs = {
	Access: string;
	Category: string;
	ConfigUser: boolean;
	Description: string;
	Id: number;
	IsVisible: boolean;
	Label: string;
	Max: number;
	Min: number;
	Name: string;
	NewValue: string;
	Reset: string;
	Units: string;
	Value: string;
	VarType: string;
};

export enum DateFormat {
	default = 'DD. MMMM YYYY HH:mm',
	date = 'DD. MMMM YYYY',
}

export type Migration = {
	title: string;
	mysqlTables: string[];
	tableTransform: MigrationTableTransform;
	count: number;
	groups: MigrationGroup[];
	action: MigrationActions;
	state?: MigrationStates;
	skip?: number;
	limit?: number;
};

export type MigrationGroup = {
	action: string;
	count: number;
	resolve?: string;
};

export type MigrationTableTransform = { [type: string]: any };

export type MigrationTable = {
	title: string;
	action: MigrationActions;
	state: MigrationStates;
	groups: MigrationGroup[];
	skip: number;
	limit: number;
	count: number;
};

export type MigrationStates = 'unimported' | 'importing' | 'imported';

export type MigrationActions =
	| 'users'
	| 'eshopUsers'
	| 'usersInvalid'
	| 'classrooms'
	| 'schools'
	| 'licences'
	| 'books'
	| 'booksPages'
	| 'areas'
	| 'bonuses'
	| 'files';

/* ----------------
   Interaktivita
----------------- */
export type BookTypes = 'book' | 'notebook' | 'workbook';

export type BookChapter = {
	title: string;
	page: number;
};

export type BookChapterDocument = MongoId & BookChapter;

export type Book = {
	id: number; // TODO: remove after migration
	title: string;
	image: string;
	type: BookTypes;
	versionId: number;
	subject: string;
	chapters: BookChapter[];
	editors: string[];
	year: number;
	format: 'H' | 'V';
	public: boolean;
	parentVersion: number;
	language: string;
	test?: string;
	testParams?: string;
	new: boolean;
	eshopUrl?: string;
	createdDate: Date;
	modifiedDate: Date;
	createdBy: string;
};

export type UpdateBook = {
	bookId: string;
	data: {
		title: string;
		subject: string;
		year: number;
		type: BookTypes;
		isPublic: boolean;
	};
};

export type UpdateBookRequest = Request & {
	body: UpdateBook;
};

export type UpdateBookResponse = AxiosResponse & {
	data: {
		newBook: BookDocument;
		success: boolean;
	};
};

export type BookDocument = Omit<Book, 'chapters'> &
	MongoId & {
		chapters: BookChapterDocument[];
	};

export type BookPage = {
	id: number;
	bookId: string;
	title: string;
	version: number;
	page: number;
	image: string;
	overlays: string[];
	audio: Audio[];
	areas: Area[];
	bonuses: Bonus[];
};

export type BookPageDocument = Omit<BookPage, 'audio' | 'areas' | 'bonuses'> & {
	areas: AreaDocument[];
	bonuses: BonusDocument[];
	audio: AudioDocument[];
} & MongoId;

export type UpdateBookPage = {
	pageId: string;
	data: {
		key?: number;
		areaId?: string;
		audioId?: string;
		bonusId?: string;
		value?: any;
	};
};

export type UpdateBookPageRequest = Request & {
	body: UpdateBookPage;
};

export type UpdateBookPageResponse = AxiosResponse & {
	data: {
		newPage: BookPageDocument;
		success: boolean;
	};
};

export type UpdateBookPageActions =
	| 'update/areaPosition'
	| 'update/areaLayer'
	| 'update/areaType'
	| 'update/areaCanvas'
	| 'update/overlay'
	| 'update/image'
	| 'update/bonus'
	| 'update/title'
	| 'update/empty'
	| 'update/audio'
	| 'add/overlay'
	| 'add/area'
	| 'add/audio'
	| 'add/areaResult'
	| 'add/bonus'
	| 'remove/overlay'
	| 'remove/area'
	| 'remove/audio'
	| 'remove/bonus'
	| 'remove/areaResult';

export interface UpdateBookPageProps {
	data: UpdateBookPage['data'];
	select: MongooseSelect;
	update: MongooseUpdate;
	options: MongooseOptions;
}

export type UpdateBookPagesSort = {
	bookId: string;
	data: {
		source: number;
		target: number;
	};
};

export type UpdateBookPagesSortRequest = Request & {
	body: UpdateBookPagesSort;
};

export type UpdateBookPagesSortResponse = AxiosResponse & {
	data: {
		newBookPages: BookPageDocument[];
		success: boolean;
	};
};

export type BookFeedback = {
	bookId: string;
	userId: string;
	email: string;
	phone: string;
	message: string;
	state: BookFeedbackStates;
};

export type BookFeedbackStates = 'new' | 'seen' | 'resolved';

export type BookFeedbackDocument = BookFeedback & CreatedDate & MongoId;

export type BookFeedbackFilter = {
	email?: string;
};

export type BookOrientation = 'horizontal' | 'vertical';

export type BookCategory = {
	title: string;
	icon: string;
	books: string[];
	userId?: string;
};

export type BookCategoryDocument = BookCategory & MongoId & CreatedDate;

export type Area = {
	id: number; // TODO: remove after migration
	versionId: number;
	exerciseId: number;
	position: {
		top: number;
		right: number;
		bottom: number;
		left: number;
	};
	type: AreaTypes;
	layer: number;
	results: number[];
	options: string[];
	group: Number;
	canvas: String;
	fontSize: Number;
	sort: Number;
};

export type AreaDocument = Area & MongoId;

export type AreaTypes =
	| 'active'
	| 'passive'
	| 'both'
	| 'choice'
	| 'canvas'
	| 'image'
	| 'node'
	| 'text'
	| 'sound'
	| 'result';

export type Bonus = {
	title: string;
	image: string;
	width: number;
	height: number;
	module: string;
	bookId: String;
	chapterId: number;
	position: {
		isPoint: boolean;
		top: number;
		left: number;
	};
	url: string;
	type: BonusTypes;
	theme: number;
	color: string;
	sort: number;
	params: string;
	html: string;
	textHeight: number;
	images: BonusImage[];
};

export type BonusDocument = Bonus & MongoId;

export type BonusTypes = 'module' | 'risk' | 'video' | 'quiz' | 'link' | 'presentation';

export type BonusModuleGroup = {
	title: string;
	params: {
		title: string;
		param: string;
		value: string;
		optional?: boolean;
	}[];
	demo: string;
	alias: string[];
	vocabulary: string;
	editor?: string;
};

export type BonusImage = {
	src: string;
	title: string;
};

export type Audio = {
	mp3: string;
	ogg: string;
	x: number;
	y: number;
	ua?: boolean;
};
export type AudioDocument = Audio & MongoId;

export type BookEditModes = 'preview' | 'layers' | 'areas' | 'audio' | 'bonuses';

export type BookEditTransformModes =
	| 'workspace'
	| 'areaMove'
	| 'areaResize'
	| 'areaDetail'
	| 'bonus'
	| 'zoom';

export type BookAreaTools = 'select' | 'add' | 'connect';

export type BookPageDimensions = {
	width: number;
	height: number;
	orientation: BookOrientation;
};

export type BookActiveProps = {
	bookId: string;
	visiblePages: number[];
	controls: boolean;
	legend: boolean;
	bonuses: boolean;
	audio?: AudioDocument;
	uaAudio?: boolean;
	zoom: number;
	pageDimensions: BookPageDimensions;
	drawing: BookActiveDrawingProps;
	editor: BookActiveEditorProps;
	canvas: BookActiveCanvasProps;
} | null;

export type BookActiveDrawingProps = {
	active: boolean;
	mode: 'pencil' | 'eraser';
	size: number;
	color: string;
};

export type BookActiveEditorProps = {
	mode: BookEditModes;
	tool: BookAreaTools;
	selected: number[];
	layer: {
		left: number;
		right: number;
	};
	transform: {
		mode: BookEditTransformModes;
		x: number;
		y: number;
		z: number;
	};
};

export type BookActiveCanvasProps = {
	drawing?: string;
	speed: 'normal' | 'fast';
};

/* ----------------
   Quotes
----------------- */
export type Quote = {
	title: string;
	subject: string;
	author: string;
};

export type QuoteDocument = MongoId & Quote & CreatedBy & CreatedDate;

/* ----------------
   Guide
----------------- */
export type GuideState = {
	active: boolean;
	steps: GuideStep[];
	options: Options;
	onComplete?: () => void;
};

/* ----------------
   Invite
----------------- */

export type Invite = {
	inviter: string;
	user: {
		_id?: string;
		degree?: string;
		firstName?: string;
		lastName?: string;
		phone?: string;
		email: string;
		role: RoleTypes;
		projectRole?: CustomerRoleTypes | EmployeeRoleTypes;
	};
	project: Projects;
	language: Languages;
	schoolId: string;
	classroomId?: string;
	state: InviteStates;
};

export type InviteResult = {
	email: string;
	state: 'success' | 'invalidEmail' | 'isInvited' | 'isMember' | 'isTeacher' | 'isStudent';
};

export type InviteStates = 'new' | 'pwd' | 'confirmed' | 'rejected';

export type InviteDocument = Invite & MongoId & CreatedDate;

export type AddInviteRequest = Request & {
	body: Invite;
};

export type GetInvite = {
	inviteId?: string;
	email?: string;
	classroomId?: string;
};

export type GetInviteRequest = Request & {
	body: GetInvite;
};

export type AddInviteResponse = AxiosResponse & {
	data: {
		snackbars: SnackbarResponse;
		success: boolean;
	};
};

export type GetInviteResponse = AxiosResponse & {
	data: InviteDocument[];
};

export type ConfirmInviteRequest = Request & {
	body: {
		inviteId: string;
	};
};

export type RejectInviteRequest = Request & {
	body: {
		inviteId: string;
	};
};

export type ConfirmInviteResponse = AxiosResponse & {
	data: {
		snackbars: SnackbarResponse;
		success: boolean;
	};
};

/* ----------------
   Email
---------------- */
export type EmailTemplate = {
	subject: string;
	design: Design;
	html: string;
	language: Languages;
	category: EmailCategory;
	slug: string;
};

export type EmailTemplateDocument = EmailTemplate & MongoId & CreatedDate & CreatedBy;

export type EmailTemplateUpdate = MongoId & {
	subject?: EmailTemplate['subject'];
	design?: EmailTemplate['design'];
	html?: EmailTemplate['html'];
	language?: EmailTemplate['language'];
	category?: EmailTemplate['category'];
	slug?: EmailTemplate['slug'];
};

export enum EmailCategoryList {
	register = 'register',
	password = 'password',
	notification = 'notification',
	error = 'error',
}
export type EmailCategory = keyof typeof EmailCategoryList;

/* ----------------
   ElasticSearch
----------------- */
export type ElasticSearchTag = MongoId & {
	_index: string;
	_type: string;
	_score: number;
	fields: {
		id: string[];
		title: string[];
	};
};

export type ElasticSearchRoutes = 'tags' | 'segments' | 'questions';

/* ----------------
   Pusher
----------------- */

export type PusherInvitesRefresh = {
	users: string[];
};

export type PusherNotificationsRefresh = {
	users: string[];
};

export type PusherSchoolRefresh = {
	schoolId: string;
};

export type PusherRevisionUpdate = {
	revisionId: string;
	userId?: string;
};

export type PusherDeviceRefresh = {
	deviceId: string;
};

export type PusherDeviceAnswer = {
	deviceId: string;
	studentCode: string;
	keyCode: KeyCodes;
};

export type PusherHomeworkSent = {
	homeworkId: string;
};

export type PusherRepeatingRefresh = {
	repeatingId: string;
};

/* ----------------
   Notification
---------------- */

export type Notification = {
	title: string;
	description: string;
	source: NotificationSource;
	target: NotificationTarget;
	state: NotificationStates;
} & CreatedBy;

export type NotificationSource = {
	users: string[];
};

export type NotificationTarget = {
	userId: string;
	notificationType: NotificationTypes;
	link: string;
	data: {
		repeatingId?: string;
		itemId?: string;
		inviteId?: string;
		schoolId?: string;
		classroomId?: string;
	};
};

export type NotificationDocument = Notification & MongoId & CreatedDate;

export type NotificationTypes =
	| 'repeatingNew'
	| 'repeatingResult'
	| 'examNew'
	| 'examResults'
	| 'inviteNew'
	| 'inviteResult';

export type NotificationStates = 'new' | 'seen';

export type NotificationActions = 'update/seen';

/* ----------------
   Upload
---------------- */

export type UploadFileTypes =
	| 'image/jpeg'
	| 'image/jpeg,image/png'
	| 'image/png'
	| 'audio/mpeg,audio/wav,audio/ogg'
	| 'audio/mpeg'
	| 'audio/wav'
	| 'audio/ogg'
	| 'image/jpeg,image/png,audio/mpeg,audio/wav,audio/ogg';

export type UploadFolders = 'school' | 'book' | 'exam' | 'exercise' | 'question';

export type UploadResponse = AxiosResponse & {
	data: {
		files: [];
	};
};

export type UploadFile = {
	file?: File;
	base64?: string;
	path?: string;
};

export type TaktikFile = {
	path: string;
	type: UploadFileTypes;
	width?: number;
	height?: number;
	loaded?: HTMLAudioElement | HTMLImageElement;
};

/* ----------------
   Tag
----------------- */
export type Tag = {
	title: string;
};

export type TagDocument = Tag & MongoId & CreatedBy & CreatedDate;

export type AddTagRequest = Request & {
	body: {
		title: string;
	};
};

export type AddTagResponse = AxiosResponse & {
	data: {
		success: boolean;
		newTag: TagDocument;
	};
};

export type SearchTagRequest = Request & {
	body: {
		query: string;
		subject: string;
		grade: number;
	};
};

export type SearchTagResponse = AxiosResponse & {
	data: TagDocument[];
};

export type GetTagsResponse = AxiosResponse & {
	data: TagDocument[];
};

/* ----------------
   Term
----------------- */
export type Term = {
	year: string;
	start: Date;
	end: Date;
	half: Date;
	holidays: Holiday[];
	timeline: Timeline[];
};

export type TimelineEvent = {
	date: Date;
	dateDue?: Date;
	chapter?: TeachingPlanChapter;
	hour: number;
	key: string;
	exam: ExamDocument;
	answers: UserAnswers[];
};

export type TimelineEventDocument = MongoId & TimelineEvent;

export type Timeline = {
	date: Date;
	day: number;
	isWeekend: boolean;
	isHoliday: boolean;
};

export type TimelineDocument = Timeline & {
	_id?: string;
};

export type TermDocument = Omit<Term, 'holidays' | 'timeline'> &
	MongoId & {
		holidays: HolidayDocument[];
		timeline: TimelineDocument[];
	};

export type Holiday = {
	title: string;
	start: Date;
	end: Date;
};

export type HolidayDocument = Holiday & MongoId;

export type AddTermResponse = AxiosResponse & {
	data: {
		newTerm: TermDocument;
		snackbars?: SnackbarResponse;
		success: boolean;
	};
};

/* ----------------
   School
----------------- */

export type School = {
	shortId: string;
	taktikId: number;
	title: string;
	cin: string;
	redizo: number;
	address: {
		city: string;
		street: string;
		zip: number;
		country: 'sk' | 'cs';
	};
	type: SchoolTypes;
	upload?: TaktikFile;
	teachers: SchoolTeacher[];
	subjects: string[];
	settings: SchoolSettings;
	licences?: {
		interaktiv: InteraktivLicence[];
	};
};

export type SchoolTeacher = MongoId & {
	isAdmin: boolean;
};

export type SchoolDocument = Omit<School, 'settings' | 'licences'> &
	MongoId &
	CreatedBy &
	CreatedDate & {
		settings: SchoolSettingsDocument;
		licences?: {
			interaktiv: InteraktivLicenceDocument[];
		};
	};

export type SchoolSettings = {
	evenOddTimetable: boolean;
	dates: Term[];
};

export type SchoolSettingsDocument = Omit<SchoolSettings, 'dates'> & {
	dates: TermDocument[];
};

export enum SchoolTypesList {
	ms = 'ms',
	zs = 'zs',
	ss = 'ss',
	vs = 'vs',
}
export type SchoolTypes = keyof typeof SchoolTypesList;

export type SchoolFilter = {
	_id?: string;
	_ids?: string[];
	classroomId?: string;
	classroomIds?: string[];
	taktikId?: number;
	title?: string;
	address?: string;
	search?: string;
	type?: SchoolTypes;
};

/* ----------------
   Classroom
----------------- */

export type Classroom = {
	schoolId: string;
	shortId: string;
	title: string;
	grade: number;
	students: ClassroomStudent[];
	groups: ClassroomGroup[];
	pairing: PairingTypes;
	// timetables: ClassroomTimetable[];
	isGroup?: boolean;
};

export type PairingTypes = 'auto' | 'custom';

export type ClassroomTeachingPlan = {
	originalId: string;
	title: string;
	startDate: Date;
	chapters: TeachingPlanChapter[];
	timeline: TeachingPlanTimeline[];
};

export type TeachingPlanTimeline = {
	date: Date;
	chapterId: string;
	part: number;
	hour: number;
	exams: ExamDocument[];
};

export type ClassroomTeachingPlanDocument = ClassroomTeachingPlan & MongoId;

export type ClassroomDocument = Omit<Classroom, 'timetables'> &
	MongoId &
	CreatedBy &
	CreatedDate & {
		timetables: ClassroomTimetableDocument[];
	};

export type ClassroomStudent = MongoId & {
	isAdmin: boolean;
	state: ClassroomStudentStates;
	keyboard: StudentKeyboard;
};

export type ClassroomStudentStates = 'invited' | 'verified' | 'imported';

export type ClassroomGroup = {
	_id: string;
	title: string;
	students: string[];
};

export type ClassroomTimetable = {
	teacherId: string;
	subjectId: string;
	hours: TimetableHour[];
	teachingPlan?: ClassroomTeachingPlan;
	classroomId: string;
};

export type TimetableHour = {
	day: number;
	hour: number;
	week?: Week;
};

export type TimetableHourDocument = TimetableHour & MongoId;

export type ClassroomTimetableDocument = Omit<ClassroomTimetable, 'teachingPlan' | 'hours'> &
	MongoId & {
		teachingPlan?: ClassroomTeachingPlanDocument;
		hours: TimetableHourDocument[];
	};

/* ----------------
   School request
----------------- */

export type AddSchool = Pick<School, 'title' | 'type'>;

export type AddSchoolRequest = Request & {
	body: AddSchool;
};

export type RemoveSchoolRequest = Request & {
	body: {
		schoolId: string;
	};
};

export type UpdateSchool = {
	schoolId: string;
	data: {
		value?: any;
		teacherId?: string;
		classroomId?: string;
		timetableId?: string;
		teachingPlanId?: string;
		subjectId?: string;
		chapterId?: string;
		termId?: string;
		termType?: 'start' | 'half' | 'end' | 'holidayStart' | 'holidayEnd';
		holidayId?: string;
		licenceId?: string;
		licenceAction?: 'extendBy' | 'setTo';
		bookIds?: string[];
	};
};

export type UpdateSchoolRequest = Request & {
	body: UpdateSchool;
};

export type UpdateSchoolActions =
	| 'add/teachers'
	| 'update/evenOddTimetable'
	| 'update/term'
	| 'update/teacherIsAdmin'
	| 'update/upload'
	| 'update/title'
	| 'update/type'
	| 'remove/teacher'
	| 'remove/subject'
	| 'add/subject'
	| 'remove/licence'
	| 'update/licence'
	| 'update/licences';

/* ----------------
   School response
----------------- */

export type AddSchoolResponse = AxiosResponse & {
	data: {
		newSchool: SchoolDocument;
		snackbars?: SnackbarResponse;
		success: boolean;
	};
};

export type RemoveSchoolResponse = AxiosResponse & {
	data: {
		snackbars?: SnackbarResponse;
		success: boolean;
	};
};

export type UpdateSchoolResponseData = {
	newSchool: SchoolDocument;
	snackbars?: SnackbarResponse;
	success: boolean;
};

export type UpdateSchoolResponse = AxiosResponse & {
	data: UpdateSchoolResponseData;
};

/* ----------------
   Device
----------------- */

export enum DeviceTypesList {
	KEY = 'KEY',
	CU = 'CU',
}
export type DeviceTypes = keyof typeof DeviceTypesList;

export enum DeviceIdsList {
	KEY = 7022,
	CU = 7011,
}

export type Device = {
	title: string;
	schoolId: string;
	serialNumber: number;
	fw: number;
	revision: number;
	cheksum: number;
	device_id: number;
	app_id: number;
	user_id: number;
	type: DeviceTypes;
	cuProps?: {
		session: CuDeviceSession;
		mode: DeviceModes;
		exam?: ExamDocument;
		answers: UserAnswers[];
		log: DeviceLog[];
		startDate: Date;
	};
	keyProps?: {
		battery: number;
	};
};

export type DeviceDocument = Device & MongoId & CreatedBy & CreatedDate;

export type CuDeviceSession = {
	classroomId?: string;
	questionId?: string;
	teacherId?: string;
};

export type DeviceLog = {
	comment: String;
} & CreatedDate;

/* ----------------
   Device request
----------------- */
export type AddDeviceRequest = Request & {
	body: Device;
};

export type RemoveDeviceRequest = Request & {
	body: {
		deviceId: string;
	};
};

export type UpdateDeviceRequest = Request & {
	body: UpdateDevice;
};

export type UpdateDevice = {
	deviceId: string;
	data: {
		userId?: string | number;
		segments?: string[];
		questionId?: string;
		answerId?: string;
		answerState?: UserAnswersState;
		schoolId?: string;
		mode?: DeviceModes;
		studentCode?: string;
		serialNumber?: number;
		keySerial?: number;
		cuSerial?: number;
		keyCode?: KeyCodes;
		keyId?: number;
		classroomId?: string;
		students?: string[];
		exam?: Exam;
		title?: string;
		battery?: number;
	};
};

export type DeviceModes = 'offline' | 'online' | 'pair' | 'exam' | 'results';

export type UpdateDeviceActions =
	| 'update/title'
	| 'update/schoolId'
	| 'update/exam'
	| 'update/question'
	| 'update/answer'
	| 'update/classroom'
	| 'update/serialNumber'
	| 'update/state'
	| 'update/cuPair'
	| 'update/keyPair'
	| 'update/keyBattery'
	| 'update/unpair'
	| 'update/mode';

export interface UpdateDeviceProps {
	data: UpdateDevice['data'];
	select: MongooseSelect;
	update: MongooseUpdate;
	options: MongooseOptions;
	callback: () => void;
	responseData: any;
}

/* ----------------
   Device response
----------------- */

export type AddDeviceResponse = AxiosResponse & {
	data: {
		newDevice: DeviceDocument;
		success: boolean;
		snackbars?: SnackbarResponse;
	};
};

export type RemoveDeviceResponse = AxiosResponse & {
	data: {
		snackbars?: SnackbarResponse;
		success: boolean;
	};
};

export type UpdateDeviceResponse = AxiosResponse & {
	data: {
		success: boolean;
		snackbars?: SnackbarResponse;
		newDevice: DeviceDocument;
	};
};

/* ----------------
   Classroom request
----------------- */

export type AddClassroom = Pick<Classroom, 'grade' | 'title' | 'schoolId'>;

export type AddClassroomRequest = Request & {
	body: AddClassroom;
};

export type RemoveClassroomRequest = Request & {
	body: {
		classroomId: string;
	};
};

export type UpdateClassroom = {
	classroomId: string;
	data: {
		value?: any;
		userId?: string;
		teacherId?: string;
		timetableId?: string;
		teachingPlanId?: string;
		subjectId?: string;
		chapterId?: string;
		deviceId?: string;
		groupId?: string;
	};
};

export type UpdateClassroomActions =
	| 'add/timetable'
	| 'add/teachingPlan'
	| 'add/teachingPlanChapter'
	| 'add/teachingPlanSegment'
	| 'add/student'
	| 'add/group'
	| 'add/groupStudents'
	| 'remove/group'
	| 'remove/groupStudent'
	| 'remove/timetable'
	| 'remove/teachingPlan'
	| 'remove/teachingPlanChapter'
	| 'remove/teachingPlanSegment'
	| 'remove/student'
	| 'update/grade'
	| 'update/title'
	| 'update/teachingPlanChapters'
	| 'update/teachingPlanHours'
	| 'update/pairing'
	| 'update/customPair';

export type UpdateClassroomRequest = Request & {
	body: UpdateClassroom;
};

export type UpdateClassroomResponseData = {
	newClassroom: ClassroomDocument;
	snackbars?: SnackbarResponse;
	success: boolean;
};

export type UpdateClassroomResponse = AxiosResponse & {
	data: UpdateClassroomResponseData;
};

/* ----------------
   Stats
----------------- */
export type Stats<T> = {
	type: StatsTypes;
	data: T;
	criteria?: StatsFilter;
};

export type StatsTypes = 'questions' | 'students' | 'segments' | 'repeating';

export type QuestionsStats = StatsItem<QuestionDocument>[];

export type StatsFilter = {
	classroom?: string;
	subject?: string;
	resultId?: string;
	date?: {
		from: Date;
		to: Date;
		value: StatsFilterDates;
	};
};

export enum StatsFilterDate {
	thisWeek = 'thisWeek',
	prevWeek = 'prevWeek',
	thisMonth = 'thisMonth',
	prevMonth = 'prevMonth',
	// firstSemester = 'firstSemester',
	// secondSemester = 'secondSemester',
	customDate = 'customDate',
}
export type StatsFilterDates = keyof typeof StatsFilterDate;

export type StudentsStats = StatsItem<User>[];

export type SegmentsStats = StatsItem<SegmentDocument>[];

export type StatsItem<T> = {
	_id: string; // questionId, segmentId, studentId
	value: StatsItemValue[];
	score: number;
	type: StatsItemTypes;
	ref?: T;
};

export type StatsItemValue = {
	_id: string;
	score: number;
	answer: QuizAnswer[];
};

export type StatsItemTypes = 'device' | 'homework' | 'repeating';

export type Quantiles = {
	one: number;
	two: number;
	tree: number;
	four: number;
	five: number;
};

/* ----------------
   History
----------------- */
export type TeacherHistory = {
	type: 'repeatingUser' | 'repeatingItem' | 'repeating' | 'examResult'; // 'homework';
	exam: ExamDocument;
	answers: UserAnswers[];
	refId: string; // homeworkId, repeatingUserId, repeatingItemId, repeatingId, examResultId
	dateDone: Date;
	dateSent: Date;
	dateDue: Date;
	classroomId: string;
};

/* ----------------
   Homework
----------------- */

export type Homework = {
	dueDate: Date;
	exam: ExamDocument;
	answers: UserAnswers[];
	createdBy: string;
	classroomId: string;
	state: HomeworkStates;
};

export type HomeworkDocument = Homework & MongoId & CreatedDate;

export type HomeworkStates = 'new' | 'sent' | 'done' | 'canceled';

export type AddHomework = {
	dueDate: Date;
	exam: Exam;
	classroomId: string;
};

export type AddHomeworkRequest = Request & {
	body: AddHomework;
};

export type SaveHomeworkAnswers = {
	homeworkId: string;
	answers: UserAnswers;
};

export type SaveHomeworkAnswersRequest = Request & {
	body: SaveHomeworkAnswers;
};

/* ----------------
   Repeating
----------------- */

export type Repeating = {
	teachingPlan: TeachingPlanDocument;
	settings: RepeatingSettings;
	students: string[];
	state: RepeatingStates;
	score: number;
};

export type RepeatingDocument = Omit<Repeating, 'timeline'> & MongoId & CreatedBy & CreatedDate;

export type RepeatingStates = 'active' | 'paused' | 'done' | 'deleted';

export type RepeatingItem = TimelineEvent;

export type RepeatingItemDocument = {
	repeatingId: string;
	dateSent?: Date;
	dateDone?: Date;
	state: RepeatingItemState;
	score: number;
} & RepeatingItem &
	MongoId &
	CreatedBy &
	CreatedDate;

export type RepeatingItemState = 'queue' | 'sent' | 'done';

export type RepeatingSettings = {
	classroomId: string;
	days: {
		sunday: RepeatingDay;
		monday: RepeatingDay;
		tuesday: RepeatingDay;
		wednesday: RepeatingDay;
		thursday: RepeatingDay;
		friday: RepeatingDay;
		saturday: RepeatingDay;
	};
	start: Date;
};

export type RepeatingDay = {
	active: boolean;
	questionsCount: number;
	time: number;
	due: number;
};

export type UpdateRepeating = {
	repeatingId: string;
	action: UpdateRepeatingActions;
	value: any;
	schoolId?: string;
};

export type UpdateRepeatingRequest = Request & {
	body: UpdateRepeating;
};

export type UpdateRepeatingActions =
	| 'setStudents'
	| 'setTeachingPlan'
	| 'setSettings'
	| 'setAnswer'
	| 'setState'
	| 'setDone';

/* ----------------
   Teaching Plan
----------------- */

export type TeachingPlanChapter = AncestorData & {
	hours?: number;
	active?: boolean;
	segments: string[];
};

export type TeachingPlanChapterDocument = TeachingPlanChapter &
	MongoId & {
		start?: Date;
		end?: Date;
	};

export type TeachingPlan = {
	id?: number;
	title: string;
	schoolType?: SchoolTypes;
	grade?: number;
	subject?: string;
	chapters?: TeachingPlanChapter[];
};

export type TeachingPlanDocument = Omit<TeachingPlan, 'chapters'> &
	MongoId &
	CreatedBy &
	CreatedDate & {
		chapters: TeachingPlanChapterDocument[];
	};

/* -------------------
   TeachingPlan request
--------------------*/

export type AddTeachingPlanRequest = Request & {
	body: {
		title: string;
		schoolType: SchoolTypes;
		grade: number;
		subject: string;
	};
};

export type RemoveTeachingPlanRequest = Request & {
	body: {
		teachingPlanId: string;
	};
};

export type UpdateTeachingPlan = {
	teachingPlanId: string;
	data: {
		value?: any;
		chapterId?: string;
	};
};

export type UpdateTeachingPlanRequest = Request & {
	body: UpdateTeachingPlan;
};

export type UpdateTeachingPlanActions =
	| 'add/chapter'
	| 'add/chapterChild'
	| 'add/segment'
	| 'update/title'
	| 'update/grade'
	| 'update/subject'
	| 'update/schoolType'
	| 'update/chapterTitle'
	| 'update/chapters'
	| 'update/sort'
	| 'update/hours'
	| 'remove/segment'
	| 'remove/chapter';

/* ----------------
   TeachingPlan response
----------------- */

export type AddTeachingPlanResponse = AxiosResponse & {
	data: {
		newTeachingPlan: TeachingPlanDocument;
		snackbars?: SnackbarResponse;
		success: boolean;
	};
};

export type RemoveTeachingPlanResponse = AxiosResponse & {
	data: {
		snackbars?: SnackbarResponse;
		success: boolean;
	};
};

export type UpdateTeachingPlanResponse = AxiosResponse & {
	data: {
		newTeachingPlan: TeachingPlanDocument;
		snackbars?: SnackbarResponse;
		success: boolean;
	};
};

/* ----------------
   Segment
----------------- */

export type Segment = {
	title: string;
	subject: string;
	grade: number;
	tags: string[];
	dependencies: [];
	questionsCount: number;
	requiredCount: number;
};

export type SegmentDocument = MongoId & Segment & CreatedBy & CreatedDate;

export type AddSegment = {
	title: string;
	parent: string;
	sort: number;
};

export type AddSegmentRequest = Request & {
	body: AddSegment;
};

export type RemoveSegment = {
	segmentId: string;
};

export type RemoveSegmentRequest = Request & {
	body: RemoveSegment;
};

export type UpdateSegment = {
	segmentId: string;
	data: {
		parentId?: string;
		tagId?: string;
		sort?: string[];
		title?: string;
		subjectId?: string;
		dependencyId?: string;
		grade?: number;
		count?: number;
	};
};

export type UpdateSegmentRequest = Request & {
	body: UpdateSegment;
};

export type UpdateSegmentActions =
	| 'add/tag'
	| 'remove/tag'
	| 'add/dependency'
	| 'remove/dependency'
	| 'update/subject'
	| 'update/grade'
	| 'update/title'
	| 'update/requiredCount';

export type UpdateSegmentSort = {
	parentId: string;
	sort: string[];
};

export type UpdateSegmentSortRequest = Request & {
	body: UpdateSegmentSort;
};

/* ----------------
   Subject
----------------- */

export type Subject = {
	title: string;
	titleShort: string;
	color: string;
	icon?: String;
	settings: {
		interaktiv: {
			visible: Boolean;
		};
	};
	language: Languages;
};

export type SubjectDocument = Subject & MongoId & CreatedBy & CreatedDate;

/* ----------------
   Subject request
----------------- */

export type AddSubjectRequest = Request & {
	body: {
		title: string;
	};
};

export type EditSubjectRequest = Request & {
	body: {
		subjectId: string;
		title: string;
		titleShort: string;
		color: string;
	};
};

/* ----------------
   Subject response
----------------- */

export type AddSubjectResponse = AxiosResponse & {
	data: {
		newSubject: SubjectDocument;
		success: boolean;
		snackbars?: SnackbarResponse;
	};
};

export type EditSubjectResponse = AxiosResponse & {
	data: {
		subjectId: string;
		newSubject: SubjectDocument;
		success: boolean;
		snackbars?: SnackbarResponse;
	};
};

export type GetSubjectsResponse = AxiosResponse & {
	data: SubjectDocument[];
};

/* ----------------
	Registration
----------------- */
export type Registration = {
	user: UserData;
	school: {
		title: string;
		uniqId: string;
	};
	subjects: RegistrationSubjects[];
	state: RegistrationStates;
};

export type RegistrationSubjects = {
	title: string;
	subjectId: string;
	grade: number;
	active: boolean;
	classrooms: RegistrationClassroom[];
};

export type RegistrationClassroom = {
	title: string;
};

export type RegistrationStates = 'new' | 'confirmed' | 'rejected';

export type RegistrationDocument = Omit<Registration, 'user'> & {
	user: UserData & MongoId;
} & MongoId &
	CreatedDate;

export type ConfirmRegistrationRequest = Request & {
	body: {
		registrationId: string;
	};
};

export type RejectRegistrationRequest = Request & {
	body: {
		registrationId: string;
	};
};

/* ----------------
   Voucher
----------------- */
export type Voucher = {
	prefix: string;
	codes: VoucherCode[];
	state: VoucherStates;
	action: VoucherActions;
};

export type VoucherDocument = MongoId &
	CreatedDate &
	CreatedBy &
	Omit<Voucher, 'codes'> & {
		codes: VoucherCodeDocument[];
	};

export type VoucherCode = {
	code: string;
	userId?: string;
	claimDate?: Date;
};

export type VoucherCodeDocument = VoucherCode & MongoId;

export type VoucherStates = 'new' | 'printed' | 'distributed';

export type VoucherActions = 'all15days';

/* ----------------
   User
----------------- */
export type UserData = {
	role: RoleTypes;
	email: string;
	degree: string;
	firstName: string;
	lastName: string;
	phone: string;
	isManager: boolean;
};

export type ProjectSettings = {
	active: boolean;
	userId?: string;
	role?: EmployeeRoleTypes | CustomerRoleTypes;
	dateRegister?: Date;
};

export type User = UserData &
	MongoId & {
		hash?: string;
		md5?: string;
		token: string;
		teacherSettings?: {
			subjects: string[];
			classrooms: string[];
			deviceId?: string;
		};
		studentSettings?: {
			keyboard?: StudentKeyboard; // TODO: remove, use id from classroom.students settings
		};
		schoolId?: string;
		taktikSchoolId?: string;
		classroomId?: string;
		state: UserStates;
		projects?: {
			central?: {
				sk?: ProjectSettings;
				cs?: ProjectSettings;
				ro?: ProjectSettings;
			};
			voti?: {
				sk?: ProjectSettings;
				cs?: ProjectSettings;
				ro?: ProjectSettings;
			};
			interaktiv?: {
				sk?: ProjectSettings;
				cs?: ProjectSettings;
				ro?: ProjectSettings;
			};
			eshop?: {
				sk?: ProjectSettings;
				cs?: ProjectSettings;
				ro?: ProjectSettings;
			};
		};
		licences?: {
			interaktiv?: InteraktivLicenceDocument[];
		};
	};

export type InteraktivLicence = {
	bookId: string;
	dateFrom: Date;
	dateTo: Date;
	orderId: Number;
	orderNum: Number;
	action: InteraktivLicenceActions;
} & CreatedDate &
	CreatedBy;

export type InteraktivLicenceDocument = InteraktivLicence & MongoId;

export enum InteraktivLicenceActionsList {
	admin = 'admin',
	order = 'order',
	bonus = 'bonus',
	csv = 'csv',
	refresh = 'refresh',
	voucher = 'voucher',
}
export type InteraktivLicenceActions = keyof typeof InteraktivLicenceActionsList;

export type UserSession = Omit<User, 'token'> & {
	token: string;
};

export enum UserStatesList {
	pwd = 'pwd',
	guide = 'guide',
	active = 'active',
	imported = 'imported',
	duplicate = 'duplicate',
	voucher = 'voucher',
}
export type UserStates = keyof typeof UserStatesList;

export type UserDocument = User &
	CreatedBy &
	CreatedDate & {
		resetPassword?: string;
		registrationDate?: Date;
		schools?: string[];
		classrooms?: string[];
	};

export type StudentKeyboard = {
	autoId: number;
	customId: number;
};

export type UserFilter = {
	_id?: string;
	_ids?: string[];
	firstName?: string;
	lastName?: string;
	email?: string;
	role?: RoleTypes[];
	projectRole?: (CustomerRoleTypes | EmployeeRoleTypes)[];
	classroomId?: string;
	schoolId?: string;
	state?: UserStates[];
	projects?: Projects[];
};

export type StudentExam = {
	type: 'homework' | 'repeating' | 'device';
	homework?: HomeworkDocument;
	repeating?: RepeatingItemDocument;
	device?: ExamResultDocument;
};

/* ----------------
   User Props
----------------- */
export interface LoginProps {
	email: string;
	password: string;
	project: Projects;
	language: Languages;
}

export interface NewUserProps {
	email: string;
	degree: string;
	phone: string;
	password: string;
	passwordAgain?: string;
	firstName: string;
	lastName: string;
	role?: RoleTypes;
	projectRole?: CustomerRoleTypes | EmployeeRoleTypes;
	inviteId?: string;
	schoolId?: string;
}

export interface PasswordChangeProps {
	oldPasswordInvalid: boolean;
	oldPassword: string;
	newPassword: string;
	newPasswordRepeat: string;
}

/* ----------------
   User Request
----------------- */
export type AddUserRequest = Request & {
	body: NewUserProps;
};

export type EditUserRequest = Request & {
	body: {
		userId: string;
		newData: User;
	};
};

export type ChangeUserPassword = {
	oldPassword: string;
	newPassword: string;
};

export type ChangeUserPasswordRequest = Request & {
	body: ChangeUserPassword;
};

export type RemoveUserRequest = Request & {
	body: {
		userId: string;
	};
};

export type LoginRequest = Request & {
	body: LoginProps;
};

export type AddUserSubject = {
	userId: string;
	subjectId: string;
};

export type AddUserSubjectRequest = Request & {
	body: AddUserSubject;
};

export type RemoveUserSubject = {
	subjectId: string;
};

export type RemoveUserSubjectRequest = Request & {
	body: RemoveUserSubject;
};

export type AddUserLicence = {
	userId: string;
	licence: InteraktivLicence;
};

export type AddUserLicenceRequest = Request & {
	body: AddUserLicence;
};

export type EditUserLicence = {
	userId: string;
	licence: InteraktivLicenceDocument;
};

export type EditUserLicenceRequest = Request & {
	body: EditUserLicence;
};

export type AddUserClassroom = {
	userId: string;
	classroomId: string;
};

export type AddUserClassroomRequest = Request & {
	body: AddUserClassroom;
};

export type RemoveUserClassroom = {
	userId: string;
	classroomId: string;
};

export type RemoveUserClassroomRequest = Request & {
	body: RemoveUserClassroom;
};

/* ----------------
   User Response
----------------- */
export type PasswordChangeResponse = AxiosResponse & {
	data: {
		snackbars?: SnackbarResponse;
		success: boolean;
	};
};

export type AddUserResponse = AxiosResponse & {
	data: {
		snackbars?: SnackbarResponse;
		success: boolean;
		newUser: User;
	};
};

export type EditUserResponse = AxiosResponse & {
	data: {
		snackbars?: SnackbarResponse;
		success: boolean;
		newUser: User;
	};
};

export type RemoveUserResponse = AxiosResponse & {
	data: {
		success: boolean;
	};
};

export type GetUsersResponse = AxiosResponse & {
	data: User[];
};

export type LoginResponse = AxiosResponse & {
	data: {
		success: boolean;
		snackbars?: SnackbarResponse;
		user: User;
	};
};

/* ----------------
   Question
----------------- */

export interface Question {
	id?: number;
	examId: string;
	exerciseId: string;
	title: string;
	equation?: string;
	description?: string;
	module: QuestionModules;
	puzzleData?: PuzzleData;
	quizData?: QuizData;
	state: QuestionStates;
	revisions: string[];
	sort: number;
	rating: number;
}

export type PuzzleData = {
	puzzles: Puzzle[];
	size: PuzzleSize;
	startImage?: TaktikFile;
	endImage?: TaktikFile;
};

export type QuizData = {
	answers: Answer[];
	layout: QuestionLayout;
	hints: Hint[];
	upload?: TaktikFile;
	timeLimit?: number;
};

export type QuestionModules = 'quiz' | 'puzzle';

export type QuestionDocument = Omit<Question, 'id' | 'puzzleData' | 'quizData'> &
	MongoId &
	CreatedBy &
	CreatedDate & {
		id: number;
		puzzleData?: {
			puzzles: PuzzleDocument[];
			size: PuzzleSize;
			startImage?: TaktikFile;
			endImage?: TaktikFile;
		};
		quizData?: {
			answers: AnswerDocument[];
			layout: QuestionLayoutDocument;
			hints: HintDocument[];
			upload?: TaktikFile;
			audioDelay?: number;
			timeLimit?: number;
		};
	};

export type Answer = {
	_id?: string;
	value: string;
	equation?: string;
	data: {
		upload?: TaktikFile;
		isCorrect: boolean;
		sort?: number;
	};
};

export type AnswerDocument = Answer & MongoId;

export type Puzzle = {
	image?: TaktikFile;
	text?: TextObject;
	audio?: {
		file: TaktikFile;
		anchor: {
			x: number;
			y: number;
		};
	};
	defaultPosition: {
		x: number;
		y: number;
	};
	correctPosition: {
		x: number;
		y: number;
	};
	size?: {
		width: number;
		height: number;
	};
};

export type PuzzleDocument = MongoId & Puzzle;

export type PuzzleSize = {
	width: number;
	height: number;
};

export type PuzzlePosition = 'default' | 'correct';

export type PuzzleAnswer = { correct: number; incorrect: number; startDate: Date; endDate?: Date };

export type TextObject = {
	value: string;
	fontSize?: number;
};

export type Hint = {
	value: String;
};

export type HintDocument = MongoId & Hint;

export type UpdateQuestionActions =
	| 'add/answer'
	| 'add/quiz'
	| 'add/puzzle'
	| 'remove/quiz'
	| 'remove/answer'
	| 'remove/puzzle'
	| 'move/puzzle'
	| 'update/title'
	| 'update/description'
	| 'update/upload'
	| 'update/sort'
	| 'update/answerUpload'
	| 'update/answerText'
	| 'update/setCorrectAnswer'
	| 'update/toggleCorrectAnswer'
	| 'update/moveAnswer'
	| 'update/hints'
	| 'update/timeLimit'
	| 'update/audioDelay'
	| 'update/puzzleUpload'
	| 'update/puzzleBgStart'
	| 'update/puzzleBgEnd'
	| 'update/puzzlesSize'
	| 'update/puzzleSize'
	| 'update/puzzleText'
	| 'update/puzzleTextSize'
	| 'update/equation'
	| 'update/answerEquation'
	| 'update/rating';

export type UpdateQuestion = {
	questionId: string;
	data: {
		answerId?: string;
		hintId?: string;
		puzzleId?: string;
		value?: any;
		count?: number;
	};
};

export type QuestionFilter = {
	id?: string;
	title?: string;
	answers?: string;
	examId?: string[];
	exerciseId?: string[];
	customExamId?: string;
	createdBy?: string[];
	state?: QuestionStates[];
	_ids?: string[];
	schoolType?: SchoolTypes;
	answerType?: AnswerTypes;
	grade?: number;
	subject?: string;
	segments?: string[];
};

export type QuestionSort = {
	page: number;
	pageSize: number;
	orderBy?: string;
	orderDirection?: 'asc' | 'desc';
};

export type UpdateQuestionRequest = Request & {
	body: UpdateQuestion;
};

export type AddQuestionRequest = Request & {
	body: Question;
};

export type QuestionReport = {
	questionId: string;
	message: string;
	state: QuestionReportStates;
};

export type QuestionReportDocument = QuestionReport & MongoId & CreatedBy & CreatedDate;

export type QuestionReportStates = 'new' | 'seen' | 'resolved' | 'rejected';

/* ----------------
   Question Types
----------------- */

export interface QuestionTypes {
	title: string;
	layout: QuestionLayout;
}

export type QuestionTypesDocument = QuestionTypes & MongoId & CreatedBy & CreatedDate;

/* ----------------
   Question type request
----------------- */

export type AddQuestionTypeRequest = Request & {
	body: {
		newQuestionType: QuestionTypes;
	};
};

export type EditQuestionTypeRequest = Request & {
	body: {
		questionTypeId: string;
		title: string;
	};
};

export type RemoveQuestionTypeRequest = Request & {
	body: {
		questionTypeId: string;
	};
};

export type GetQuestionTypesRequest = Request;

/* ----------------
   Question type response
----------------- */

export type AddQuestionTypeResponse = AxiosResponse & {
	data: {
		snackbars: SnackbarResponse;
		success: boolean;
		newQuestionType: QuestionTypesDocument;
	};
};

export type RemoveQuestionTypeResponse = AxiosResponse & {
	data: {
		snackbars: SnackbarResponse;
		success: boolean;
		questionTypeId: string;
	};
};

export type EditQuestionTypeResponse = AxiosResponse & {
	data: {
		snackbars: SnackbarResponse;
		success: boolean;
		newQuestionType: QuestionTypesDocument;
	};
};

export type GetQuestionTypesResponse = AxiosResponse & {
	data: {
		snackbars: SnackbarResponse;
		success: boolean;
		questionTypes: QuestionTypesDocument[];
	};
};

/* ----------------
   Revision
----------------- */
export type Revision = {
	title: string;
	state: RevisionStates;
	counts: RevisionCounts;
};

export type RevisionCounts = {
	review: number;
	check: number;
	approved: number;
	characters: number;
};

export enum RevisionStatesList {
	review = 'review',
	check = 'check',
	moved = 'moved',
	approved = 'approved',
	discarded = 'discarded',
}
export type RevisionStates = keyof typeof RevisionStatesList;

export type RevisionDocument = Revision & MongoId & CreatedBy & CreatedDate;

export type RevisionNote = {
	value: string;
	userId?: string;
};

export type RevisionNoteDocument = RevisionNote & MongoId & CreatedDate;

export type RevisionItem = {
	_id?: string;
	questionId: string;
	id: number;
	revisionId?: string;
	corrections: {
		title: RevisionCorrection;
		answers: RevisionCorrection[];
		upload?: TaktikFile;
	};
	notes: RevisionNote[];
	layout: QuestionLayout;
	state: RevisionStates;
	changes: RevisionChangeDocument[];
};

export type RevisionItemDocument = Omit<RevisionItem, 'revisionId' | 'notes'> & {
	notes: RevisionNoteDocument[];
	dates: {
		sent: Date;
		corrected?: Date;
		approved?: Date;
	};
	revisionId: string;
	newRevisionId: string;
} & MongoId &
	CreatedDate;

export type RevisionChange = {
	title: RevisionCorrection;
	answers: RevisionCorrection[];
};

export type RevisionChangeDocument = RevisionChange & MongoId & CreatedDate;

export type RevisionCorrection = {
	original: string;
	corrected: string;
	data?: {
		isCorrect: boolean;
		upload?: TaktikFile;
	};
};

export type RevisionActions =
	| 'setItemCorrections'
	| 'setItemApproved'
	| 'setItemDiscarded'
	| 'cancelItem'
	| 'addItems'
	| 'cancelReady'
	| 'addNote';

export type UpdateRevision = {
	revisionId: string;
	newRevisionId?: string;
	itemId?: string;
	itemsIds?: string[];
	value?: any;
	userId?: string;
	filter?: RevisionFilter;
};

export type RevisionFilter = {
	state: RevisionStates;
	page: number;
	pageSize: number;
};

export type AddRevision = Omit<Revision, 'items'> & {
	items: Omit<RevisionItem, 'layout' | 'state' | 'changes' | 'corrections'>[];
};

/* ----------------
   Exam
----------------- */

export interface Exam {
	id: number;
	title: string;
	description: string;
	upload?: TaktikFile;
	exercises: Exercise[];
	criteria: ExamCriteria;
	state: ExamStates;
}

export type ExamDocument = Omit<Exam, 'exercises' | 'id'> &
	MongoId &
	CreatedBy &
	CreatedDate & {
		id: number;
		exercises: ExerciseDocument[];
	};

export interface ExamCriteria {
	subject: string;
	schoolType: SchoolTypes;
	grade: number;
}

export type ExamFilter = {
	id?: string;
	title?: string;
	description?: string;
	createdBy?: string[];
	state?: ExerciseStates[];
	subject?: string;
	schoolType?: SchoolTypes;
	grade?: number;
};

export type CustomExam = {
	title: string;
	schoolType: SchoolTypes;
	grade: number;
	subject: string;
	questions: string[];
} & CreatedBy;

export type CustomExamDocument = MongoId & CustomExam & CreatedDate;

export type UpdateCustomExamActions =
	| 'update/addQuestions'
	| 'update/removeQuestion'
	| 'update/title'
	| 'update/sort';

export type UpdateCustomExam = {
	customExamId: string;
	data: {
		title?: string;
		ids?: string[];
		_id?: string;
		sourceIndex?: number;
		targetIndex?: number;
	};
};
export interface UpdateCustomExamProps {
	data: UpdateCustomExam['data'];
	select: MongooseSelect;
	update: MongooseUpdate;
	options: MongooseOptions;
}

/* ----------------
   Exam request
----------------- */

export type AddExam = {
	title: string;
	description: string;
	criteria: {
		subject?: string;
		schoolType?: SchoolTypes;
		grade?: number;
	};
};

export type AddExamRequest = Request & {
	body: AddExam;
};

export type UpdateExam = {
	examId: string;
	data: {
		value?: any;
	};
};

export type UpdateExamActions =
	| 'update/upload'
	| 'update/title'
	| 'update/description'
	| 'update/schoolType'
	| 'update/grade'
	| 'update/subject'
	| 'update/sort/exercises';

export type UpdateExamRequest = Request & {
	body: UpdateExam;
};

export type RemoveExamRequest = Request & {
	body: {
		examId: string;
	};
};

/* ----------------
   Exam response
----------------- */

export type AddExamResponse = AxiosResponse & {
	data: {
		snackbars: SnackbarResponse;
		success: boolean;
		newExam: Exam;
	};
};

export type UpdateExamResponse = AxiosResponse & {
	data: {
		newExam: ExamDocument;
		snackbars: SnackbarResponse;
		success: boolean;
	};
};

export type RemoveExamResponse = AxiosResponse & {
	data: {
		snackbars: SnackbarResponse;
		success: boolean;
	};
};

/* ----------------
   ExamResult
----------------- */

export type ExamResult = {
	deviceId: string;
	answers: UserAnswers[];
	exam: ExamDocument;
	session: CuDeviceSession;
	startDate: Date;
};

export type ExamResultDocument = ExamResult &
	MongoId & {
		endDate: Date;
	};

/* ----------------
   ExamResult Request
----------------- */

export type SaveExamResult = {
	device: Device;
};

export type SaveExamResultRequest = Request & {
	body: SaveExamResult;
};

export type SaveExamResultResponse = AxiosResponse & {
	data: {
		success: boolean;
		newExamResult: ExamResultDocument;
	};
};

export type GetExamResult = {
	userId?: string;
	teacherId?: string;
	resultId?: string;
};

export type GetExamResultRequest = Request & {
	body: GetExamResult;
};

export type GetExamResultResponse = AxiosResponse & {
	data: {
		success: boolean;
		examAnswers: ExamResultDocument[];
	};
};

/* ----------------
   Exercise
----------------- */

export interface Exercise {
	id: number;
	examId: string;
	title: string;
	description: string;
	criteria: ExerciseCriteria;
	upload?: TaktikFile;
	questions: Question[];
	state: ExerciseStates;
	metadata?: {
		description?: string;
	};
}

export type ExerciseCriteria = {
	segments: string[];
	difficulty: number;
};

export type ExerciseDocument = Omit<Exercise, 'questions' | 'id'> &
	MongoId &
	CreatedBy &
	CreatedDate & {
		id: number;
		questions: QuestionDocument[];
	};

export interface QuestionLayout {
	questionTypeId?: string;
	description?: string;
	componentType?: ComponentTypes;
	answersType?: AnswerTypes;
	limits: QuestionLayoutLimits;
}

export type QuestionLayoutDocument = Omit<QuestionLayout, 'componentType' | 'answersType'> &
	MongoId & {
		componentType: ComponentTypes;
		answersType: AnswerTypes;
	};

export type QuestionLayoutLimits = {
	answersCount: MinMaxType;
	questionChars: MinMaxType;
	answerChars: MinMaxType;
};

export type UpdateExerciseActions =
	| 'add/segment'
	| 'delete/segment'
	| 'update/sort/questions'
	| 'update/meta'
	| 'update/title'
	| 'update/description'
	| 'update/upload';

export type UpdateExercise = {
	exerciseId: string;
	data: {
		value?: any;
	};
};

export type AddExerciseRequest = Request & {
	body: Exercise;
};

export type ExerciseFilter = {
	id?: string;
	title?: string;
	description?: string;
	examId?: string[];
	createdBy?: string[];
	state?: ExerciseStates[];
	_ids?: string[];
	schoolType?: SchoolTypes;
	grade?: number;
	subject?: string;
	segments?: string[];
};

/* ----------------
   Answer
----------------- */

export interface UserAnswers {
	_id: string; // UserId
	keyId: number;
	dateDone?: Date;
	questions: QuestionAnswer[];
	state: UserAnswersState;
	score: number;
}

export type UserAnswersState = 'new' | 'done' | 'skip';

export type QuestionAnswer = {
	_id: string; // QuestionId
	quizAnswer?: QuizAnswer[];
	puzzleAnswer?: PuzzleAnswer;
	state: QuestionAnswerState;
	score: number;
};

export type QuestionAnswerState = 'new' | 'done' | 'skip';

export type QuizAnswer = MongoId & {
	keyCode?: KeyCodes;
};

export type ExamQuestionStep = {
	questionId: string;
	loaded: boolean;
	completed: boolean;
};

export type ExamStep = {
	exerciseId: string;
	questions: ExamQuestionStep[];
};

export type KeyCodes = 0 | 1 | 2 | 3;

/* ----------------
   Roles
----------------- */

export enum RoleTypesList {
	superadmin = 'superadmin',
	admin = 'admin',
	employee = 'employee',
	customer = 'customer',
	unauthorized = 'unauthorized',
}
export type RoleTypes = keyof typeof RoleTypesList;

export enum EmployeeRoleTypesList {
	admin = 'admin',
	sales = 'sales',
	moderator = 'moderator',
	maker = 'maker',
}
export type EmployeeRoleTypes = keyof typeof EmployeeRoleTypesList;

export enum CustomerRoleTypesList {
	teacher = 'teacher',
	student = 'student',
}
export type CustomerRoleTypes = keyof typeof CustomerRoleTypesList;

/* ----------------
   Admin redux root state
----------------- */

export type RootState<T = QuestionsStats> = {
	users: UserDocument[];
	snackbars: SnackbarResponse;
	exams: ExamDocument[];
	exercises: ExerciseDocument[];
	questions: QuestionDocument[];
	questionTypes: QuestionTypesDocument[];
	questionReports: QuestionReportDocument[];
	examResults: ExamResultDocument[];
	schools: SchoolDocument[];
	classrooms: ClassroomDocument[];
	subjects: SubjectDocument[];
	teachingPlans: TeachingPlanDocument[];
	invites: InviteDocument[];
	devices: DeviceDocument[];
	device: TeacherDevice;
	tags: TagDocument[];
	elastic: any[];
	homeworks: HomeworkDocument[];
	repeatings: RepeatingDocument[];
	repeatingItems: RepeatingItemDocument[];
	revisions: RevisionDocument[];
	revisionItems: RevisionItemDocument[];
	segments: SegmentDocument[];
	emailTemplates: EmailTemplateDocument[];
	socket: VotiSocket;
	pairing: number[];
	translations: TranslationDocument[];
	translationEdit: TranslationPath | null;
	notifications: NotificationDocument[];
	registrations: RegistrationDocument[];
	user: UserSession;
	guide: GuideState;
	stats: Stats<T>[];
	history: TeacherHistory[];
	usbDevices: UsbDevice[];
	customExams: CustomExamDocument[];
	quotes: QuoteDocument[];
	migrate: Migration[];
	books: BookDocument[];
	bookPages: BookPageDocument[];
	bookFeedbacks: BookFeedbackDocument[];
	bookActive: BookActiveProps;
	bookCategories: BookCategoryDocument[];
	vouchers: VoucherDocument[];
};

/* ----------------
   Teacher Device
----------------- */
export type TeacherDevice = DeviceDocument | null;

/* ----------------
   Voti CU socket
----------------- */

export type VotiSocket = Socket | null;

/* ----------------
   RBAC Rules
----------------- */

export enum StaticRuleList {
	// core
	'admin:loginAs' = 'admin:loginAs',
	'admin:dashboard' = 'admin:dashboard',
	'admin:profile' = 'admin:profile',
	'admin:users' = 'admin:users',
	'admin:users:add' = 'admin:users:add',
	'admin:users:edit' = 'admin:users:edit',
	'admin:users:remove' = 'admin:users:remove',
	'admin:users:addSubject' = 'admin:users:addSubject',
	'admin:users:removeSubject' = 'admin:users:removeSubject',
	'admin:users:addLicence' = 'admin:users:addLicence',
	'admin:users:editLicence' = 'admin:users:editLicence',
	'admin:users:setDevice' = 'admin:users:setDevice',
	'admin:users:getBySchool' = 'admin:users:getBySchool',
	'admin:users:addClassroom' = 'admin:users:addClassroom',
	'admin:users:removeClassroom' = 'admin:users:removeClassroom',
	'admin:schools' = 'admin:schools',
	'admin:schools:add' = 'admin:schools:add',
	'admin:schools:remove' = 'admin:schools:remove',
	'admin:schools:update' = 'admin:schools:update',
	'admin:schools:update:classroom' = 'admin:schools:update:classroom',
	'admin:schools:update:subject' = 'admin:schools:update:subject',
	'admin:schools:update:teachingPlan' = 'admin:schools:update:teachingPlan',
	'admin:schools:update:addTeacher' = 'admin:schools:update:addTeacher',
	'admin:classrooms' = 'admin:classrooms',
	'admin:classrooms:add' = 'admin:classrooms:add',
	'admin:classrooms:remove' = 'admin:classrooms:remove',
	'admin:classrooms:update' = 'admin:classrooms:update',
	'admin:classrooms:update:timetable' = 'admin:classrooms:update:timetable',
	'admin:classrooms:update:teachingPlan' = 'admin:classrooms:update:teachingPlan',
	'admin:classrooms:update:groups' = 'admin:classrooms:update:groups',
	'admin:classrooms:pairing' = 'admin:classrooms:pairing',
	'admin:classroom:update:pairing' = 'admin:classroom:update:pairing',
	'admin:classroom:update:addStudent' = 'admin:classroom:update:addStudent',
	'admin:subjects' = 'admin:subjects',
	'admin:subjects:add' = 'admin:subjects:add',
	'admin:subjects:edit' = 'admin:subjects:edit',
	'admin:subjects:get' = 'admin:subjects:get',
	'admin:emailTemplate' = 'admin:emailTemplate',
	'admin:emailTemplate:add' = 'admin:emailTemplate:add',
	'admin:emailTemplate:get' = 'admin:emailTemplate:get',
	'admin:emailTemplate:update' = 'admin:emailTemplate:update',
	'admin:emailTemplate:remove' = 'admin:emailTemplate:remove',
	'admin:invite:add' = 'admin:invite:add',
	'admin:translations' = 'admin:translations',

	// voti
	'voti:exams' = 'voti:exams',
	'voti:exams:add' = 'voti:exams:add',
	'voti:exams:remove' = 'voti:exams:remove',
	'voti:exams:update' = 'voti:exams:update',
	'voti:exams:update:question' = 'voti:exams:update:question',
	'voti:exams:update:layout' = 'voti:exams:update:layout',
	'voti:questionTypes' = 'voti:questionTypes',
	'voti:questionTypes:get' = 'voti:questionTypes:get',
	'voti:questionTypes:add' = 'voti:questionTypes:add',
	'voti:questionTypes:edit' = 'voti:questionTypes:edit',
	'voti:questionTypes:remove' = 'voti:questionTypes:remove',
	'voti:teachingPlans' = 'voti:teachingPlans',
	'voti:teachingPlans:add' = 'voti:teachingPlans:add',
	'voti:teachingPlans:update' = 'voti:teachingPlans:update',
	'voti:teachingPlans:get' = 'voti:teachingPlans:get',
	'voti:devices' = 'voti:devices',
	'voti:devices:add' = 'voti:devices:add',
	'voti:devices:remove' = 'voti:devices:remove',
	'voti:devices:update' = 'voti:devices:update',
	'voti:registrations' = 'voti:registrations',
	'voti:registrations:confirm' = 'voti:registrations:confirm',
	'voti:registrations:reject' = 'voti:registrations:reject',
	'voti:customExams' = 'voti:customExams',
	'voti:customExams:add' = 'voti:customExams:add',
	'voti:customExams:remove' = 'voti:customExams:remove',
	'voti:examResult' = 'voti:examResult',
	'voti:examResult:save' = 'voti:examResult:save',
	'voti:tags:get' = 'voti:tags:get',
	'voti:tags:add' = 'voti:tags:add',
	'voti:tags:remove' = 'voti:tags:remove',
	'voti:quotes:get' = 'voti:quotes:get',
	'voti:quotes:add' = 'voti:quotes:add',
	'voti:quotes:remove' = 'voti:quotes:remove',
	'voti:segments' = 'voti:segments',
	'voti:segments:add' = 'voti:segments:add',
	'voti:segments:get' = 'voti:segments:get',
	'voti:segments:update' = 'voti:segments:update',
	'voti:segments:remove' = 'voti:segments:remove',
	'voti:homeworks:add' = 'voti:homeworks:add',
	'voti:homeworks:get' = 'voti:homeworks:get',
	'voti:homeworks:update' = 'voti:homeworks:update',
	'voti:pusher:answer' = 'voti:pusher:answer',
	'voti:revisions:get' = 'voti:revisions:get',
	'voti:revisions:add' = 'voti:revisions:add',
	'voti:revisions:remove' = 'voti:revisions:remove',
	'voti:repeating:get' = 'voti:repeating:get',
	'voti:repeating:add' = 'voti:repeating:add',
	'voti:repeating:update' = 'voti:repeating:update',
	'voti:history:get' = 'voti:history:get',
	'voti:device' = 'voti:device',
	'voti:questionReport:add' = 'voti:questionReport:add',
	'voti:questionReport:get' = 'voti:questionReport:get',
	'voti:questionReport:resolve' = 'voti:questionReport:resolve',
	'voti:questionReport:reject' = 'voti:questionReport:reject',

	// interaktiv
	'interaktiv:books:get' = 'interaktiv:books:get',
	'interaktiv:books:add' = 'interaktiv:books:add',
	'interaktiv:books:update' = 'interaktiv:books:update',
	'interaktiv:bookCategory:get' = 'interaktiv:bookCategory:get',
	'interaktiv:bookCategory:add' = 'interaktiv:bookCategory:add',
	'interaktiv:bookCategory:update' = 'interaktiv:bookCategory:update',
	'interaktiv:bookCategory:remove' = 'interaktiv:bookCategory:remove',
	'interaktiv:bookFeedback:get' = 'interaktiv:bookFeedback:get',
	'interaktiv:bookFeedback:add' = 'interaktiv:bookFeedback:add',
	'interaktiv:bookFeedback:update' = 'interaktiv:bookFeedback:update',
	'interaktiv:voucher:get' = 'interaktiv:voucher:get',
	'interaktiv:voucher:update' = 'interaktiv:voucher:update',
	'interaktiv:voucher:generate' = 'interaktiv:voucher:generate',
}
export type StaticRuleType = keyof typeof StaticRuleList;
export type StaticRuleTypes = StaticRuleType[];
export type DynamicFunctionType = (data: any) => boolean;
export type DynamicRuleType = {
	[key: string]: DynamicFunctionType;
};
export type RuleType = {
	static?: StaticRuleTypes;
	dynamic?: DynamicRuleType;
};

export type RbacRules = {
	superadmin: RuleType;
	admin: RuleType;
	employee: { [key in EmployeeRoleTypes]: RuleType };
	customer: { [key in CustomerRoleTypes]: RuleType };
	unauthorized: RuleType;
};

/* ----------------
   Can
---------------- */

export interface CanInterface {
	rules: RbacRules; // TODO: remove rbac rules and load from @taktik/common
	user: User;
	project: Projects;
	language: Languages;
	perform: StaticRuleType;
	data?: object;
	yes?: () => any;
	no?: () => any;
}
