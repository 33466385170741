import { GET_QUESTION_REPORTS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { QuestionFilter, QuestionSort } from '../../../../types';
import { getQuestionsAction } from '../../questions/actions/get';
import reducerConfig from '../../reducerConfig';

const getQuestionReportsRequest = (token: string, filter: QuestionFilter, sort: QuestionSort) => {
	return axios.get(`${reducerConfig.api}/question-report/get`, {
		params: { ...filter, ...sort },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getQuestionReportsAction = (res: AxiosResponse) => {
	return {
		type: GET_QUESTION_REPORTS,
		payload: res.data.questionReports,
	};
};

const getQuestionReports = (token: string, filter: QuestionFilter, sort: QuestionSort): any => {
	return (dispatch: any) => {
		return getQuestionReportsRequest(token, filter, sort).then((res: AxiosResponse) => {
			if (res.data.questions) {
				dispatch(getQuestionsAction(res.data.questions));
			}
			dispatch(getQuestionReportsAction(res));
			return res;
		});
	};
};

export default getQuestionReports;
