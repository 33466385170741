import { UPDATE_HOMEWORK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { QuestionModules, PuzzleAnswer } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateHomeworkRequest = (
	module: QuestionModules,
	homeworkId: string,
	questionId: string,
	answer: string | PuzzleAnswer,
	token: string
) => {
	return axios.post(
		`${reducerConfig.api}/homework/answer`,
		{
			module,
			homeworkId,
			questionId,
			answer,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateHomeworkAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_HOMEWORK,
		payload: res.data.newHomework,
	};
};

const updateHomework = (
	module: QuestionModules,
	homeworkId: string,
	questionId: string,
	answer: string | PuzzleAnswer,
	token: string
): any => {
	return (dispatch: any) => {
		return updateHomeworkRequest(module, homeworkId, questionId, answer, token).then(
			(res: AxiosResponse) => {
				if (res.data.success) {
					dispatch(updateHomeworkAction(res));
				}
				if (res.data.snackbars) {
					dispatch(addSnackbar(res.data.snackbars));
				}
				return res.data.newHomewrork;
			}
		);
	};
};

export default updateHomework;
