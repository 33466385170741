import axios, { AxiosResponse } from 'axios';
import fetchUsersAction from './fetch';
import reducerConfig from '../../reducerConfig';

const getUserByRequest = (action: string, id: string, token: string) => {
	return axios.get(`${reducerConfig.api}/user/get-by/${action}/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getUsersBy = (action: string, id: string, token: string): any => {
	return (dispatch: any) => {
		return getUserByRequest(action, id, token).then((res: AxiosResponse) =>
			dispatch(fetchUsersAction(res.data.users))
		);
	};
};

export default getUsersBy;
