import { BookDocument } from '../../../types';
import {
	GET_BOOK_PAGES,
	ADD_BOOK_PAGES,
	BookPagesActionTypes,
	UPDATE_BOOK_PAGE,
	UPDATE_BOOK_PAGES_SORT,
	REMOVE_BOOK_PAGE,
} from './constants';

const initialState: BookDocument[] = [];

const books = (state = initialState, action: BookPagesActionTypes) => {
	switch (action.type) {
		case GET_BOOK_PAGES:
			return action.payload;

		case ADD_BOOK_PAGES:
			if (!action.payload.success) {
				return state;
			}
			return [...state, ...action.payload.newBookPages];

		case UPDATE_BOOK_PAGES_SORT:
			return action.payload.newBookPages;

		case REMOVE_BOOK_PAGE:
			return state.filter(s => s._id !== action.payload.pageId);

		case UPDATE_BOOK_PAGE:
			return state.map(page => {
				if (page._id === action.payload.newPage._id) {
					return {
						...page,
						...action.payload.newPage,
					};
				}
				return page;
			});

		default:
			return state;
	}
};

export default books;
