import get from './get';
import add from './add';
import confirm from './confirm';
import reject from './reject';

export default {
	get,
	add,
	confirm,
	reject,
};
