import axios, { AxiosResponse } from 'axios';
import { MigrationActions, MigrationTable } from '../../../../types';
import updateMigration from './update';
import reducerConfig from '../../reducerConfig';

const migrateRequest = (action: MigrationActions | 'clean-tables', table?: MigrationActions) => {
	return axios.post(`${reducerConfig.api}/migrate/${action}`, {
		table,
	});
};

const migrate = (action: MigrationActions | 'clean-tables', table?: MigrationActions): any => {
	return (dispatch: any) => {
		return migrateRequest(action, table).then((res: AxiosResponse) => {
			if (res.data.migration) {
				dispatch(updateMigration(res.data.migration));
			}
			if (res.data.migrations) {
				const migrations = res.data.migrations as MigrationTable[];
				migrations.forEach(migrate => {
					dispatch(updateMigration(migrate));
				});
			}
			return res;
		});
	};
};

export default migrate;
