import { NotificationDocument } from '../../../types';
import { GET_NOTIFICATIONS, UPDATE_NOTIFICATION, SnackbarActionTypes } from './constants';

const initialState: NotificationDocument[] = [];

const notifications = (state = initialState, action: SnackbarActionTypes) => {
	switch (action.type) {
		case GET_NOTIFICATIONS:
			return action.payload;

		case UPDATE_NOTIFICATION:
			return state.map(notification => {
				const newNotification = action.payload.newNotifications.find(n => n._id === notification._id);
				if (newNotification) {
					return newNotification;
				}
				return notification;
			});

		default:
			return state;
	}
};

export default notifications;
