import { calculateQuestionScore } from '../../../helpers/helpers';
import { AnswerTypesList, QuestionAnswer, TeacherDevice } from '../../../types';
import {
	UPDATE_DEVICE,
	GET_DEVICE,
	DeviceActionTypes,
	UPDATE_DEVICE_QUESTION,
	UPDATE_DEVICE_ANSWERS,
} from './constants';

const initialState: TeacherDevice = null;

const device = (state = initialState, action: DeviceActionTypes) => {
	switch (action.type) {
		case GET_DEVICE:
			return action.payload.device ? action.payload.device : null;

		case UPDATE_DEVICE_ANSWERS:
			const device = { ...state };
			const cuProps = device?.cuProps;
			const questionId = cuProps?.session?.questionId;
			const exercise = cuProps?.exam?.exercises.find(e => e.questions.find(q => q._id === questionId));
			const answers = cuProps?.answers;

			if (questionId && exercise && answers) {
				const question = exercise.questions.find(q => q._id === questionId);
				console.log('update answer', question);
				if (question) {
					const answer = question.quizData?.answers?.[action.payload.keyCode];
					const answerType = question.quizData?.layout?.answersType;
					const userAnswersKey = answers.findIndex(e => e._id === action.payload.userId);
					if (answer && userAnswersKey > -1) {
						const q = answers[userAnswersKey].questions.findIndex(q => q._id === questionId);
						if (q > -1) {
							// has answer for question
							switch (answerType) {
								case AnswerTypesList.single:
									// change answer
									answers[userAnswersKey].questions[q].quizAnswer = [
										{
											_id: answer._id,
										},
									];
									break;
								case AnswerTypesList.multi:
								case AnswerTypesList.sort:
									const answerIndex = answers[userAnswersKey].questions[q].quizAnswer?.findIndex(
										a => a._id === answer._id
									);
									if (answerIndex && answerIndex > -1) {
										// remove answer
										answers[userAnswersKey].questions[q].quizAnswer?.splice(answerIndex, 1);
									} else if (answers[userAnswersKey].questions[q].quizAnswer) {
										// add another answer
										answers[userAnswersKey].questions[q].quizAnswer?.push({
											_id: answer._id,
										});
									} else {
										// add first answer
										answers[userAnswersKey].questions[q].quizAnswer = [
											{
												_id: answer._id,
											},
										];
									}
									break;
							}
							answers[userAnswersKey].questions[q].score = calculateQuestionScore(
								question,
								answers[userAnswersKey].questions[q]
							).score;
						} else {
							const newAnswer: QuestionAnswer = {
								_id: questionId,
								quizAnswer: [
									{
										_id: answer._id,
										keyCode: action.payload.keyCode,
									},
								],
								state: 'done',
								score: -1,
							};
							newAnswer.score = calculateQuestionScore(question, newAnswer).score;
							answers[userAnswersKey].questions.push(newAnswer);
						}
					}
				}
			}
			return device;

		case UPDATE_DEVICE_QUESTION:
			return {
				...state,
				cuProps: {
					...state.cuProps,
					session: {
						...state.cuProps.session,
						questionId: action.payload.questionId,
					},
				},
			};

		case UPDATE_DEVICE:
			return {
				...state,
				...action.payload.newDevice,
			};

		default:
			return state;
	}
};

export default device;
