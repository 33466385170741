import { FETCH_USERS } from '../constants';
import { User } from '../../../../types';

const fetchUsersAction = (users: User[]) => {
	return {
		type: FETCH_USERS,
		payload: users,
	};
};

export default fetchUsersAction;
