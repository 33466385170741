import { ADD_SNACKBAR } from '../constants';
import { SnackbarResponse } from '../../../../types';

const addSnackbar = (snackbars: SnackbarResponse): any => {
	return {
		type: ADD_SNACKBAR,
		payload: snackbars,
	};
};

export default addSnackbar;
