import { RuleType } from '../../types';

const rules: RuleType = {
	static: [
		// Login As
		'admin:loginAs',

		// Dashboard
		'admin:dashboard',

		// Profile
		'admin:profile',

		// User
		'admin:users', // Users list
		'admin:users:add', // Add user

		// School
		'admin:schools', // Schools list
		'admin:schools:add', // Add school
		'admin:schools:remove', // Remove school
		'admin:schools:update', // Update school
		'admin:schools:update:addTeacher', // Add teacher

		// Classroom
		'admin:classrooms',
		'admin:classroom:update:addStudent',

		// Subjects
		'admin:subjects:get', // Get subjects

		// Translations
		'admin:translations',

		// Exam
		'voti:exams', // exams list
		'voti:exams:add', // Add exam
		'voti:exams:update', // Update exam
		'voti:exams:update:layout', // Update exam layout
		'voti:exams:update:question', // Update exam question
		'voti:exams:remove', // Remove exam

		// Question
		'voti:questionTypes', // Quetion types list
		'voti:questionTypes:get', // Get question types
		'voti:questionTypes:add', // Add question type
		'voti:questionTypes:edit', // Edit question type
		'voti:questionTypes:remove', // Remove question type

		// Segment
		'voti:segments',
		'voti:segments:get',
		'voti:segments:add',
		'voti:segments:update',
		'voti:segments:remove',

		// Revision
		'voti:revisions:add',
		'voti:revisions:get',
		'voti:revisions:remove',

		// Tags
		'voti:tags:get',
		'voti:tags:add',

		// QuestionReport
		'voti:questionReport:add',
		'voti:questionReport:get',
		'voti:questionReport:resolve',
		'voti:questionReport:reject',

		// Books
		'interaktiv:books:get',
		'interaktiv:books:add',
		'interaktiv:books:update',

		// BookCategories
		'interaktiv:bookCategory:get',
		'interaktiv:bookCategory:add',
		'interaktiv:bookCategory:update',
		'interaktiv:bookCategory:remove',

		// BookFeedbacks
		'interaktiv:bookFeedback:get',
		'interaktiv:bookFeedback:add',
		'interaktiv:bookFeedback:update',

		// Vouchers
		'interaktiv:voucher:get',
		'interaktiv:voucher:generate',
		'interaktiv:voucher:update',
	],
};

export default rules;
