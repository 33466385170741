import get from './get';
import add from './add';
import remove from './remove';
import edit from './edit';

export default {
	get,
	add,
	remove,
	edit,
};
