import { TagDocument, EmailTemplateDocument } from '../../../../types';

export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const REMOVE_EMAIL_TEMPLATE = 'REMOVE_EMAIL_TEMPLATE';

export interface AddEmailTemplateAction {
	type: typeof ADD_EMAIL_TEMPLATE;
	payload: {
		newEmailTemplate: TagDocument;
	};
}

export interface GetEmailTemplateAction {
	type: typeof GET_EMAIL_TEMPLATES;
	payload: EmailTemplateDocument[];
}

export interface RemoveEmailTemplateAction {
	type: typeof REMOVE_EMAIL_TEMPLATE;
	payload: {
		success: boolean;
		emailTemplateId: string;
	};
}

export interface UpdateEmailTemplateAction {
	type: typeof UPDATE_EMAIL_TEMPLATE;
	payload: {
		newEmailTemplate: TagDocument;
	};
}

export type EmailTemplateActionTypes =
	| AddEmailTemplateAction
	| GetEmailTemplateAction
	| RemoveEmailTemplateAction
	| UpdateEmailTemplateAction;
