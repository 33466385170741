import { REMOVE_QUOTE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveExamResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeQuestionRequest = (quoteId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/quote/remove/${quoteId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeQuestionAction = (res: RemoveExamResponse, quoteId: string) => {
	return {
		type: REMOVE_QUOTE,
		payload: {
			quoteId,
		},
	};
};

const removeQuestion = (quoteId: string, token: string): any => {
	return (dispatch: any) => {
		return removeQuestionRequest(quoteId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeQuestionAction(res, quoteId));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default removeQuestion;
