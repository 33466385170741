import { GET_REVISION_ITEMS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RevisionFilter } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getRevisionItemsRequest = (token: string, revisionId: string, filter: RevisionFilter) => {
	return axios.get(`${reducerConfig.api}/revision/get-items`, {
		params: { revisionId, ...filter },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getRevisionItemsAction = (res: AxiosResponse) => {
	return {
		type: GET_REVISION_ITEMS,
		payload: res.data,
	};
};

const getRevisionItems = (token: string, revisionId: string, filter: RevisionFilter): any => {
	return (dispatch: any) => {
		return getRevisionItemsRequest(token, revisionId, filter).then((res: AxiosResponse) => {
			dispatch(getRevisionItemsAction(res));
			return res.data;
		});
	};
};

export default getRevisionItems;
