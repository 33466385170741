import { SET_PAIRED, RegActionTypes } from './constants';

const initialState: string[] = [];

const socket = (state = initialState, action: RegActionTypes) => {
	switch (action.type) {
		case SET_PAIRED:
			return action.payload;

		default:
			return state;
	}
};

export default socket;
