import { ADD_QUOTE } from '../constants';
import axios from 'axios';
import { AddTagResponse, Quote } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addQuoteRequest = (quote: Quote, token: string) => {
	return axios.put(`${reducerConfig.api}/quote/add`, quote, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addQuoteAction = (res: AddTagResponse) => {
	return {
		type: ADD_QUOTE,
		payload: res.data,
	};
};

const addQuote = (quote: Quote, token: string): any => {
	return (dispatch: any) => {
		return addQuoteRequest(quote, token).then((res: AddTagResponse) => {
			if (res.data.success) {
				dispatch(addQuoteAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newQuote;
		});
	};
};

export default addQuote;
