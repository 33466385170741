import { UPDATE_NOTIFICATION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const updateSeenRequest = (ids: string[], token: string) => {
	return axios.post(
		`${reducerConfig.api}/notification/seen`,
		{
			ids,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateSeenAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_NOTIFICATION,
		payload: res.data,
	};
};

const updateSeen = (ids: string[], token: string): any => {
	return (dispatch: any) => {
		return updateSeenRequest(ids, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateSeenAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newHomewrork;
		});
	};
};

export default updateSeen;
