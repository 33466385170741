import { UPDATE_CUSTOM_EXAM } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateCustomExamActions, UpdateCustomExam, UpdateDeviceResponse } from '../../../../types';
import fetchQuestions from '../../questions/actions/fetch';
import reducerConfig from '../../reducerConfig';

const updateCustomExamRequest = (
	action: UpdateCustomExamActions,
	data: UpdateCustomExam,
	token: string
) => {
	return axios.post(`${reducerConfig.api}/custom-exam/${action}`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const updateCustomExamAction = (res: UpdateDeviceResponse) => {
	return {
		type: UPDATE_CUSTOM_EXAM,
		payload: res.data,
	};
};

const updateCustomExam = (
	action: UpdateCustomExamActions,
	data: UpdateCustomExam,
	token: string
): any => {
	return (dispatch: any) => {
		return updateCustomExamRequest(action, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateCustomExamAction(res));
			}
			if (res.data.questions) {
				dispatch(fetchQuestions(res.data.questions));
			}
			return res.data;
		});
	};
};

export default updateCustomExam;
