import { TranslationDocument } from '../../../../types';

export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';

export interface UpdateTranslationAction {
	type: typeof UPDATE_TRANSLATION;
	payload: {
		success: boolean;
		translations: TranslationDocument[];
	};
}

export interface GetTranslationsAction {
	type: typeof GET_TRANSLATIONS;
	payload: TranslationDocument[];
}

export type TranslationActionTypes = UpdateTranslationAction | GetTranslationsAction;
