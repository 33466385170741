import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const fillEmailRequest = (userId: string, email: string) => {
	return axios.post(`${reducerConfig.api}/user/fill-email`, {
		userId,
		email,
	});
};

const fillEmail = (userId: string, email: string): any => {
	return (dispatch: any) => {
		return fillEmailRequest(userId, email).then((res: AxiosResponse) => {
			return res;
		});
	};
};

export default fillEmail;
