import {
	ADD_CUSTOM_EXAM,
	UPDATE_CUSTOM_EXAM,
	REMOVE_CUSTOM_EXAM,
	GET_CUSTOM_EXAMS,
	CustomExamActionTypes,
} from './constants';
import { CustomExamDocument } from '../../../types';

const initialState: CustomExamDocument[] = [];

const customExam = (state = initialState, action: CustomExamActionTypes) => {
	switch (action.type) {
		case GET_CUSTOM_EXAMS:
			if (action.payload.success) {
				return action.payload.customExams;
			}
			return [];

		case ADD_CUSTOM_EXAM:
			return [...state, action.payload.newCustomExam];

		case UPDATE_CUSTOM_EXAM:
			return state.map(s => {
				if (s._id === action.payload.newCustomExam._id) {
					return action.payload.newCustomExam;
				}
				return s;
			});

		case REMOVE_CUSTOM_EXAM:
			if (action.payload.success) {
				return state.filter(s => s._id !== action.payload.customExamId);
			}
			return state;

		default:
			return state;
	}
};

export default customExam;
