import { BookDocument } from '../../../types';
import { GET_VOUCHERS, GENERATE_VOUCHER, UPDATE_VOUCHER, VoucherActionTypes } from './constants';

const initialState: BookDocument[] = [];

const books = (state = initialState, action: VoucherActionTypes) => {
	switch (action.type) {
		case GET_VOUCHERS:
			return action.payload;

		case GENERATE_VOUCHER:
			return [...state, action.payload];

		case UPDATE_VOUCHER:
			return state.map(book => {
				if (book._id === action.payload.newVoucher._id) {
					return action.payload.newVoucher;
				}
				return book;
			});

		default:
			return state;
	}
};

export default books;
