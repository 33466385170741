import { ADD_DEVICES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { Device, AddDeviceResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addDeviceRequest = (data: Device, token: string) => {
	return axios.put(`${reducerConfig.api}/device/add`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addDeviceAction = (res: AddDeviceResponse) => {
	return {
		type: ADD_DEVICES,
		payload: res.data,
	};
};

const addDevice = (data: Device, token: string): any => {
	return (dispatch: any) => {
		return addDeviceRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addDeviceAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newDevice;
		});
	};
};

export default addDevice;
