import { InviteDocument } from '../../../types';
import {
	ADD_INVITE,
	CONFIRM_INVITE,
	REJECT_INVITE,
	GET_INVITES,
	InviteActionTypes,
} from './constants';

const initialState: InviteDocument[] = [];

const invites = (state = initialState, action: InviteActionTypes) => {
	switch (action.type) {
		case GET_INVITES:
			return action.payload;

		case ADD_INVITE:
			if (action.payload.newInvites) {
				return [...state, ...action.payload.newInvites];
			}
			return state;

		case CONFIRM_INVITE:
			return state.map(invite => {
				if (invite._id === action.payload.newInvite._id) {
					return {
						...invite,
						...action.payload.newInvite,
					};
				}
				return invite;
			});

		case REJECT_INVITE:
			return state.map(invite => {
				if (invite._id === action.payload.newInvite._id) {
					return action.payload.newInvite;
				}
				return invite;
			});

		default:
			return state;
	}
};

export default invites;
