import { UPDATE_REVISION_ITEMS } from '../constants';
import { RevisionItemDocument } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateRevisionItems = (revisionItems: RevisionItemDocument[]): any => {
	return {
		type: UPDATE_REVISION_ITEMS,
		payload: revisionItems,
	};
};

export default updateRevisionItems;
