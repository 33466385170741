import { ADD_REGISTRATION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { Registration } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addRegistrationRequest = (registration: Registration) => {
	return axios.put(`${reducerConfig.api}/registration/add`, { registration });
};

const addRegistrationAction = (res: AxiosResponse) => {
	return {
		type: ADD_REGISTRATION,
		payload: res.data,
	};
};

const addRegistration = (registration: Registration): any => {
	return (dispatch: any) => {
		return addRegistrationRequest(registration).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addRegistrationAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res;
		});
	};
};

export default addRegistration;
