import { UPDATE_BOOK_PAGE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const emptyBookPageRequest = (pageId: string, token: string) => {
	return axios.post(
		`${reducerConfig.api}/book/update/empty`,
		{
			pageId,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const emptyBookPageAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_BOOK_PAGE,
		payload: { success: true, newPage: res.data.newPage },
	};
};

const emptyBookPage = (pageId: string, token: string): any => {
	return (dispatch: any) => {
		return emptyBookPageRequest(pageId, token).then((res: AxiosResponse) =>
			dispatch(emptyBookPageAction(res))
		);
	};
};

export default emptyBookPage;
