import { GET_QUESTIONS_STATS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import fetchUserAction from '../../../common/users/actions/fetch';
import fetchExercises from '../../exercises/actions/fetch';
import { getSegmentsAction } from '../../segments/actions/get';
import { QuestionsStats, Stats, StatsFilter } from '../../../../types';
import fetchQuestions from '../../questions/actions/fetch';
import reducerConfig from '../../reducerConfig';

const getStatsOverviewRequest = (token: string, filter: StatsFilter) => {
	return axios.get(`${reducerConfig.api}/stats/get/overview`, {
		params: {
			...filter,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getStatsOverviewAction = (stats: Stats<QuestionsStats>) => {
	return {
		type: GET_QUESTIONS_STATS,
		payload: stats,
	};
};

const getStatsOverview = (token: string, filter: StatsFilter): any => {
	return (dispatch: any) => {
		return getStatsOverviewRequest(token, filter).then((res: AxiosResponse) => {
			dispatch(getStatsOverviewAction(res.data.stats));
			if (res.data.users) {
				dispatch(fetchUserAction(res.data.users));
			}
			if (res.data.segments) {
				dispatch(getSegmentsAction(res.data.segments));
			}
			if (res.data.exercises) {
				dispatch(fetchExercises(res.data.exercises));
			}
			if (res.data.questions) {
				dispatch(fetchQuestions(res.data.questions));
			}
			return res.data;
		});
	};
};

export default getStatsOverview;
