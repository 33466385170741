import { REJECT_QUESTION_REPORT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const rejectQuestionReportRequest = (questionReportId: string, token: string) => {
	return axios.post(`${reducerConfig.api}/question-report/reject/${questionReportId}`, null, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const rejectQuestionReportAction = (res: AxiosResponse) => {
	return {
		type: REJECT_QUESTION_REPORT,
		payload: res.data,
	};
};

const rejectQuestionReport = (questionReportId: string, token: string): any => {
	return (dispatch: any) => {
		return rejectQuestionReportRequest(questionReportId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(rejectQuestionReportAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newQuestionReport;
		});
	};
};

export default rejectQuestionReport;
