import { GET_SEGMENTS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { SegmentDocument } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getSegmentsRequest = (token: string, filter?: { subject: string; grade: number }) => {
	return axios.get(`${reducerConfig.api}/segment/get`, {
		params: filter,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getSegmentsAction = (segments: SegmentDocument[]) => {
	return {
		type: GET_SEGMENTS,
		payload: segments,
	};
};

const getSegments = (token: string, filter?: { subject: string; grade: number }): any => {
	return (dispatch: any) => {
		return getSegmentsRequest(token, filter).then((res: AxiosResponse) => {
			dispatch(getSegmentsAction(res.data));
			return res.data;
		});
	};
};

export default getSegments;
