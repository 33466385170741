import { ADD_CLASSROOM } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { AddSchoolResponse, AddClassroom } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addRequest = (data: AddClassroom, token: string) => {
	return axios.put(`${reducerConfig.api}/classroom/add`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addAction = (res: AddSchoolResponse) => {
	return {
		type: ADD_CLASSROOM,
		payload: res.data,
	};
};

const add = (data: AddClassroom, token: string): any => {
	return (dispatch: any) => {
		return addRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data;
		});
	};
};

export default add;
