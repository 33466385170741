import { DeviceDocument } from '../../../types';
import {
	ADD_DEVICES,
	REMOVE_DEVICES,
	UPDATE_DEVICES,
	GET_DEVICES,
	DeviceActionTypes,
} from './constants';

const initialState: DeviceDocument[] = [];

const devices = (state = initialState, action: DeviceActionTypes) => {
	switch (action.type) {
		case GET_DEVICES:
			return action.payload;

		case ADD_DEVICES:
			return [...state, action.payload.newDevice];

		case REMOVE_DEVICES:
			return state.filter(device => device._id !== action.payload.deviceId);

		case UPDATE_DEVICES:
			return state.map(device => {
				if (device._id === action.payload.newDevice._id) {
					return {
						...device,
						...action.payload.newDevice,
					};
				}
				return device;
			});

		default:
			return state;
	}
};

export default devices;
