import { UPDATE_CLASSROOM } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateClassroomActions, UpdateClassroom, UpdateSchoolResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import fetchUserAction from '../../../common/users/actions/fetch';
import reducerConfig from '../../reducerConfig';

const updateClassroomRequest = (
	action: UpdateClassroomActions,
	data: UpdateClassroom,
	token: string
) => {
	const uri = `${reducerConfig.api}/classroom/${action}`;
	const options = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	if (action.startsWith('add')) {
		return axios.put(uri, data, options);
	} else if (action.startsWith('remove')) {
		return axios.delete(uri, { ...options, data });
	}
	return axios.post(uri, data, options);
};

export const updateClassroomAction = (res: UpdateSchoolResponse) => {
	return {
		type: UPDATE_CLASSROOM,
		payload: res.data,
	};
};

const updateClassroom = (
	action: UpdateClassroomActions,
	data: UpdateClassroom,
	token: string
): any => {
	return (dispatch: any) => {
		return updateClassroomRequest(action, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateClassroomAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newUsers) {
				dispatch(fetchUserAction(res.data.newUsers));
			}
			return res.data;
		});
	};
};

export default updateClassroom;
