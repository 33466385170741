import { UPDATE_BOOK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const addBookEditorRequest = (bookId: string, userId: string, token: string) => {
	return axios.put(
		`${reducerConfig.api}/book/add/editor`,
		{ bookId, userId },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const addBookEditorAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_BOOK,
		payload: res.data,
	};
};

const addBookEditor = (bookId: string, userId: string, token: string): any => {
	return (dispatch: any) => {
		return addBookEditorRequest(bookId, userId, token).then((res: AxiosResponse) => {
			dispatch(addBookEditorAction(res));
			return res;
		});
	};
};

export default addBookEditor;
