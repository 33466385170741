import { UPDATE_DEVICE_QUESTION } from '../constants';

export interface updateDeviceQuestionProps {
	questionId: string;
}

const updateDeviceQuestion = (props: updateDeviceQuestionProps): any => {
	return {
		type: UPDATE_DEVICE_QUESTION,
		payload: {
			questionId: props.questionId,
		},
	};
};

export default updateDeviceQuestion;
