import { RbacRules } from '../types/types';

import superadmin from './roles/superadmin';
import admin from './roles/admin';
import employeeSales from './roles/employeeSales';
import employeeAdmin from './roles/employeeAdmin';
import employeeModerator from './roles/employeeModerator';
import employeeMaker from './roles/employeeMaker';
import customerTeacher from './roles/customerTeacher';
import customerStudent from './roles/customerStudent';
import unauthorized from './roles/unauthorized';

const rules: RbacRules = {
	superadmin,
	admin,
	employee: {
		admin: employeeAdmin,
		sales: employeeSales,
		moderator: employeeModerator,
		maker: employeeMaker,
	},
	customer: {
		teacher: customerTeacher,
		student: customerStudent,
	},
	unauthorized,
};

export default rules;
