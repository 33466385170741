import { ADD_BOOK_FEEDBACK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { BookFeedback, GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const addBookFeedbackRequest = (bookFeedback: BookFeedback, token: string) => {
	return axios.put(
		`${reducerConfig.api}/book/addFeedback`,
		{ bookFeedback },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const addBookFeedbackAction = (res: GetUsersResponse) => {
	return {
		type: ADD_BOOK_FEEDBACK,
		payload: res.data,
	};
};

const addBookFeedback = (bookFeedback: BookFeedback, token: string): any => {
	return (dispatch: any) => {
		return addBookFeedbackRequest(bookFeedback, token).then((res: AxiosResponse) => {
			dispatch(addBookFeedbackAction(res));
			return res;
		});
	};
};

export default addBookFeedback;
