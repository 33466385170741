import { ADD_EMAIL_TEMPLATE } from '../constants';
import axios from 'axios';
import { EmailTemplate, AddTagResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addEmailTemplateRequest = (emailTemplate: EmailTemplate, token: string) => {
	return axios.put(`${reducerConfig.api}/email-template/add`, emailTemplate, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addEmailTemplateAction = (res: AddTagResponse) => {
	return {
		type: ADD_EMAIL_TEMPLATE,
		payload: res.data,
	};
};

const addEmailTemplate = (emailTemplate: EmailTemplate, token: string): any => {
	return (dispatch: any) => {
		return addEmailTemplateRequest(emailTemplate, token).then((res: AddTagResponse) => {
			if (res.data.success) {
				dispatch(addEmailTemplateAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data;
		});
	};
};

export default addEmailTemplate;
