import { UPDATE_BOOK_CATEGORY } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateBookResponse, BookCategory } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateBookCategoryRequest = (data: BookCategory, token: string) => {
	return axios.post(`${reducerConfig.api}/book-category/update`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const updateBookCategoryAction = (res: UpdateBookResponse) => {
	return {
		type: UPDATE_BOOK_CATEGORY,
		payload: res.data,
	};
};

const updateBookCategory = (data: BookCategory, token: string): any => {
	return (dispatch: any) => {
		return updateBookCategoryRequest(data, token).then((res: AxiosResponse) =>
			dispatch(updateBookCategoryAction(res))
		);
	};
};

export default updateBookCategory;
