import { GET_BOOK_PAGES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const removeBookChapterRequest = (pageId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/book/remove/page`, {
		data: {
			pageId,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const removeBookChapterAction = (res: AxiosResponse) => {
	return {
		type: GET_BOOK_PAGES,
		payload: res.data.newPages,
	};
};

const removeBookChapter = (pageId: string, token: string): any => {
	return (dispatch: any) => {
		return removeBookChapterRequest(pageId, token).then((res: AxiosResponse) =>
			dispatch(removeBookChapterAction(res))
		);
	};
};

export default removeBookChapter;
