import { GET_BOOKS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getBooksRequest = (token: string) => {
	return axios.get(`${reducerConfig.api}/book`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getBooksAction = (res: GetUsersResponse) => {
	return {
		type: GET_BOOKS,
		payload: res.data.books,
	};
};

const getBooks = (token: string): any => {
	return (dispatch: any) => {
		return getBooksRequest(token).then((res: AxiosResponse) => dispatch(getBooksAction(res)));
	};
};

export default getBooks;
