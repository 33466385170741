import { TeachingPlanDocument } from '../../../../types';

export const ADD_TEACHING_PLAN = 'ADD_TEACHING_PLAN';
export const UPDATE_TEACHING_PLAN = 'UPDATE_TEACHING_PLAN';
export const REMOVE_TEACHING_PLAN = 'REMOVE_TEACHING_PLAN';
export const GET_TEACHING_PLANS = 'GET_TEACHING_PLANS';

export interface AddTeachingPlanAction {
	type: typeof ADD_TEACHING_PLAN;
	payload: {
		newTeachingPlan: TeachingPlanDocument;
	};
}

export interface RemoveTeachingPlanAction {
	type: typeof REMOVE_TEACHING_PLAN;
	payload: {
		teachingPlanId: string;
		token: string;
	};
}

export interface UpdateTeachingPlanAction {
	type: typeof UPDATE_TEACHING_PLAN;
	payload: {
		teachingPlanId: string;
		newTeachingPlan: TeachingPlanDocument;
		token: string;
	};
}

export interface GetTeachingPlansAction {
	type: typeof GET_TEACHING_PLANS;
	payload: {
		newTeachingPlan: TeachingPlanDocument[];
	};
}

export type TeachingPlanActionTypes =
	| AddTeachingPlanAction
	| RemoveTeachingPlanAction
	| UpdateTeachingPlanAction
	| GetTeachingPlansAction;
