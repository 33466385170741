import { ADD_HOMEWORK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { AddHomework } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const addHomeworkRequest = (homework: AddHomework, token: string) => {
	return axios.put(`${reducerConfig.api}/homework/add`, homework, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addHomeworkAction = (res: AxiosResponse) => {
	return {
		type: ADD_HOMEWORK,
		payload: res.data,
	};
};

const addHomework = (homework: AddHomework, token: string): any => {
	return (dispatch: any) => {
		return addHomeworkRequest(homework, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addHomeworkAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newHomewrork;
		});
	};
};

export default addHomework;
