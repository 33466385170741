import { UPDATE_REVISION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { RevisionActions, UpdateRevision } from '../../../../types';
import updateRevisionItems from '../../revisionItems/actions/update';
import fetchQuestions from '../../questions/actions/fetch';
import reducerConfig from '../../reducerConfig';

const updateRevisionRequest = (action: RevisionActions, data: UpdateRevision, token: string) => {
	return axios.post(
		`${reducerConfig.api}/revision/update`,
		{
			action,
			data,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateRevisionAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_REVISION,
		payload: res.data,
	};
};

const updateRevision = (action: RevisionActions, data: UpdateRevision, token: string): any => {
	return (dispatch: any) => {
		return updateRevisionRequest(action, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateRevisionAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newQuestions) {
				dispatch(fetchQuestions(res.data.newQuestions));
			}
			if (res.data.newItems) {
				dispatch(updateRevisionItems(res.data.newItems));
			}
			return res;
		});
	};
};

export default updateRevision;
