import { RevisionDocument } from '../../../types';
import {
	ADD_REVISION,
	GET_REVISIONS,
	FETCH_REVISION,
	UPDATE_REVISION,
	RevisionActionTypes,
} from './constants';

const initialState: RevisionDocument[] = [];

const tags = (state = initialState, action: RevisionActionTypes) => {
	switch (action.type) {
		case GET_REVISIONS:
			return action.payload;

		case FETCH_REVISION:
			return state.map(item => {
				const newItem = action.payload.find(i => i._id === item._id);
				if (newItem) {
					return newItem;
				}
				return item;
			});

		case ADD_REVISION:
			return [...state, action.payload.newRevision];

		case UPDATE_REVISION:
			const newRevisions = action.payload.newRevisions;
			if (newRevisions) {
				const add: RevisionDocument[] = [];
				newRevisions.forEach(revision => {
					const isNew = state.findIndex(s => s._id === revision._id) === -1;
					if (isNew) {
						add.push(revision);
					}
				});
				return state.concat(add).map(revision => {
					const newRevision = newRevisions.find(r => r._id === revision._id);
					if (newRevision) {
						return newRevision;
					}
					return revision;
				});
			}
			return state;
		default:
			return state;
	}
};

export default tags;
