import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { ErrorInfo } from 'react';
import T from '../T';
import Replay from '@mui/icons-material/Replay';
import axios from 'axios';

interface ErrorBoundaryState {
	hasError: boolean;
}

interface ErrorBoundaryProps {
	children: any;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// You can also log the error to an error reporting service
		if (process.env.REACT_APP_API_CENTRAL !== 'https://localhost:5100') {
			axios
				.post(`${process.env.REACT_APP_API_CENTRAL}/error/report`, {
					href: window.location.href,
					error: error.message,
					stack: error.stack,
					info: errorInfo.componentStack,
				})
				.then(res => {
					console.log('error sent!');
				});
		} else {
			console.log('error', error);
		}
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Container
					style={{
						height: '100vh',
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
					}}
				>
					<Box>
						<Paper>
							<Alert severity="error">
								<AlertTitle>
									<T id="pages.errorBoundary.title" />
								</AlertTitle>
								<T id="pages.errorBoundary.description" />
							</Alert>
						</Paper>
						<Box mt={3} textAlign="center">
							<Button
								variant="outlined"
								color="primary"
								onClick={() => {
									window.location.replace('/');
								}}
								startIcon={<Replay />}
							>
								<T id="pages.errorBoundary.button" />
							</Button>
						</Box>
					</Box>
				</Container>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
