import { REMOVE_USER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveUserResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeUserRequest = (userId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/user/remove`, {
		params: {
			userId,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeUserAction = (res: RemoveUserResponse, userId: string) => {
	return {
		type: REMOVE_USER,
		payload: {
			userId,
		},
	};
};

const removeUser = (userId: string, token: string): any => {
	return (dispatch: any) => {
		return removeUserRequest(userId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeUserAction(res, userId));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default removeUser;
