import { FETCH_EXERCISES } from '../constants';
import { ExerciseDocument } from '../../../../types';

const fetchExercises = (exercises: ExerciseDocument[]): any => {
	return {
		type: FETCH_EXERCISES,
		payload: exercises,
	};
};

export default fetchExercises;
