import { TagDocument } from '../../../../types';

export const ADD_TAG = 'ADD_TAG';
export const GET_TAGS = 'GET_TAGS';

export interface AddTagAction {
	type: typeof ADD_TAG;
	payload: {
		newTag: TagDocument;
	};
}

export interface GetTagsAction {
	type: typeof GET_TAGS;
	payload: TagDocument[];
}

export type TagActionTypes = AddTagAction | GetTagsAction;
