import { GET_SUBJECTS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetSubjectsResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getSubjectsRequest = (token: string) => {
	return axios.get(`${reducerConfig.api}/subject/get`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getSubjectAction = (res: GetSubjectsResponse) => {
	return {
		type: GET_SUBJECTS,
		payload: res.data,
	};
};

const getSubjects = (token: string): any => {
	return (dispatch: any) => {
		return getSubjectsRequest(token).then((res: AxiosResponse) => dispatch(getSubjectAction(res)));
	};
};

export default getSubjects;
