import { BookCategoryDocument } from '../../../../types';

export const GET_BOOK_CATEGORIES = 'GET_BOOK_CATEGORIES';
export const ADD_BOOK_CATEGORY = 'ADD_BOOK_CATEGORY';
export const REMOVE_BOOK_CATEGORY = 'REMOVE_BOOK_CATEGORY';
export const UPDATE_BOOK_CATEGORY = 'UPDATE_BOOK_CATEGORY';

export interface GetBookCategoriesAction {
	type: typeof GET_BOOK_CATEGORIES;
	payload: {
		success: boolean;
		bookCategories: BookCategoryDocument[];
	};
}

export interface AddBookCategoryAction {
	type: typeof ADD_BOOK_CATEGORY;
	payload: {
		success: boolean;
		newBookCategory: BookCategoryDocument;
	};
}

export interface RemoveBookCategoryAction {
	type: typeof REMOVE_BOOK_CATEGORY;
	payload: {
		success: boolean;
		categoryId: string;
	};
}

export interface UpdateBookCategoryAction {
	type: typeof UPDATE_BOOK_CATEGORY;
	payload: {
		success: boolean;
		newBookCategory: BookCategoryDocument;
	};
}

export type BookCategoryActionTypes =
	| GetBookCategoriesAction
	| AddBookCategoryAction
	| RemoveBookCategoryAction
	| UpdateBookCategoryAction;
