import { DeviceDocument } from '../../../../types';

export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATE_DEVICE_QUESTION = 'UPDATE_DEVICE_QUESTION';
export const UPDATE_DEVICE_ANSWERS = 'UPDATE_DEVICE_ANSWERS';
export const GET_DEVICE = 'GET_DEVICE';

export interface UpdateDeviceAction {
	type: typeof UPDATE_DEVICE;
	payload: {
		deviceId: string;
		newDevice: DeviceDocument;
		token: string;
	};
}

export interface UpdateDeviceQuestionAction {
	type: typeof UPDATE_DEVICE_QUESTION;
	payload: {
		questionId: string;
	};
}

export interface UpdateDeviceAnswersAction {
	type: typeof UPDATE_DEVICE_ANSWERS;
	payload: {
		userId: string;
		keyCode: 0 | 1 | 2 | 3;
	};
}

export interface GetDeviceAction {
	type: typeof GET_DEVICE;
	payload: {
		device: DeviceDocument;
	};
}

export type DeviceActionTypes =
	| UpdateDeviceAction
	| UpdateDeviceQuestionAction
	| UpdateDeviceAnswersAction
	| GetDeviceAction;
