import { UPDATE_BOOK_PAGE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateBookPageResponse, UpdateBookPage, UpdateBookPageActions } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateBookPageRequest = (
	action: UpdateBookPageActions,
	data: UpdateBookPage,
	token: string
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const uri = `${reducerConfig.api}/book/${action}`;
	if (action.startsWith('add')) {
		return axios.put(uri, data, options);
	} else if (action.startsWith('remove')) {
		return axios.delete(uri, { ...options, data });
	}
	return axios.post(uri, data, options);
};

export const updateBookPageAction = (res: UpdateBookPageResponse) => {
	return {
		type: UPDATE_BOOK_PAGE,
		payload: res.data,
	};
};

const updateBookPage = (
	action: UpdateBookPageActions,
	data: UpdateBookPage,
	token: string
): any => {
	return (dispatch: any) => {
		return updateBookPageRequest(action, data, token).then((res: AxiosResponse) => {
			dispatch(updateBookPageAction(res));
			return res;
		});
	};
};

export default updateBookPage;
