import { TranslationDocument } from '../../../types';
import { UPDATE_TRANSLATION, GET_TRANSLATIONS, TranslationActionTypes } from './constants';

const initialState: TranslationDocument[] = [];

const translations = (state = initialState, action: TranslationActionTypes) => {
	switch (action.type) {
		case GET_TRANSLATIONS:
			return action.payload;

		case UPDATE_TRANSLATION:
			return action.payload.translations;

		default:
			return state;
	}
};

export default translations;
