import { AddSubjectResponse, EditSubjectResponse, SubjectDocument } from '../../../../types';

export const ADD_SUBJECT = 'ADD_SUBJECT';
export const EDIT_SUBJECT = 'EDIT_SUBJECT';
export const GET_SUBJECTS = 'GET_SUBJECTS';

export interface AddSubjectAction {
	type: typeof ADD_SUBJECT;
	payload: AddSubjectResponse;
}

export interface EditSubjectAction {
	type: typeof EDIT_SUBJECT;
	payload: EditSubjectResponse;
}

export interface GetSubjectsAction {
	type: typeof GET_SUBJECTS;
	payload: SubjectDocument[];
}

export type SubjectActionTypes = AddSubjectAction | EditSubjectAction | GetSubjectsAction;
