import { GET_TEACHING_PLANS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

type TeachingPlanParams = {
	subject: string;
	grade: number;
	schoolType: string;
};

const getTeachingPlanRequest = (token: string, params?: TeachingPlanParams) => {
	return axios.get(`${reducerConfig.api}/teaching-plan/get`, {
		params,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getTeachingPlanAction = (res: AxiosResponse) => {
	return {
		type: GET_TEACHING_PLANS,
		payload: res.data,
	};
};

const getTeachingPlans = (token: string, params?: TeachingPlanParams): any => {
	return (dispatch: any) => {
		return getTeachingPlanRequest(token, params).then((res: AxiosResponse) =>
			dispatch(getTeachingPlanAction(res))
		);
	};
};

export default getTeachingPlans;
