import { GET_REGISTRATIONS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const getRegistrationRequest = (token: string) => {
	return axios.get(`${reducerConfig.api}/registration/get`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getRegistrationAction = (res: AxiosResponse) => {
	return {
		type: GET_REGISTRATIONS,
		payload: res.data,
	};
};

const getRegistrations = (token: string): any => {
	return (dispatch: any) => {
		return getRegistrationRequest(token).then((res: AxiosResponse) =>
			dispatch(getRegistrationAction(res))
		);
	};
};

export default getRegistrations;
