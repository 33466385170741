import { ADD_EXERCISE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { Exercise } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addExerciseRequest = (data: Exercise, token: string) => {
	return axios.put(`${reducerConfig.api}/exercise/add`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addExerciseAction = (res: AxiosResponse) => {
	return {
		type: ADD_EXERCISE,
		payload: res.data,
	};
};

const addExercise = (data: Exercise, token: string): any => {
	return (dispatch: any) => {
		return addExerciseRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addExerciseAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res;
		});
	};
};

export default addExercise;
