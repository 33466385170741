import { RepeatingItemDocument } from '../../../../types';

export const GET_REPEATING_ITEMS = 'GET_REPEATING_ITEMS';
export const ADD_REPEATING_ITEMS = 'ADD_REPEATING_ITEMS';
export const UPDATE_REPEATING_ITEMS = 'UPDATE_REPEATING_ITEMS';

export interface GetRepeatingItemsAction {
	type: typeof GET_REPEATING_ITEMS;
	payload: RepeatingItemDocument[];
}

export interface AddRepeatingItemsAction {
	type: typeof ADD_REPEATING_ITEMS;
	payload: RepeatingItemDocument[];
}

export interface UpdateRepeatingItemsAction {
	type: typeof UPDATE_REPEATING_ITEMS;
	payload: RepeatingItemDocument[];
}

export type RepeatingActionTypes =
	| GetRepeatingItemsAction
	| AddRepeatingItemsAction
	| UpdateRepeatingItemsAction;
