import { ADD_QUESTION_REPORT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { QuestionReport } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import fetchUsersAction from '../../../common/users/actions/fetch';
import { updateClassroomAction } from '../../../common/classrooms/actions/update';
import reducerConfig from '../../reducerConfig';

const addQuestionReportRequest = (data: QuestionReport, token: string) => {
	return axios.put(`${reducerConfig.api}/question-report/add`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addQuestionReportAction = (res: AxiosResponse) => {
	return {
		type: ADD_QUESTION_REPORT,
		payload: res.data,
	};
};

const addQuestionReport = (data: QuestionReport, token: string): any => {
	return (dispatch: any) => {
		return addQuestionReportRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addQuestionReportAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newUsers) {
				dispatch(fetchUsersAction(res.data.newUsers));
			}
			if (res.data.newClassroom) {
				dispatch(updateClassroomAction(res));
			}
			return res;
		});
	};
};

export default addQuestionReport;
