import { ClassroomDocument, ExamDocument } from '../../../../types';

export const ADD_CLASSROOM = 'ADD_CLASSROOM';
export const UPDATE_CLASSROOM = 'UPDATE_CLASSROOM';
export const REMOVE_CLASSROOM = 'REMOVE_CLASSROOM';
export const GET_CLASSROOMS = 'GET_CLASSROOMS';

export interface AddClassroomAction {
	type: typeof ADD_CLASSROOM;
	payload: {
		newClassroom: ClassroomDocument;
	};
}

export interface RemoveClassroomAction {
	type: typeof REMOVE_CLASSROOM;
	payload: {
		classroomId: string;
		token: string;
	};
}

export interface UpdateClassroomAction {
	type: typeof UPDATE_CLASSROOM;
	payload: {
		classroomId: string;
		newClassroom: ClassroomDocument;
		token: string;
	};
}

export interface GetClassroomsAction {
	type: typeof GET_CLASSROOMS;
	payload: {
		newExam: ExamDocument[];
	};
}

export type ClassroomActionTypes =
	| AddClassroomAction
	| RemoveClassroomAction
	| UpdateClassroomAction
	| GetClassroomsAction;
