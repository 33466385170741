import { GET_MIGRATION_TABLES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getTablesRequest = () => {
	return axios.get(`${reducerConfig.api}/migrate/get-tables`);
};

export const getTablesAction = (res: GetUsersResponse) => {
	return {
		type: GET_MIGRATION_TABLES,
		payload: res.data,
	};
};

const getTables = (): any => {
	return (dispatch: any) => {
		return getTablesRequest().then((res: AxiosResponse) => dispatch(getTablesAction(res)));
	};
};

export default getTables;
