import { QuestionReportDocument } from '../../../types';
import {
	ADD_QUESTION_REPORT,
	RESOLVE_QUESTION_REPORT,
	REJECT_QUESTION_REPORT,
	GET_QUESTION_REPORTS,
	QuestionReportActionTypes,
} from './constants';

const initialState: QuestionReportDocument[] = [];

const invites = (state = initialState, action: QuestionReportActionTypes) => {
	switch (action.type) {
		case GET_QUESTION_REPORTS:
			return action.payload;

		case ADD_QUESTION_REPORT:
			if (action.payload.newQuestionReport) {
				return [...state, action.payload.newQuestionReport];
			}
			return state;

		case RESOLVE_QUESTION_REPORT:
			return state.map(invite => {
				if (invite._id === action.payload.newQuestionReport._id) {
					return {
						...invite,
						...action.payload.newQuestionReport,
					};
				}
				return invite;
			});

		case REJECT_QUESTION_REPORT:
			return state.map(invite => {
				if (invite._id === action.payload.newQuestionReport._id) {
					return action.payload.newQuestionReport;
				}
				return invite;
			});

		default:
			return state;
	}
};

export default invites;
