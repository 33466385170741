import getQuestionsStats from './getQuestionsStats';
import getSegmentsStats from './getSegmentsStats';
import getStudentsStats from './getStudentsStats';
import getStatsOverview from './getStatsOverview';

export default {
	getQuestionsStats,
	getSegmentsStats,
	getStudentsStats,
	getStatsOverview,
};
