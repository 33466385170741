import createTheme from '@mui/material/styles/createTheme';

// A custom theme for this app
const theme = createTheme({
	typography: {
		fontFamily: 'Montserrat',
	},
	palette: {
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#fbc02d',
		},
		error: {
			main: '#d32f2f',
		},
		success: {
			main: '#689f38',
		},
		background: {
			default: '#f5f5f6',
		},
	},
	components: {
		MuiDialogActions: {
			styleOverrides: {
				root: {
					borderTop: `1px solid rgba(0,0,0,0.1)`,
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					borderBottom: '1px solid rgba(0,0,0,0.1)',
					'+.MuiDialogContent-root': {
						paddingTop: '16px !important',
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: 14,
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: 'rgba(0, 0, 0, 0.08)',
					},
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					whiteSpace: 'normal',
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 36,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'initial',
				},
				containedSecondary: {
					'&:hover': {
						backgroundColor: '#fbc02d',
					},
				},
				sizeSmall: {
					textTransform: 'none',
				},
				textSizeSmall: {
					textTransform: 'none',
				},
				outlinedSizeSmall: {
					paddingTop: 1,
					paddingBottom: 1,
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				colorDefault: {
					backgroundColor: '#fff',
				},
				root: {
					'.MuiTab-root': {
						opacity: 0.7,
						color: '#fff',
						minHeight: 46,
						'&.Mui-selected': {
							color: '#fff',
							background: '#115293',
							opacity: 1,
						},
					},
					'.MuiTabs-indicator': {
						backgroundColor: '#fbc02d',
					},
				},
			},
		},
	},
});

export default theme;
