import { GET_USERS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UserFilter, GetUsersResponse, MongooseQuerySort } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getUserRequest = (token: string, filter: UserFilter, sort: MongooseQuerySort) => {
	return axios.get(`${reducerConfig.api}/user/get`, {
		params: { ...filter, ...sort },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getUserAction = (res: GetUsersResponse) => {
	return {
		type: GET_USERS,
		payload: res.data.users,
	};
};

const getUsers = (token: string, filter: UserFilter, sort: MongooseQuerySort): any => {
	return (dispatch: any) => {
		return getUserRequest(token, filter, sort).then((res: AxiosResponse) => {
			dispatch(getUserAction(res));
			return res;
		});
	};
};

export default getUsers;
