import { UPDATE_SCHOOL } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateSchoolActions, UpdateSchool, UpdateSchoolResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const updateSchoolRequest = (action: UpdateSchoolActions, data: UpdateSchool, token: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const uri = `${reducerConfig.api}/school/${action}`;
	if (action.startsWith('add')) {
		return axios.put(uri, data, options);
	} else if (action.startsWith('remove')) {
		return axios.delete(uri, { ...options, data });
	}
	return axios.post(uri, data, options);
};

export const updateSchoolAction = (res: UpdateSchoolResponse) => {
	return {
		type: UPDATE_SCHOOL,
		payload: res.data,
	};
};

const updateSchool = (action: UpdateSchoolActions, data: UpdateSchool, token: string): any => {
	return (dispatch: any) => {
		return updateSchoolRequest(action, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateSchoolAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data;
		});
	};
};

export default updateSchool;
