import { TagDocument } from '../../../types';
import { ADD_TAG, GET_TAGS, TagActionTypes } from './constants';

const initialState: TagDocument[] = [];

const tags = (state = initialState, action: TagActionTypes) => {
	switch (action.type) {
		case GET_TAGS:
			return action.payload;

		case ADD_TAG:
			return [...state, action.payload.newTag];

		default:
			return state;
	}
};

export default tags;
