import Types from '../../../types';
import { SET_BOOK_ACTIVE, ActiveBookActionTypes } from './constants';

const initialState: Types.BookActiveProps = null;

const activeBook = (state = initialState, action: ActiveBookActionTypes) => {
	switch (action.type) {
		case SET_BOOK_ACTIVE:
			return action.payload;

		default:
			return state;
	}
};

export default activeBook;
