import * as Types from '../types';
import moment, { Moment } from 'moment';

export const validateEmail = (email: string) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"/]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const objectGetValue = function (o: any, s: string) {
	const a = s.split('.');
	for (let i = 0, n = a.length; i < n; ++i) {
		const k = a[i];
		if (k in o) {
			o = o[k];
		} else {
			return;
		}
	}
	return o;
};

export const objectUpdatePath = (data: any, path: string, newValue: string) => {
	let k = data;
	const steps = path.split('.');
	const last = steps.pop();
	steps.forEach(e => (k[e] = k[e] || {}) && (k = k[e]));
	if (last) {
		k[last] = newValue;
	}

	return data;
};

export const objectGetkeys = (obj: any, prefix?: string) => {
	const keys = Object.keys(obj);
	prefix = prefix ? prefix + '.' : '';
	return keys.reduce((result: string[], key) => {
		if (typeof obj[key] === 'object') {
			result = result.concat(objectGetkeys(obj[key], prefix + key));
		} else {
			result.push(prefix + key);
		}
		return result;
	}, []);
};

export const uniqueIndex = (pow: number) => {
	const rnd = Math.random();
	return Math.floor(rnd * Math.pow(10, pow))
		.toString(36)
		.toUpperCase();
};

export const uniqueId = (length: number) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
};

export const randomABCD = (length: number) => {
	const r = [];
	const chars = ['A', 'B', 'C', 'D'];
	for (let n = 0; n < length; n++) {
		const rnd = Math.floor(Math.random() * Math.floor(4));
		r.push(chars[rnd]);
	}
	return r.join('');
};

export const codeToId = (code: Types.VotiButtons) => {
	const codes = code.split('') as Types.VotiButtons[];
	let user_id = 0;
	codes.forEach((c: Types.VotiButtons) => {
		if (['A', 'B', 'C', 'D'].indexOf(c) > -1) {
			const n = codeToNum(c);
			user_id *= 16;
			user_id += n;
		}
	});
	return user_id;
};

export const codeToNum = (code: Types.VotiButtons) => {
	switch (code) {
		case 'A':
			return 0;
		case 'B':
			return 1;
		case 'C':
			return 2;
		case 'D':
			return 3;
	}
};

export const createSnackbar = (type: Types.SnackbarTypes, text: string): Types.Snackbar => {
	return {
		type: type,
		message: text,
		shown: false,
	};
};

export const minMaxText = (minMax: Types.MinMaxType) => {
	if (minMax.min && minMax.max && minMax.min === minMax.max) {
		return minMax.min.toString();
	}
	if (minMax.min && !minMax.max) {
		return `min ${minMax.min}`;
	}
	if (minMax.max && !minMax.min) {
		return `max ${minMax.max}`;
	}
	if (minMax.min !== minMax.max) {
		return `${minMax.min} - ${minMax.max}`;
	}
	return undefined;
};

export const secondsToHMS = (seconds?: number) => {
	if (!seconds) return null;
	const date = new Date(seconds * 1000).toISOString().substr(11, 8);
	const hms = date.split(':');
	return {
		hours: parseInt(hms[0]),
		minutes: parseInt(hms[1]),
		seconds: parseInt(hms[2]),
	};
};

export const formatTimeNumber = (value: number): string => {
	if (value > 9) return value.toString();
	return `0${value}`;
};

export const diacriticSensitiveRegex = (value: string) => {
	return value
		.replace(/a/g, '[a,á]')
		.replace(/c/g, '[c,č]')
		.replace(/d/g, '[d,ď]')
		.replace(/e/g, '[e,é,ě]')
		.replace(/i/g, '[i,í]')
		.replace(/n/g, '[n,ň]')
		.replace(/o/g, '[o,ó]')
		.replace(/r/g, '[r,ř]')
		.replace(/s/g, '[s,š]')
		.replace(/t/g, '[t,ť]')
		.replace(/u/g, '[u,ů,ú]')
		.replace(/y/g, '[y,ý]')
		.replace(/z/g, '[z,ž]');
};

export const mapRange = (
	value: number,
	low1: number,
	high1: number,
	low2: number,
	high2: number
) => {
	return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
};

export const getStatsFilterDate = (term: Types.StatsFilterDates) => {
	const dateFrom = moment();
	const dateTo = moment().endOf('day');

	switch (term) {
		case 'thisWeek':
			dateFrom.startOf('week');
			break;
		case 'prevWeek':
			dateFrom.subtract(1, 'week').startOf('week');
			dateTo.subtract(1, 'week').endOf('week');
			break;
		case 'thisMonth':
			dateFrom.startOf('month');
			break;
		case 'prevMonth':
			dateFrom.subtract(1, 'month').startOf('month');
			dateTo.subtract(1, 'month').endOf('month');
			break;
	}

	return {
		from: dateFrom.toDate(),
		to: dateTo.toDate(),
		value: term,
	};
};

export const sortByAlphabet = (items: { title: string }[]) => {
	const newItems = [...items];
	newItems.sort((a, b) => a.title.trim().localeCompare(b.title.trim()));

	const r: { letter: string; items: any[] }[] = [];
	newItems.forEach(item => {
		const currentLetter = Array.from(item.title.trim())[0];
		const letterIndex = r.findIndex(o => o.letter === currentLetter);
		if (letterIndex > -1) {
			r[letterIndex].items.push(item);
		} else {
			r.push({
				letter: currentLetter,
				items: [item],
			});
		}
	});

	return r;
};

export const isNumeric = (value: string) => {
	return /^-?\d+$/.test(value);
};

/* ----------------------------------------
   Terminal logs
---------------------------------------- */
export const Terminal = {
	log: (message: any) => {
		console.log(message);
	},
	warn: (message: any) => {
		console.log(Types.TerminalLogColors.FgRed, message);
	},
	info: (message: any) => {
		console.log(Types.TerminalLogColors.FgBlue, message);
	},
	success: (message: any) => {
		console.log(Types.TerminalLogColors.FgGreen, message);
	},
};

/* ----------------------------------------
   User
---------------------------------------- */
export const getUsername = (user: Types.User) => {
	let username = `${user.firstName} ${user.lastName}`;
	if (username.trim() === '') {
		username = user.email;
	}
	return username;
};

/* ----------------------------------------
   School
---------------------------------------- */
export const getSchoolAddress = (address: Types.SchoolDocument['address']) => {
	return `${address?.street} ${address?.city} ${address?.zip}`;
};

/* ----------------------------------------
   Exam
---------------------------------------- */
export const getExamQuestion = (exam: Types.ExamDocument, questionId: string) => {
	const exercise = exam.exercises.find(e =>
		e.questions.find(q => q._id.toString() === questionId.toString())
	);
	const question = exercise?.questions.find(q => q._id.toString() === questionId.toString());
	return question;
};

export const answerQuiz = (
	question: Types.QuestionDocument,
	answers: Types.UserAnswers,
	answerType: Types.AnswerTypes | undefined,
	answerId: string
) => {
	const qIndex = answers.questions.findIndex(q => q._id.toString() === question._id.toString());
	if (qIndex > -1) {
		const quizAnswer = answers.questions[qIndex].quizAnswer;
		if (answerType && quizAnswer) {
			if (answerType === Types.AnswerTypesList.single) {
				answers.questions[qIndex].quizAnswer = [
					{
						_id: answerId,
					},
				];
			} else if (answerType === Types.AnswerTypesList.multi) {
				const answerIndex = quizAnswer.findIndex(a => a._id.toString() === answerId.toString());
				if (answerIndex > -1) {
					answers.questions[qIndex].quizAnswer?.splice(answerIndex, 1);
				} else {
					answers.questions[qIndex].quizAnswer?.push({
						_id: answerId,
					});
				}
			} else if (answerType === Types.AnswerTypesList.sort) {
				const answerIndex = answers.questions[qIndex].quizAnswer?.findIndex(
					a => a._id.toString() === answerId.toString()
				);
				if (answerIndex) {
					if (answerIndex === -1) {
						answers.questions[qIndex].quizAnswer?.push({
							_id: answerId,
						});
					} else {
						answers.questions[qIndex].quizAnswer?.splice(answerIndex, 1);
					}
				}
			}
			answers.questions[qIndex] = calculateQuestionScore(question, answers.questions[qIndex]);
		}
	} else {
		answers.questions.push(
			calculateQuestionScore(question, {
				_id: question._id.toString(),
				quizAnswer: [
					{
						_id: answerId,
					},
				],
				score: 0,
				state: 'new',
			})
		);
	}
	return answers;
};

export const calculateExamResultsScore = (
	exam: Types.ExamDocument,
	answers: Types.UserAnswers[]
) => {
	let scoreTotal = 0;
	answers.forEach((userAnswers, key) => {
		const newUserAnswers = calculateUserScore(exam, userAnswers);
		scoreTotal = scoreTotal + newUserAnswers.score;
		answers[key] = newUserAnswers;
	});
	return scoreTotal / answers.length;
};

export const calculateRepeatingItemScore = (repeatingItem: Types.RepeatingItemDocument) => {
	let scoreTotal = 0;
	repeatingItem.answers.forEach((userAnswers, key) => {
		const newUserAnswers = calculateUserScore(repeatingItem.exam, userAnswers);
		scoreTotal = scoreTotal + newUserAnswers.score;
		repeatingItem.answers[key] = newUserAnswers;
	});
	repeatingItem.score = scoreTotal / repeatingItem.answers.length;
	return repeatingItem;
};

export const calculateUserScore = (exam: Types.ExamDocument, userAnswers: Types.UserAnswers) => {
	let totalScore = 0;
	let count = 0;
	userAnswers.questions.forEach((questionAnswer, key) => {
		const question = getExamQuestion(exam, questionAnswer._id);
		if (question) {
			const newQuestionAnswer = calculateQuestionScore(question, questionAnswer);
			userAnswers.questions[key] = newQuestionAnswer;
			totalScore = totalScore + newQuestionAnswer.score;
			count = count + 1;
		}
	});
	userAnswers.score = (totalScore / (count > 0 ? count : 1)) * 100;

	return userAnswers;
};

export const calculateQuestionScore = (
	question: Types.QuestionDocument,
	questionAnswer: Types.QuestionAnswer
) => {
	if (question.module === 'quiz' && question.quizData && questionAnswer.quizAnswer) {
		const cim = getQuizCIM(question.quizData.answers, questionAnswer.quizAnswer);
		const correctTotal = question.quizData.answers.filter(a => a.data.isCorrect).length;
		questionAnswer.score = cim.correct / correctTotal / (1 + cim.missing + cim.incorrect);
	}
	return questionAnswer;
};

export const getQuizCIM = (answers: Types.AnswerDocument[], quizAnswer: Types.QuizAnswer[]) => {
	let correct = 0;
	let incorrect = 0;
	let missing = 0;

	answers.forEach(answer => {
		if (quizAnswer) {
			const hasAnswer = quizAnswer?.findIndex(a => a._id.toString() === answer._id.toString()) > -1;
			if (hasAnswer && answer.data.isCorrect) {
				correct = correct + 1;
			} else if (!hasAnswer && answer.data.isCorrect) {
				missing = missing + 1;
			} else if (hasAnswer && !answer.data.isCorrect) {
				incorrect = incorrect + 1;
			}
		}
	});

	return {
		correct,
		missing,
		incorrect,
	};
};

/* ----------------------------------------
   Question
---------------------------------------- */
export const isCorrectAnswer = (
	question: Types.QuestionDocument,
	answer: Types.QuizAnswer[],
	mode?: 'exact'
) => {
	let isCorrect = true;
	const correctAnswers = question.quizData?.answers?.filter(a => a.data.isCorrect);
	answer.forEach(a => {
		const correct = correctAnswers && correctAnswers.findIndex(c => c._id === a._id) > -1;
		if (!correct) isCorrect = false;
	});
	return isCorrect && correctAnswers && correctAnswers.length === answer.length;
};

export const getSuccessRate = (exam: Types.ExamDocument, answers: Types.UserAnswers[]) => {
	let correctAnswers = 0;
	const questionsCount = getQuestionsCount(exam);
	const totalAnswersCount = questionsCount * answers.length;
	exam.exercises.forEach(e =>
		e.questions.forEach(q => {
			answers.forEach(answer => {
				const questionAnswer = answer.questions.find(aq => aq._id === q._id);
				if (questionAnswer?.quizAnswer) {
					if (isCorrectAnswer(q, questionAnswer.quizAnswer)) {
						correctAnswers = correctAnswers + 1;
					}
				}
			});
		})
	);
	return Math.round((correctAnswers / totalAnswersCount) * 100);
};

export const getQuestionsCount = (exam: Types.Exam) => {
	let count = 0;
	exam.exercises.forEach(e => (count = count + e.questions.length));
	return count;
};

/* ----------------------------------------
   Puzzle
---------------------------------------- */
export const puzzleInArea = (
	puzzle: Types.PuzzleDocument,
	size: Types.PuzzleSize,
	position: Types.Cartesian
) => {
	return (
		puzzle.correctPosition.x <= position.x &&
		puzzle.correctPosition.x + (size.width || 4.5) >= position.x &&
		puzzle.correctPosition.y <= position.y &&
		puzzle.correctPosition.y + (size.height || 9) >= position.y
	);
};

/* ----------------------------------------
   Teaching Plan helpers
---------------------------------------- */
export const getNextHour = (classrooms: Types.ClassroomDocument[], teacherId: string) => {
	const currentDate = new Date();
	let timeline: Types.TeachingPlanTimeline | undefined;
	let classroom: Types.ClassroomDocument | undefined;
	let teachingPlan: Types.TeachingPlanDocument | undefined;
	let subjectId: string | undefined;
	classrooms.forEach(c => {
		c.timetables
			.filter(timetable => timetable.teacherId === teacherId)
			.forEach(timetable => {
				timetable.teachingPlan?.timeline.forEach(t => {
					if (moment(t.date).isAfter(currentDate)) {
						if (!timeline && !classroom) {
							timeline = t;
							classroom = c;
							subjectId = timetable.subjectId;
						}
					}
				});
			});
	});
	return {
		subjectId,
		timeline,
		teachingPlan,
		classroom,
	};
};

/* ----------------------------------------
   Arrays
---------------------------------------- */
export const arrayGroupBy = (xs: any[], key: string) => {
	return xs.reduce((rv, x) => {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, []);
};

export const ArrayGroupByMulti = (xs: any[], key1: string, key2: string) => {
	return xs.reduce(function (rv, x) {
		(rv[x[key1] + ',' + x[key2]] = rv[x[key1] + ',' + x[key2]] || []).push(x);
		return rv;
	}, {});
};

export const arrayToObject = (array: any[], key: string, value?: string | string[]) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		if (value) {
			if (typeof value === 'string') {
				return {
					...obj,
					[item[key]]: item[value],
				};
			} else {
				let val = value.map(v => item[v]).join(' ');
				return {
					...obj,
					[item[key]]: val,
				};
			}
		}
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

export const uniqueArray = (array: string[]) => {
	let uniqueArray = array.filter((c, index) => {
		return array.indexOf(c) === index;
	});
	return uniqueArray;
};

export const arrayEnd = (arr: any[]) => {
	return arr.length > 0 ? arr[arr.length - 1] : null;
};

export const arraySwitch = (arr: any[], index1: number, index2: number) => {
	[arr[index1], arr[index2]] = [arr[index2], arr[index1]];
};

export const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
	const element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);
	return arr;
};

/* ----------------------------------------
   Percentile
---------------------------------------- */

export const percentile = (arr: number[], val: number) =>
	(100 * arr.reduce((acc, v) => acc + (v < val ? 1 : 0) + (v === val ? 0.5 : 0), 0)) / arr.length;

/* ----------------------------------------
   TreeData <-> AncestorData
---------------------------------------- */

export const treeToAncestor = (treeData: Types.TreeData[]): Types.AncestorData[] => {
	return findChildrenFromTree(treeData, 'root');
};

const findChildrenFromTree = (treeData: Types.TreeData[], parent: string): Types.AncestorData[] => {
	let ancestorData: Types.AncestorData[] = [];
	treeData.forEach((data, key) => {
		ancestorData.push({
			_id: data._id,
			title: data.title,
			hours: data.hours,
			hasChildren: data.hasChildren,
			segments: data.segments,
			sort: key,
			parent,
		});
		if (data.children) {
			const children = findChildrenFromTree(data.children, data._id);
			ancestorData = [...ancestorData, ...children];
		}
	});

	return ancestorData;
};

export const ancestorToTree = (
	ancestorData: Types.AncestorData[],
	expandedNodes?: string[]
): Types.TreeData[] => {
	return findChildrenFromAncestor(ancestorData, 'root', expandedNodes);
};

const findChildrenFromAncestor = (
	ancestorData: Types.AncestorData[],
	parent: string,
	expandedNodes?: string[]
) => {
	let treeData: Types.TreeData[] = [];
	// find roots
	const roots = ancestorData.filter(a => a.parent === parent);
	roots.forEach(root => {
		let r: Types.TreeData = {
			_id: root._id,
			hasChildren: root.hasChildren,
			hours: root.hours,
			sort: root.sort,
			title: root.title,
			segments: root.segments,
			expanded: expandedNodes && expandedNodes.indexOf(root._id) > -1,
		};
		// find roots children
		const children = findChildrenFromAncestor(ancestorData, root._id.toString(), expandedNodes);
		if (children.length) {
			r.children = children;
		}
		treeData.push(r);
	});

	treeData.sort((a, b) => a.sort - b.sort);

	return treeData;
};

/* ----------------------------------------
   Check user access based on rbac rules
---------------------------------------- */
export const check = ({ rules, user, project, language, perform, data }: Types.CanInterface) => {
	const projectRole = user.projects?.[project]?.[language]?.role;
	const role = user.role;
	const permissions = rules[role];

	if (!permissions) {
		// role is not present in the rules
		return false;
	}

	if (role === Types.RoleTypesList.superadmin) {
		// role superadmin can do everithing
		return true;
	}

	let staticPermissions: Types.RuleType['static'] = [];
	if (role === Types.RoleTypesList.employee || role === Types.RoleTypesList.customer) {
		if (!projectRole) {
			// project role is not defined
			return false;
		}
		staticPermissions = permissions[projectRole].static;
	} else {
		const p = permissions as Types.RuleType;
		staticPermissions = p.static;
	}

	if (staticPermissions && staticPermissions.includes(perform)) {
		// static rule provided for perform
		return true;
	}

	// const dynamicPermissions = permissions.dynamic;

	// if (dynamicPermissions) {
	// 	const permissionCondition = dynamicPermissions[perform];
	// 	if (!permissionCondition) {
	// 		// dynamic rule not provided for perform
	// 		return false;
	// 	}

	// 	return permissionCondition(data);
	// }

	return false;
};

export const allowRoute = (roles: Types.RoleTypes[], role: Types.RoleTypes) => {
	return roles.indexOf(role) > -1;
};

export const projectAccess = (
	user: Types.UserSession,
	project: Types.Projects,
	language: Types.Languages,
	allow: (Types.CustomerRoleTypes | Types.EmployeeRoleTypes)[]
) => {
	const role = user.projects?.[project]?.[language]?.role;
	return allow.indexOf(role) > -1;
};

/* ----------------------------------------
   Calculate chapter hours
---------------------------------------- */
export const recalculateHours = (
	chapters: Types.TeachingPlanChapter[],
	parent: string
): Types.ChapterHours => {
	let hours: Types.ChapterHours = {};
	chapters
		.filter(ch => ch.parent === parent)
		.forEach(chapter => {
			hours[chapter._id] = {
				sum: 0,
				hasChildren: false,
			};
			const children = chapters.filter(ch => ch.parent === chapter._id.toString());
			if (children.length > 0) {
				let sum = 0;
				const childrenHours = recalculateHours(chapters, chapter._id.toString());
				hours = {
					...hours,
					...childrenHours,
				};
				Object.keys(childrenHours).forEach(key => {
					if (!childrenHours[key].hasChildren) {
						sum = sum + childrenHours[key].sum;
					}
				});
				hours[chapter._id].sum = sum;
				hours[chapter._id].hasChildren = true;
			} else {
				hours[chapter._id].sum = chapter.hours;
			}
		});
	return hours;
};

/* ----------------------------------------
   Calculate repeating timeline
---------------------------------------- */
export const isSchoolDay = (date: Moment, term: Types.TermDocument) => {
	const currentDate = date;
	const day = currentDate.day();
	let isWeekend = day === 6 || day === 0;
	let isHoliday = false;
	term.holidays.forEach(holiday => {
		if (
			currentDate.hour(0).isAfter(moment(holiday.start).hour(1)) &&
			currentDate.hour(1).isBefore(moment(holiday.end).hour(0).add(1, 'day'))
		) {
			isHoliday = true;
		}
	});
	return isWeekend ? false : isHoliday ? false : true;
};

export const calculateRepeatingTimeline = (
	repeating: Types.Repeating,
	repeatingItems: Types.RepeatingItem[],
	term: Types.TermDocument,
	repeatingExercises: Types.ExerciseDocument[],
	repeatingQuestions: Types.QuestionDocument[],
	userId: string
) => {
	const settings = repeating.settings;
	const daysKeys = Object.keys(repeating.settings.days) as Types.Days[];
	const dateNow = new Date();
	const timeline = repeatingItems.filter(t => moment(t.date).isBefore(dateNow));
	const noActiveDay =
		!repeating.settings.days.monday.active &&
		!repeating.settings.days.tuesday.active &&
		!repeating.settings.days.wednesday.active &&
		!repeating.settings.days.thursday.active &&
		!repeating.settings.days.friday.active &&
		!repeating.settings.days.saturday.active &&
		!repeating.settings.days.sunday.active;

	const segments: string[] = [];
	repeating.teachingPlan.chapters
		.sort((a, b) => a.sort - b.sort)
		.forEach(chapter => {
			if (chapter.active) {
				chapter.segments.forEach(segment => {
					segments.push(segment.toString());
				});
			}
		});

	if (noActiveDay) {
		return timeline;
	}

	let currentDate = moment(settings.start).hour(1);
	let chapterIndex = 0;
	let questionKey = 0;
	let segmentKey = 0;
	while (segmentKey < segments.length && currentDate.isBefore(term.end)) {
		const currentDay = repeating.settings.days[daysKeys[currentDate.day()]];
		if (currentDay.active && isSchoolDay(currentDate, term)) {
			const hasEvent =
				timeline.filter(t => moment(t.date).hour(1).isSame(currentDate.hour(1), 'day')).length > 0;
			if (hasEvent) {
				currentDate.hour(1).add(1, 'day');
				continue;
			}
			let lastId = '';
			const exercises: Types.ExerciseDocument[] = [];
			const questionsRequired = currentDay.questionsCount * 2;
			let questionsCount = 0;
			while (questionsCount < questionsRequired) {
				const segment = segments[segmentKey];
				const exercise = repeatingExercises.find(e =>
					e.criteria.segments.find(s => s.toString() === segment)
				);
				if (!exercise) {
					break;
				}

				if (!exercises[segmentKey]) {
					exercises[segmentKey] = {
						...exercise,
						questions: [],
					};
				}

				const exerciseQuestions = repeatingQuestions.filter(
					q => q.exerciseId.toString() === exercise._id.toString()
				);
				const question = exerciseQuestions[questionKey];
				if (question) {
					const isNewQuestion =
						timeline.filter(t => {
							let hasQuestion = false;
							t.exam.exercises.forEach(e => {
								if (e.questions.findIndex(q => q._id.toString() === question._id.toString()) > -1) {
									hasQuestion = true;
								}
							});
							return hasQuestion;
						}).length === 0;
					if (isNewQuestion) {
						exercises[segmentKey].questions.push(question);
						questionsCount = questionsCount + 1;
					}
					questionKey = questionKey + 1;
				} else {
					segmentKey = segmentKey + 1;
					questionKey = 0;
				}
				lastId = exercise._id;
			}

			if (exercises.filter(e => e._id && e.questions.length > 0).length > 0) {
				const hours = Math.floor(currentDay.time / 60);
				const minutes = currentDay.time - hours * 60;
				const date = currentDate.clone().hours(hours).minutes(minutes);
				timeline.push({
					date: date.toDate(),
					dateDue: date.add(currentDay.due, 'hours').toDate(),
					exam: {
						_id: lastId,
						title: `Opakování ${chapterIndex + 1}. část`,
						description: '',
						id: 0,
						exercises: exercises.filter(e => e._id && e.questions.length > 0),
						criteria: {
							subject: repeating.teachingPlan.subject || '',
							schoolType: repeating.teachingPlan.schoolType || 'zs',
							grade: repeating.teachingPlan.grade || 1,
						},
						state: 'generated',
						createdBy: userId,
						createdDate: new Date(),
					},
					answers: repeating.students.map(student => ({
						_id: student,
						keyId: 0,
						questions: [],
						state: 'new',
						score: 0,
					})),
					hour: 0,
					key: '',
				});
			}
			chapterIndex = chapterIndex + 1;
		}
		currentDate.hour(1).add(1, 'day');
	}

	return timeline;
};

/* ----------------------------------------
   Calculate term timeline
---------------------------------------- */
export const recalculateTimeline = (date: Types.TermDocument) => {
	const timeline: Types.Timeline[] = [];
	let currentDate = moment(date.start);
	let i = 0;
	while (currentDate.isBefore(moment(date.end).add(1, 'day'))) {
		const day = currentDate.day();
		let isWeekend = day === 6 || day === 0;
		let isHoliday = false;
		date.holidays.forEach(holiday => {
			if (
				currentDate.isAfter(moment(holiday.start)) &&
				currentDate.isBefore(moment(holiday.end).add(1, 'day'))
			) {
				isHoliday = true;
			}
		});
		timeline.push({
			date: currentDate.toDate(),
			day: currentDate.day(),
			isHoliday,
			isWeekend,
		});
		currentDate.add(1, 'day');
		i = i + 1;
	}
	return timeline;
};

export const getTimelineEvents = (teachingPlan: Types.ClassroomTeachingPlan | undefined) => {
	const events: Types.TimelineEvent[] = [];
	teachingPlan?.timeline.forEach(t => {
		const chapter = teachingPlan.chapters.find(ch => ch._id === t.chapterId);
		if (chapter) {
			let key = t.chapterId;
			const eventChapter = { ...chapter };
			eventChapter.part = t.part;
			events.push({
				key,
				date: t.date,
				chapter: eventChapter,
				hour: t.hour,
				exam: t.exams[0],
				answers: [],
			});
		}
	});
	return events;
};

export const generateExamsBySegments = (segments: string[], exams: Types.ExamDocument[]) => {
	let timelineExams: Types.ExamDocument[] = [];
	exams.forEach(ex => {
		const exercises: Types.ExerciseDocument[] = ex.exercises.filter(e => {
			let hasSegment = false;
			const criteriaSegments = e.criteria.segments;
			segments.forEach(segment => {
				if (
					criteriaSegments &&
					criteriaSegments.map(cs => cs.toString()).indexOf(segment.toString()) > -1
				) {
					hasSegment = true;
				}
			});
			return hasSegment;
		});
		if (exercises.length > 0) {
			const exam = {
				_id: ex._id,
				id: ex.id,
				title: ex.title,
				description: ex.description,
				upload: ex.upload,
				criteria: ex.criteria,
				state: ex.state,
				createdBy: ex.createdBy,
				createdDate: ex.createdDate,
				exercises,
			};
			timelineExams.push(exam);
		}
	});
	return timelineExams;
};

export const generateExamsByChapters = (
	chapters: Types.TeachingPlanChapter[],
	exams: Types.ExamDocument[]
) => {
	let timelineExams: { [key: string]: Types.ExamDocument } = {};
	exams.forEach(ex => {
		ex.exercises.forEach(exercise => {
			const criteriaSegments = exercise.criteria.segments;
			chapters.forEach(chapter => {
				chapter.segments.forEach(segment => {
					if (criteriaSegments && criteriaSegments.indexOf(segment) > -1) {
						if (timelineExams[chapter._id]) {
							timelineExams[chapter._id].exercises.push(exercise);
						} else {
							const newExam = {
								_id: ex._id,
								id: ex.id,
								title: chapter.title,
								description: ex.description,
								upload: ex.upload,
								criteria: ex.criteria,
								state: ex.state,
								createdBy: ex.createdBy,
								createdDate: ex.createdDate,
								exercises: [exercise],
							};
							timelineExams[chapter._id] = newExam;
						}
					}
				});
			});
		});
	});
	return timelineExams;
};

type TreeOrderData = { _id: string; hours: number };
export const getTreeOrder = (treeData: Types.TreeData[]): TreeOrderData[] => {
	let order: TreeOrderData[] = [];

	treeData.forEach(data => {
		if (data.children && data.children.length > 0) {
			const childOrder = getTreeOrder(data.children);
			order = order.concat(childOrder);
		} else {
			order.push({
				_id: data._id,
				hours: data.hours,
			});
		}
	});

	return order;
};

export const recalculateTeachingPlanTimeline = (
	term: Types.TermDocument,
	chapters: Types.AncestorData[],
	timetable: Types.ClassroomTimetableDocument,
	exams: Types.ExamDocument[],
	evenOddTimetable: boolean
): Types.TeachingPlanTimeline[] => {
	const teachingPlanTimeline: Types.TeachingPlanTimeline[] = [];
	const treeData = ancestorToTree(chapters);
	const treeOrder = getTreeOrder(treeData);
	const timeline = term.timeline.filter(d => !d.isHoliday && !d.isWeekend);
	const timetableHoursGrouped = ArrayGroupByMulti(timetable.hours, 'day', 'week');
	let lastDate = moment(term.start);
	let rest = 0;

	treeOrder.forEach(ch => {
		let hasLastDate = false;
		let hoursTotal = ch.hours;
		let hoursCounter = rest;
		let part = 1;
		timeline.forEach(d => {
			if (moment(d.date).isBefore(lastDate)) return;

			Object.keys(timetableHoursGrouped).forEach(key => {
				timetableHoursGrouped[key].forEach((h: Types.TimetableHour) => {
					let condition = h.day === d.day;
					if (evenOddTimetable) {
						condition = h.day === d.day && h.week === (moment(d.date).isoWeek() % 2 ? 'odd' : 'even');
					}

					if (condition) {
						if (hoursTotal === hoursCounter) {
							if (!hasLastDate) {
								lastDate = moment(d.date);
								hasLastDate = true;
							}
						} else {
							let timelineExams = generateExamsBySegments([], exams);
							teachingPlanTimeline.push({
								date: d.date,
								chapterId: ch._id,
								part,
								hour: h.hour,
								exams: timelineExams,
							});

							if (hoursCounter + 1 > hoursTotal) {
								rest = hoursTotal - (hoursCounter + 1);
								lastDate = moment(d.date);
								hoursCounter = hoursTotal;
								hasLastDate = true;
							} else {
								hoursCounter = hoursCounter + 1;
								rest = 0;
							}

							part = part + 1;
						}
					}
				});
			});
		});
	});

	return teachingPlanTimeline;
};

export const minutesToTime = (value: number) => {
	const hours = Math.floor(value / 60);
	const minutes = value - hours * 60;
	return `${hours}:${minutes > 9 ? minutes : `0${minutes}`}`;
};
