import { GET_SEGMENTS_STATS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import fetchQuestions from '../../questions/actions/fetch';
import { SegmentsStats, Stats, StatsFilter } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getSegmentsStatsRequest = (token: string, filter: StatsFilter) => {
	return axios.get(`${reducerConfig.api}/stats/get/segments`, {
		params: {
			...filter,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getSegmentsStatsAction = (stats: Stats<SegmentsStats>[]) => {
	return {
		type: GET_SEGMENTS_STATS,
		payload: stats,
	};
};

const getSegmentsStats = (token: string, filter: StatsFilter): any => {
	return (dispatch: any) => {
		return getSegmentsStatsRequest(token, filter).then((res: AxiosResponse) => {
			dispatch(getSegmentsStatsAction(res.data.stats));
			if (res.data.questions) {
				dispatch(fetchQuestions(res.data.questions));
			}
			return res.data;
		});
	};
};

export default getSegmentsStats;
