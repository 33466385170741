import { ADD_BOOK_CATEGORY } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { BookCategory } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const addBookCategoryRequest = (book: BookCategory, token: string) => {
	return axios.put(`${reducerConfig.api}/book-category/add`, book, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const addBookCategoryAction = (res: AxiosResponse) => {
	return {
		type: ADD_BOOK_CATEGORY,
		payload: res.data.newBookCategory,
	};
};

const addBookCategory = (book: BookCategory, token: string): any => {
	return (dispatch: any) => {
		return addBookCategoryRequest(book, token).then((res: AxiosResponse) => {
			dispatch(addBookCategoryAction(res));
			return res;
		});
	};
};

export default addBookCategory;
