import { SchoolDocument, ExamDocument } from '../../../../types';

export const ADD_SCHOOL = 'ADD_SCHOOL';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const REMOVE_SCHOOL = 'REMOVE_SCHOOL';
export const GET_SCHOOLS = 'GET_SCHOOLS';

export interface AddSchoolAction {
	type: typeof ADD_SCHOOL;
	payload: {
		newSchool: SchoolDocument;
	};
}

export interface RemoveSchoolAction {
	type: typeof REMOVE_SCHOOL;
	payload: {
		schoolId: string;
		token: string;
	};
}

export interface UpdateSchoolAction {
	type: typeof UPDATE_SCHOOL;
	payload: {
		schoolId: string;
		newSchool: SchoolDocument;
		token: string;
	};
}

export interface GetSchoolsAction {
	type: typeof GET_SCHOOLS;
	payload: {
		newExam: ExamDocument[];
	};
}

export type SchoolActionTypes =
	| AddSchoolAction
	| RemoveSchoolAction
	| UpdateSchoolAction
	| GetSchoolsAction;
