import { UPDATE_QUESTION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateQuestionActions, UpdateQuestion, UpdateExamResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const updateQuestionRequest = (
	action: UpdateQuestionActions,
	data: UpdateQuestion,
	token: string
) => {
	const uri = `${reducerConfig.api}/question/${action}`;
	const options = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	if (action.startsWith('add')) {
		return axios.put(uri, data, options);
	} else if (action.startsWith('remove')) {
		return axios.delete(uri, { ...options, data });
	}
	return axios.post(uri, data, options);
};

const updateQuestionAction = (res: UpdateExamResponse) => {
	return {
		type: UPDATE_QUESTION,
		payload: res.data,
	};
};

const updateQuestion = (
	action: UpdateQuestionActions,
	data: UpdateQuestion,
	token: string
): any => {
	return (dispatch: any) => {
		return updateQuestionRequest(action, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateQuestionAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data;
		});
	};
};

export default updateQuestion;
