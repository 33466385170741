import { ADD_QUESTION_TYPE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { QuestionTypes, AddQuestionTypeResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addQuestionTypeRequest = (newQuestionType: QuestionTypes, token: string) => {
	return axios.put(
		`${reducerConfig.api}/question-type/add`,
		{
			newQuestionType,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const addQuestionTypeAction = (res: AddQuestionTypeResponse) => {
	return {
		type: ADD_QUESTION_TYPE,
		payload: res,
	};
};

const addQuestionType = (newQuestionType: QuestionTypes, token: string): any => {
	return (dispatch: any) => {
		return addQuestionTypeRequest(newQuestionType, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addQuestionTypeAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newJob;
		});
	};
};

export default addQuestionType;
