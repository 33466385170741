import { TeacherHistory } from '../../../../types';

export const GET_HISTORY = 'GET_HISTORY';

export interface GetHistoryAction {
	type: typeof GET_HISTORY;
	payload: {
		success: boolean;
		newHistory: TeacherHistory[];
	};
}

export type DeviceActionTypes = GetHistoryAction;
