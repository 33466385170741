import { QuestionDocument } from '../../../../types';

export const ADD_QUESTION = 'ADD_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';

export interface AddQuestionAction {
	type: typeof ADD_QUESTION;
	payload: {
		newQuestions: QuestionDocument[];
	};
}

export interface RemoveQuestionAction {
	type: typeof REMOVE_QUESTION;
	payload: {
		questionId: string;
		token: string;
	};
}

export interface UpdateQuestionAction {
	type: typeof UPDATE_QUESTION;
	payload: {
		questionId: string;
		newQuestion: QuestionDocument;
		token: string;
	};
}

export interface GetQuestionsAction {
	type: typeof GET_QUESTIONS;
	payload: {
		newQuestion: QuestionDocument[];
	};
}

export interface FetchQuestionsAction {
	type: typeof FETCH_QUESTIONS;
	payload: QuestionDocument[];
}

export type QuestionActionTypes =
	| AddQuestionAction
	| RemoveQuestionAction
	| UpdateQuestionAction
	| GetQuestionsAction
	| FetchQuestionsAction;
