import { GET_STUDENTS_STATS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import fetchQuestions from '../../questions/actions/fetch';
import { Stats, StatsFilter, StudentsStats } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getStudentsStatsRequest = (token: string, filter: StatsFilter) => {
	return axios.get(`${reducerConfig.api}/stats/get/students`, {
		params: {
			...filter,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getStudentsStatsAction = (stats: Stats<StudentsStats>[]) => {
	return {
		type: GET_STUDENTS_STATS,
		payload: stats,
	};
};

const getStudentsStats = (token: string, filter: StatsFilter): any => {
	return (dispatch: any) => {
		return getStudentsStatsRequest(token, filter).then((res: AxiosResponse) => {
			dispatch(getStudentsStatsAction(res.data.stats));
			if (res.data.questions) {
				dispatch(fetchQuestions(res.data.questions));
			}
			return res.data;
		});
	};
};

export default getStudentsStats;
