import get from './get';
import add from './add';
import addDependency from './addDependency';
import addTag from './addTag';
import removeDependency from './removeDependency';
import removeTag from './removeTag';
import updateGrade from './updateGrade';
import updateRequiredCount from './updateRequiredCount';
import updateSubject from './updateSubject';
import updateTitle from './updateTitle';
import remove from './remove';

export default {
	get,
	add,
	addDependency,
	addTag,
	removeDependency,
	removeTag,
	updateGrade,
	updateRequiredCount,
	updateSubject,
	updateTitle,
	remove,
};
