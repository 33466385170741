import axios from 'axios';
import reducerConfig from '../../reducerConfig';

const verifyEmail = (classroomId: string, email: string) => {
	return axios.post(`${reducerConfig.api}/user/verify-email`, {
		classroomId,
		email,
	});
};

export default verifyEmail;
