import { UsbDevice } from '../../../types';
import { GET_USB_DEVICES, FETCH_USB_DEVICE_INFO, UsbDevicesActionTypes } from './constants';

const initialState: UsbDevice[] = [];

const usbDevices = (state = initialState, action: UsbDevicesActionTypes) => {
	switch (action.type) {
		case GET_USB_DEVICES:
			return action.payload;

		case FETCH_USB_DEVICE_INFO:
			return state.map(device => {
				if (device.serial === action.payload.serialNumber) {
					return {
						...device,
						info: action.payload,
					};
				}
				return device;
			});

		default:
			return state;
	}
};

export default usbDevices;
