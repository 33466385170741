import { ADD_USER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { NewUserProps, AddUserResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addUserRequest = (newUser: NewUserProps, token: string) => {
	return axios.put(`${reducerConfig.api}/user/add-user`, newUser, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addUserAction = (res: AddUserResponse) => {
	return {
		type: ADD_USER,
		payload: res.data,
	};
};

const addUser = (newUser: NewUserProps, token: string): any => {
	return (dispatch: any) => {
		return addUserRequest(newUser, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addUserAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newUser;
		});
	};
};

export default addUser;
