import { UPDATE_DEVICE_ANSWERS } from '../constants';
import { KeyCodes } from '../../../../types';

export interface updateDeviceAnswerProps {
	userId: string;
	keyCode: KeyCodes;
}

const UpdateDeviceAnswer = (props: updateDeviceAnswerProps): any => {
	return {
		type: UPDATE_DEVICE_ANSWERS,
		payload: {
			userId: props.userId,
			keyCode: props.keyCode,
		},
	};
};

export default UpdateDeviceAnswer;
