import { SubjectDocument } from '../../../types';
import { ADD_SUBJECT, EDIT_SUBJECT, GET_SUBJECTS, SubjectActionTypes } from './constants';

const initialState: SubjectDocument[] = [];

const subjects = (state = initialState, action: SubjectActionTypes) => {
	switch (action.type) {
		case GET_SUBJECTS:
			return action.payload;

		case ADD_SUBJECT:
			return [...state, action.payload.data.newSubject];

		case EDIT_SUBJECT:
			return state.map(subject => {
				if (subject._id === action.payload.data.subjectId) {
					return action.payload.data.newSubject;
				}
				return subject;
			});

		default:
			return state;
	}
};

export default subjects;
