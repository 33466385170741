import { RepeatingItemDocument } from '../../../types';
import {
	GET_REPEATING_ITEMS,
	ADD_REPEATING_ITEMS,
	UPDATE_REPEATING_ITEMS,
	RepeatingActionTypes,
} from './constants';

const initialState: RepeatingItemDocument[] = [];

const repeatingItems = (state = initialState, action: RepeatingActionTypes) => {
	switch (action.type) {
		case GET_REPEATING_ITEMS:
			return action.payload;

		case ADD_REPEATING_ITEMS:
			return [...state, ...action.payload];

		case UPDATE_REPEATING_ITEMS:
			return state.map(item => {
				const newItem = action.payload.find(p => p._id === item._id);
				if (newItem) {
					return newItem;
				}
				return item;
			});
		default:
			return state;
	}
};

export default repeatingItems;
