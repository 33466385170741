import { FETCH_REPEATINGS } from '../constants';
import { RepeatingDocument } from '../../../../types';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const fetchRepeatingRequest = (token: string, ids?: string[]) => {
	return axios.get(`${reducerConfig.api}/repeating/get`, {
		params: { ids },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const fetchRepeatingAction = (repeatings: RepeatingDocument[]): any => {
	return {
		type: FETCH_REPEATINGS,
		payload: repeatings,
	};
};

const fetchRepeating = (token: string, ids?: string[]): any => {
	return (dispatch: any) => {
		return fetchRepeatingRequest(token, ids).then((res: AxiosResponse) => {
			dispatch(fetchRepeatingAction(res.data));
			return res.data;
		});
	};
};

export default fetchRepeating;
