import { TranslationPath } from '../../../types';
import { SET_TRANSLATION_EDIT, TranslationEditActionTypes } from './constants';

const initialState: TranslationPath | null = null;

const translationEdit = (state = initialState, action: TranslationEditActionTypes) => {
	switch (action.type) {
		case SET_TRANSLATION_EDIT:
			return action.payload;

		default:
			return state;
	}
};

export default translationEdit;
