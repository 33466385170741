import { ADD_EXAM } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { AddExamResponse, AddExam } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addExamRequest = (data: AddExam, token: string) => {
	return axios.put(`${reducerConfig.api}/exam/add`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addExamAction = (res: AddExamResponse) => {
	return {
		type: ADD_EXAM,
		payload: res.data,
	};
};

const addExam = (data: AddExam, token: string): any => {
	return (dispatch: any) => {
		return addExamRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addExamAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newExam;
		});
	};
};

export default addExam;
