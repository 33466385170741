import { GET_QUESTIONS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { QuestionFilter, QuestionSort, QuestionDocument } from '../../../../types';
import { getRevisionItemsAction } from '../../revisionItems/actions/get';
import fetchQuestions from './fetch';
import fetchExercises from '../../exercises/actions/fetch';
import reducerConfig from '../../reducerConfig';

const getQuestionsRequest = (token: string, filter: QuestionFilter, sort: QuestionSort) => {
	return axios.get(`${reducerConfig.api}/question/get`, {
		params: { ...filter, ...sort },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getQuestionsAction = (questions: QuestionDocument[]) => {
	return {
		type: GET_QUESTIONS,
		payload: questions,
	};
};

const getQuestions = (
	token: string,
	filter: QuestionFilter,
	sort: QuestionSort,
	fetch?: boolean
): any => {
	return (dispatch: any) => {
		return getQuestionsRequest(token, filter, sort).then((res: AxiosResponse) => {
			if (res.data.revisionItems) {
				dispatch(
					getRevisionItemsAction({
						...res,
						data: res.data.revisionItems,
					})
				);
			}
			if (res.data.exercises) {
				dispatch(fetchExercises(res.data.exercises));
			}
			if (fetch) {
				dispatch(fetchQuestions(res.data.questions));
			} else {
				dispatch(getQuestionsAction(res.data.questions));
			}
			return res;
		});
	};
};

export default getQuestions;
