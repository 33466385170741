import { SegmentDocument } from '../../../types';
import {
	ADD_SEGMENT,
	GET_SEGMENTS,
	UPDATE_SEGMENT,
	UPDATE_SEGMENT_SORT,
	REMOVE_SEGMENT,
	SegmentActionTypes,
} from './constants';

const initialState: SegmentDocument[] = [];

const segments = (state = initialState, action: SegmentActionTypes) => {
	switch (action.type) {
		case GET_SEGMENTS:
			return action.payload;

		case ADD_SEGMENT:
			return [...state, action.payload.newSegment];

		case UPDATE_SEGMENT:
			return state.map(segment => {
				if (action.payload._id === segment._id) {
					return action.payload;
				}
				return segment;
			});

		case UPDATE_SEGMENT_SORT:
			return action.payload;

		case REMOVE_SEGMENT:
			return state.filter(segment => segment._id !== action.payload.segmentId);

		default:
			return state;
	}
};

export default segments;
