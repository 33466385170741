import { ExamDocument } from '../../../types';
import {
	ADD_SCHOOL,
	REMOVE_SCHOOL,
	UPDATE_SCHOOL,
	GET_SCHOOLS,
	SchoolActionTypes,
} from './constants';

const initialState: ExamDocument[] = [];

const exams = (state = initialState, action: SchoolActionTypes) => {
	switch (action.type) {
		case GET_SCHOOLS:
			return action.payload;

		case ADD_SCHOOL:
			return [...state, action.payload.newSchool];

		case REMOVE_SCHOOL:
			return state.filter(school => school._id !== action.payload.schoolId);

		case UPDATE_SCHOOL:
			return state.map(school => {
				if (school._id === action.payload.newSchool._id) {
					return {
						...school,
						...action.payload.newSchool,
					};
				}
				return school;
			});

		default:
			return state;
	}
};

export default exams;
