import { EDIT_USER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { User, EditUserResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';
import setUserSessionAction from '../../user/actions/set';

const editUserRequest = (userId: string, newUser: User, token: string) => {
	return axios.post(
		`${reducerConfig.api}/user/edit`,
		{
			userId: userId,
			newUser: newUser,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const editUserAction = (res: EditUserResponse) => {
	return {
		type: EDIT_USER,
		payload: res.data,
	};
};

const editUser = (userId: string, newUser: User, token: string): any => {
	return (dispatch: any) => {
		return editUserRequest(userId, newUser, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(editUserAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newUser) {
				dispatch(setUserSessionAction(res.data.newUser));
			}
			return res.data.success;
		});
	};
};

export default editUser;
