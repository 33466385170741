import {
	AddQuestionTypeResponse,
	EditQuestionTypeResponse,
	GetQuestionTypesResponse,
	RemoveQuestionTypeResponse,
} from '../../../../types';

export const ADD_QUESTION_TYPE = 'ADD_QUESTION_TYPE';
export const REMOVE_QUESTION_TYPE = 'REMOVE_QUESTION_TYPE';
export const EDIT_QUESTION_TYPE = 'EDIT_QUESTION_TYPE';
export const GET_QUESTION_TYPES = 'GET_QUESTION_TYPES';

export interface AddQuestionTypeAction {
	type: typeof ADD_QUESTION_TYPE;
	payload: AddQuestionTypeResponse;
}

export interface RemoveQuestionTypeAction {
	type: typeof REMOVE_QUESTION_TYPE;
	payload: RemoveQuestionTypeResponse;
}

export interface EditQuestionTypeAction {
	type: typeof EDIT_QUESTION_TYPE;
	payload: EditQuestionTypeResponse;
}

export interface GetQuestionTypesAction {
	type: typeof GET_QUESTION_TYPES;
	payload: GetQuestionTypesResponse;
}

export type QuestionTypeActionTypes =
	| AddQuestionTypeAction
	| RemoveQuestionTypeAction
	| EditQuestionTypeAction
	| GetQuestionTypesAction;
