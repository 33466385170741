import connect from './connect';
import disconnect from './disconnect';
import update from './update';
import updateAnswer from './updateAnswer';
import updateQuestion from './updateQuestion';
import updateState from './updateState';

export default {
	connect,
	disconnect,
	update,
	updateAnswer,
	updateQuestion,
	updateState,
};
