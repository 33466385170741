import { UserSession } from '../../../../types';
import { SET_USER_SESSION } from '../constants';

export const setUserSessionAction = (user: UserSession) => {
	return {
		type: SET_USER_SESSION,
		payload: user,
	};
};

export default setUserSessionAction;
