import { ADD_REVISION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { AddRevision } from '../../../../types';
import fetchQuestions from '../../questions/actions/fetch';
import reducerConfig from '../../reducerConfig';

const addRevisionRequest = (revision: AddRevision, token: string) => {
	return axios.put(`${reducerConfig.api}/revision/add`, revision, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addRevisionAction = (res: AxiosResponse) => {
	return {
		type: ADD_REVISION,
		payload: res.data,
	};
};

const addRevision = (revision: AddRevision, token: string): any => {
	return (dispatch: any) => {
		return addRevisionRequest(revision, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addRevisionAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newQuestions) {
				dispatch(fetchQuestions(res.data.newQuestions));
			}
			return res.data.newHomewrork;
		});
	};
};

export default addRevision;
