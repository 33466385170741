import Types, { CanInterface } from '@taktik/common/types';
import { check } from '@taktik/common/helpers';
import rules from '@taktik/common/rules';
import { connect } from 'react-redux';

const Can = (
	props: Pick<CanInterface, 'user' | 'perform' | 'yes' | 'project' | 'language' | 'no'>
) =>
	check({
		rules,
		user: props.user,
		project: props.project,
		language: props.language,
		perform: props.perform,
	})
		? props.yes
			? props.yes()
			: null
		: props.no
		? props.no()
		: null;

Can.defaultProps = {
	yes: () => null,
	no: () => null,
};

export default connect((state: Types.RootState) => ({
	user: state.user,
}))(Can);
