import { UPDATE_REPEATING } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { UpdateRepeating } from '../../../../types';
import updateRepeatingItems from '../../repeatingItems/actions/update';
import reducerConfig from '../../reducerConfig';

const updateRepeatingRequest = (update: UpdateRepeating, token: string) => {
	return axios.post(`${reducerConfig.api}/repeating/update`, update, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const updateRepeatingAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_REPEATING,
		payload: res.data,
	};
};

const updateRepeating = (update: UpdateRepeating, token: string): any => {
	return (dispatch: any) => {
		return updateRepeatingRequest(update, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateRepeatingAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newItems) {
				dispatch(updateRepeatingItems(res.data.newItems));
			}
			return res.data;
		});
	};
};

export default updateRepeating;
