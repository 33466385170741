import axios from 'axios';
import reducerConfig from '../../reducerConfig';

const getById = (classroomId: string) => {
	return axios.get(`${reducerConfig.api}/classroom/get-by-id`, {
		params: {
			classroomId,
		},
	});
};

export default getById;
