import { ADD_SEGMENT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { Segment } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const addSegmentRequest = (newSegment: Segment, token: string) => {
	return axios.put(`${reducerConfig.api}/segment/add`, newSegment, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addSegmentAction = (res: AxiosResponse) => {
	return {
		type: ADD_SEGMENT,
		payload: res.data,
	};
};

const addSegment = (newSegment: Segment, token: string): any => {
	return (dispatch: any) => {
		return addSegmentRequest(newSegment, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addSegmentAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newSegment;
		});
	};
};

export default addSegment;
