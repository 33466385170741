import { UPDATE_SEGMENT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addSegmentTagRequest = (segmentId: string, tagId: string, token: string) => {
	return axios.post(
		`${reducerConfig.api}/segment/remove/tag`,
		{
			segmentId,
			data: {
				tagId,
			},
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const addSegmentTagAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_SEGMENT,
		payload: res.data.newSegment,
	};
};

const addSegmentTag = (segmentId: string, tagId: string, token: string): any => {
	return (dispatch: any) => {
		return addSegmentTagRequest(segmentId, tagId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addSegmentTagAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newSegment;
		});
	};
};

export default addSegmentTag;
