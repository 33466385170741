import { UPDATE_EMAIL_TEMPLATE } from '../constants';
import axios from 'axios';
import { EmailTemplateUpdate, GetTagsResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const updateEmailTemplatesRequest = (emailTemplate: EmailTemplateUpdate, token: string) => {
	return axios.post(
		`${reducerConfig.api}/email-template/update/${emailTemplate._id}`,
		emailTemplate,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateEmailTemplatesAction = (res: GetTagsResponse) => {
	return {
		type: UPDATE_EMAIL_TEMPLATE,
		payload: res.data,
	};
};

const updateEmailTemplates = (emailTemplate: EmailTemplateUpdate, token: string): any => {
	return (dispatch: any) => {
		return updateEmailTemplatesRequest(emailTemplate, token).then((res: GetTagsResponse) => {
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			dispatch(updateEmailTemplatesAction(res));
			return res.data;
		});
	};
};

export default updateEmailTemplates;
