import { UPDATE_REPEATING_ITEMS } from '../constants';
import { RepeatingItemDocument } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateRepeatingItems = (repeatingItems: RepeatingItemDocument[]): any => {
	return {
		type: UPDATE_REPEATING_ITEMS,
		payload: repeatingItems,
	};
};

export default updateRepeatingItems;
