import { UPDATE_MIGRATION } from '../constants';
import { MigrationTable } from '../../../../types';

const updateMigration = (migration: MigrationTable) => {
	return {
		type: UPDATE_MIGRATION,
		payload: migration,
	};
};

export default updateMigration;
