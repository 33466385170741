import { UPDATE_HOMEWORK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { HomeworkStates } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateHomeworkRequest = (homeworkId: string, state: HomeworkStates, token: string) => {
	return axios.post(
		`${reducerConfig.api}/homework/state`,
		{
			homeworkId,
			state,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateHomeworkAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_HOMEWORK,
		payload: res.data.newHomework,
	};
};

const updateHomework = (homeworkId: string, state: HomeworkStates, token: string): any => {
	return (dispatch: any) => {
		return updateHomeworkRequest(homeworkId, state, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateHomeworkAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newHomewrork;
		});
	};
};

export default updateHomework;
