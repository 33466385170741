import { QuestionsStats, SegmentsStats, Stats, StudentsStats } from '../../../../types';

export const GET_QUESTIONS_STATS = 'GET_QUESTIONS_STATS';
export const GET_STUDENTS_STATS = 'GET_STUDENTS_STATS';
export const GET_SEGMENTS_STATS = 'GET_SEGMENTS_STATS';
export const GET_STATS_OVERVIEW = 'GET_STATS_OVERVIEW';

export interface GetQuestionsStatsAction {
	type: typeof GET_QUESTIONS_STATS;
	payload: Stats<QuestionsStats>[];
}

export interface GetStudentsStatsAction {
	type: typeof GET_STUDENTS_STATS;
	payload: Stats<StudentsStats>[];
}

export interface GetQuestionStatsAction {
	type: typeof GET_SEGMENTS_STATS;
	payload: Stats<SegmentsStats>[];
}

export interface GetStatsOverviewAction {
	type: typeof GET_STATS_OVERVIEW;
	payload: Stats<SegmentsStats | SegmentsStats | QuestionsStats>[];
}

export type StatsActionTypes =
	| GetQuestionsStatsAction
	| GetStudentsStatsAction
	| GetQuestionStatsAction
	| GetStatsOverviewAction;
