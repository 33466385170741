import { UPDATE_BOOK_CATEGORY } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const removeBookCategoryBookRequest = (categoryId: string, bookId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/book-category/remove-book`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			categoryId,
			bookId,
		},
	});
};

const removeBookCategoryBookAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_BOOK_CATEGORY,
		payload: res.data,
	};
};

const removeBookCategoryBook = (categoryId: string, bookId: string, token: string): any => {
	return (dispatch: any) => {
		return removeBookCategoryBookRequest(categoryId, bookId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeBookCategoryBookAction(res));
			}
		});
	};
};

export default removeBookCategoryBook;
