import { SET_TRANSLATION_EDIT } from '../constants';
import { TranslationPath } from '../../../../types';

const setTranslationEditAction = (path?: TranslationPath) => {
	return {
		type: SET_TRANSLATION_EDIT,
		payload: path || null,
	};
};

export default setTranslationEditAction;
