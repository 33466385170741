import { GET_EXAM_RESULTS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetExamResult, GetExamResultResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const saveExamResultRequest = (params: GetExamResult, token: string) => {
	return axios.get(`${reducerConfig.api}/exam-result/get`, {
		params,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const saveExamResultAction = (res: GetExamResultResponse) => {
	return {
		type: GET_EXAM_RESULTS,
		payload: res.data.examResults,
	};
};

const saveExamResult = (params: GetExamResult, token: string): any => {
	return (dispatch: any) => {
		return saveExamResultRequest(params, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(saveExamResultAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data;
		});
	};
};

export default saveExamResult;
