import { UPDATE_BOOK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';
import { BookChapterDocument } from '../../../../types';

export interface UpdateBookChapter {
	bookId: string;
	data: BookChapterDocument;
}

const removeBookChapterRequest = (data: UpdateBookChapter, token: string) => {
	return axios.delete(`${reducerConfig.api}/book/remove/chapter`, {
		data,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const removeBookChapterAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_BOOK,
		payload: res.data,
	};
};

const removeBookChapter = (data: UpdateBookChapter, token: string): any => {
	return (dispatch: any) => {
		return removeBookChapterRequest(data, token).then((res: AxiosResponse) =>
			dispatch(removeBookChapterAction(res))
		);
	};
};

export default removeBookChapter;
