import { QuestionDocument } from '../../../types';
import {
	ADD_QUESTION,
	REMOVE_QUESTION,
	UPDATE_QUESTION,
	GET_QUESTIONS,
	FETCH_QUESTIONS,
	QuestionActionTypes,
} from './constants';

const initialState: QuestionDocument[] = [];

const questions = (state = initialState, action: QuestionActionTypes) => {
	switch (action.type) {
		case GET_QUESTIONS:
			return action.payload;

		case FETCH_QUESTIONS:
			const newState: QuestionDocument[] = [];
			// update existing
			state.forEach(question => {
				const newQuestion = action.payload.find(q => q._id === question._id);
				if (newQuestion) {
					newState.push(newQuestion);
				} else {
					newState.push(question);
				}
			});
			// add new
			action.payload
				.filter(q => newState.findIndex(s => s._id === q._id) === -1)
				.forEach(question => {
					newState.push(question);
				});
			return newState;

		case ADD_QUESTION:
			return [...state, ...action.payload.newQuestions];

		case REMOVE_QUESTION:
			return state.filter(question => question._id !== action.payload.questionId);

		case UPDATE_QUESTION:
			return state.map(question => {
				if (question._id === action.payload.newQuestion._id) {
					return action.payload.newQuestion;
				}
				return question;
			});

		default:
			return state;
	}
};

export default questions;
