import get from './get';
import claim from './claim';
import verify from './verify';
import generate from './generate';
import update from './update';

export default {
	get,
	claim,
	verify,
	generate,
	update,
};
