import { ExamResultDocument } from '../../../types';
import { SAVE_EXAM_RESULT, GET_EXAM_RESULTS, ExamActionTypes } from './constants';

const initialState: ExamResultDocument[] = [];

const examResults = (state = initialState, action: ExamActionTypes) => {
	switch (action.type) {
		case GET_EXAM_RESULTS:
			return action.payload;

		case SAVE_EXAM_RESULT:
			return [...state, action.payload];

		default:
			return state;
	}
};

export default examResults;
