import { UPDATE_VOUCHER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateBookResponse, VoucherStates } from '../../../../types';
import reducerConfig from '../../reducerConfig';

type UpdateVoucherActions = 'state' | 'verified';

type UpdateVoucherData = {
	state?: VoucherStates;
};

const updateVoucherRequest = (
	voucherId: string,
	action: UpdateVoucherActions,
	data: UpdateVoucherData,
	token: string
) => {
	return axios.post(
		`${reducerConfig.api}/voucher/update`,
		{
			voucherId,
			action,
			data,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const updateVoucherAction = (res: UpdateBookResponse) => {
	return {
		type: UPDATE_VOUCHER,
		payload: res.data,
	};
};

const updateVoucher = (
	voucherId: string,
	action: UpdateVoucherActions,
	data: UpdateVoucherData,
	token: string
): any => {
	return (dispatch: any) => {
		return updateVoucherRequest(voucherId, action, data, token).then((res: AxiosResponse) =>
			dispatch(updateVoucherAction(res))
		);
	};
};

export default updateVoucher;
