import { HomeworkDocument } from '../../../../types';

export const ADD_HOMEWORK = 'ADD_HOMEWORK';
export const GET_HOMEWORKS = 'GET_HOMEWORKS';
export const UPDATE_HOMEWORK = 'UPDATE_HOMEWORK';

export interface AddHomeworkAction {
	type: typeof ADD_HOMEWORK;
	payload: {
		newHomework: HomeworkDocument;
	};
}

export interface GetHomeworksAction {
	type: typeof GET_HOMEWORKS;
	payload: HomeworkDocument[];
}

export interface UpdateHomeworkAction {
	type: typeof UPDATE_HOMEWORK;
	payload: HomeworkDocument;
}

export type HomeworkActionTypes = AddHomeworkAction | GetHomeworksAction | UpdateHomeworkAction;
