import { REMOVE_EMAIL_TEMPLATE } from '../constants';
import axios from 'axios';
import { GetTagsResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const removeEmailTemplatesRequest = (emailTemplateId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/email-template/remove/${emailTemplateId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeEmailTemplatesAction = (res: GetTagsResponse) => {
	return {
		type: REMOVE_EMAIL_TEMPLATE,
		payload: res.data,
	};
};

const removeEmailTemplates = (emailTemplateId: string, token: string): any => {
	return (dispatch: any) => {
		return removeEmailTemplatesRequest(emailTemplateId, token).then((res: GetTagsResponse) => {
			dispatch(removeEmailTemplatesAction(res));
			return res.data;
		});
	};
};

export default removeEmailTemplates;
