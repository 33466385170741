import { BookPageDocument } from '../../../../types';

export const ADD_BOOK_PAGES = 'ADD_BOOK_PAGES';
export const GET_BOOK_PAGES = 'GET_BOOK_PAGES';
export const UPDATE_BOOK_PAGE = 'UPDATE_BOOK_PAGE';
export const REMOVE_BOOK_PAGE = 'REMOVE_BOOK_PAGE';
export const UPDATE_BOOK_PAGES_SORT = 'UPDATE_BOOK_PAGES_SORT';

export interface GetBookPagesAction {
	type: typeof GET_BOOK_PAGES;
	payload: {
		success: boolean;
		books: BookPageDocument[];
	};
}

export interface AddBookPageAction {
	type: typeof ADD_BOOK_PAGES;
	payload: {
		success: boolean;
		newBookPages: BookPageDocument[];
	};
}

export interface UpdateBookPageAction {
	type: typeof UPDATE_BOOK_PAGE;
	payload: {
		success: boolean;
		newPage: BookPageDocument;
	};
}

export interface RemoveBookPageAction {
	type: typeof REMOVE_BOOK_PAGE;
	payload: {
		success: boolean;
		pageId: string;
	};
}

export interface UpdateBookPagesSortAction {
	type: typeof UPDATE_BOOK_PAGES_SORT;
	payload: {
		success: boolean;
		newBookPages: BookPageDocument[];
	};
}

export type BookPagesActionTypes =
	| GetBookPagesAction
	| UpdateBookPageAction
	| AddBookPageAction
	| UpdateBookPagesSortAction
	| RemoveBookPageAction;
