import { FETCH_QUESTIONS } from '../constants';
import { QuestionDocument } from '../../../../types';

const fetchQuestions = (questions: QuestionDocument[]): any => {
	return {
		type: FETCH_QUESTIONS,
		payload: questions,
	};
};

export default fetchQuestions;
