import axios, { AxiosResponse } from 'axios';

const removeFileRequest = (path: string, token: string) => {
	return axios.delete(`${process.env.REACT_APP_API_CENTRAL}/upload/remove`, {
		data: {
			path,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeFile = (path: string, token: string): any => {
	return () => {
		return removeFileRequest(path, token).then((res: AxiosResponse) => {
			return res.data;
		});
	};
};

export default removeFile;
