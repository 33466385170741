import { QuestionReportDocument } from '../../../../types';

export const ADD_QUESTION_REPORT = 'ADD_QUESTION_REPORT';
export const RESOLVE_QUESTION_REPORT = 'RESOLVE_QUESTION_REPORT';
export const REJECT_QUESTION_REPORT = 'REJECT_QUESTION_REPORT';
export const GET_QUESTION_REPORTS = 'GET_QUESTION_REPORTS';

export interface AddQuestionReportAction {
	type: typeof ADD_QUESTION_REPORT;
	payload: {
		newQuestionReport: QuestionReportDocument;
	};
}

export interface ConfirmQuestionReportAction {
	type: typeof RESOLVE_QUESTION_REPORT;
	payload: {
		newQuestionReport: QuestionReportDocument;
	};
}

export interface RejectQuestionReportAction {
	type: typeof REJECT_QUESTION_REPORT;
	payload: {
		newQuestionReport: QuestionReportDocument;
	};
}

export interface GetQuestionReportsAction {
	type: typeof GET_QUESTION_REPORTS;
	payload: {
		newQuestionReports: QuestionReportDocument[];
	};
}

export type QuestionReportActionTypes =
	| AddQuestionReportAction
	| ConfirmQuestionReportAction
	| RejectQuestionReportAction
	| GetQuestionReportsAction;
