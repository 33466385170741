import { SnackbarResponse } from '../../../../types';

export const ADD_SNACKBAR = 'ADD_SNACKBAR';
export const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';

export interface AddSnackbarAction {
	type: typeof ADD_SNACKBAR;
	payload: SnackbarResponse;
}

export interface ClearSnackbarAction {
	type: typeof CLEAR_SNACKBAR;
}

export type SnackbarActionTypes = AddSnackbarAction | ClearSnackbarAction;
