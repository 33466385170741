import { InviteDocument } from '../../../../types';

export const ADD_INVITE = 'ADD_INVITE';
export const CONFIRM_INVITE = 'CONFIRM_INVITE';
export const REJECT_INVITE = 'REJECT_INVITE';
export const GET_INVITES = 'GET_INVITES';

export interface AddInviteAction {
	type: typeof ADD_INVITE;
	payload: {
		newInvites: InviteDocument[];
	};
}

export interface ConfirmInviteAction {
	type: typeof CONFIRM_INVITE;
	payload: {
		newInvite: InviteDocument;
	};
}

export interface RejectInviteAction {
	type: typeof REJECT_INVITE;
	payload: {
		newInvite: InviteDocument;
	};
}

export interface GetInvitesAction {
	type: typeof GET_INVITES;
	payload: {
		newInvite: InviteDocument[];
	};
}

export type InviteActionTypes =
	| AddInviteAction
	| ConfirmInviteAction
	| RejectInviteAction
	| GetInvitesAction;
