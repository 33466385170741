import { EDIT_SUBJECT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { Subject, EditSubjectResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const editSubjectRequest = (subjectId: string, data: Subject, token: string) => {
	return axios.post(
		`${reducerConfig.api}/subject/edit`,
		{
			subjectId,
			...data,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const editSubjectAction = (res: EditSubjectResponse) => {
	return {
		type: EDIT_SUBJECT,
		payload: res,
	};
};

const editSubject = (subjectId: string, data: Subject, token: string): any => {
	return (dispatch: any) => {
		return editSubjectRequest(subjectId, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(editSubjectAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.success;
		});
	};
};

export default editSubject;
