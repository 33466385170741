import { QuestionTypesDocument } from '../../../types';
import {
	ADD_QUESTION_TYPE,
	EDIT_QUESTION_TYPE,
	REMOVE_QUESTION_TYPE,
	QuestionTypeActionTypes,
	GET_QUESTION_TYPES,
} from './constants';

const initialState: QuestionTypesDocument[] = [];

const questionTypes = (state = initialState, action: QuestionTypeActionTypes) => {
	switch (action.type) {
		case GET_QUESTION_TYPES:
			return action.payload.data;

		case ADD_QUESTION_TYPE:
			return [...state, action.payload.data.newQuestionType];

		case EDIT_QUESTION_TYPE:
			return state.map(questionType => {
				if (questionType._id === action.payload.data.questionTypeId) {
					return action.payload.data.newQuestionType;
				}
				return questionType;
			});

		case REMOVE_QUESTION_TYPE:
			return state.filter(questionType => questionType._id !== action.payload.data.questionTypeId);

		default:
			return state;
	}
};

export default questionTypes;
