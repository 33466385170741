import { FETCH_USB_DEVICE_INFO } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { DeviceTypes, GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const fetchDeviceInfoRequest = (token: string, serialNumber: number, type: DeviceTypes) => {
	return axios.get(`${reducerConfig.api}/device/fetch-info`, {
		params: {
			serialNumber,
			type,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const fetchDeviceInfoAction = (res: GetUsersResponse) => {
	return {
		type: FETCH_USB_DEVICE_INFO,
		payload: res.data.device,
	};
};

const fetchDeviceInfo = (token: string, serialNumber: number, type: DeviceTypes): any => {
	return (dispatch: any) => {
		return fetchDeviceInfoRequest(token, serialNumber, type).then((res: AxiosResponse) => {
			if (res.data.success) {
				return dispatch(fetchDeviceInfoAction(res));
			}
		});
	};
};

export default fetchDeviceInfo;
