import { GET_SCHOOLS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const searchSchoolsRequest = (search: string) => {
	return axios.post(`${reducerConfig.api}/school/search`, { search });
};

const searchSchoolsAction = (res: AxiosResponse) => {
	return {
		type: GET_SCHOOLS,
		payload: res.data.schools,
	};
};

const searchSchools = (search: string): any => {
	return (dispatch: any) => {
		return searchSchoolsRequest(search).then((res: AxiosResponse) => {
			dispatch(searchSchoolsAction(res));
			return res;
		});
	};
};

export default searchSchools;
