import axios from 'axios';
import { User } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const registerStudent = (
	data: Omit<User & { password: string }, '_id' | 'state' | 'token'> & {
		classroomId: string;
		inviteId?: string;
	}
) => {
	return axios.post(`${reducerConfig.api}/user/register-student`, data);
};

export default registerStudent;
