import { ExamDocument } from '../../../../types';

export const ADD_EXAM = 'ADD_EXAM';
export const UPDATE_EXAM = 'UPDATE_EXAM';
export const REMOVE_EXAM = 'REMOVE_EXAM';
export const GET_EXAMS = 'GET_EXAMS';
export const FETCH_EXAMS = 'FETCH_EXAMS';

export interface AddExamAction {
	type: typeof ADD_EXAM;
	payload: {
		newExam: ExamDocument;
	};
}

export interface RemoveExamAction {
	type: typeof REMOVE_EXAM;
	payload: {
		examId: string;
		token: string;
	};
}

export interface UpdateExamAction {
	type: typeof UPDATE_EXAM;
	payload: {
		examId: string;
		newExam: ExamDocument;
		token: string;
	};
}

export interface GetExamsAction {
	type: typeof GET_EXAMS;
	payload: {
		exams: ExamDocument[];
		page: number;
		pageSize: number;
	};
}

export interface FetchExamsAction {
	type: typeof FETCH_EXAMS;
	payload: ExamDocument[];
}

export type ExamActionTypes =
	| AddExamAction
	| RemoveExamAction
	| UpdateExamAction
	| GetExamsAction
	| FetchExamsAction;
