import get from './get';
import add from './add';
import update from './update';
import remove from './remove';

export default {
	get,
	remove,
	add,
	update,
};
