import { ExerciseDocument } from '../../../../types';

export const ADD_EXERCISE = 'ADD_EXERCISE';
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE';
export const REMOVE_EXERCISE = 'REMOVE_EXERCISE';
export const GET_EXERCISES = 'GET_EXERCISES';
export const FETCH_EXERCISES = 'FETCH_EXERCISES';

export interface AddExercisesAction {
	type: typeof ADD_EXERCISE;
	payload: {
		newExercise: ExerciseDocument;
	};
}

export interface RemoveExercisesAction {
	type: typeof REMOVE_EXERCISE;
	payload: {
		exerciseId: string;
		token: string;
	};
}

export interface UpdateExercisesAction {
	type: typeof UPDATE_EXERCISE;
	payload: {
		exerciseId: string;
		newExercise: ExerciseDocument;
		token: string;
	};
}

export interface GetExercisessAction {
	type: typeof GET_EXERCISES;
	payload: {
		newExercises: ExerciseDocument[];
	};
}

export interface FetchExercisessAction {
	type: typeof FETCH_EXERCISES;
	payload: ExerciseDocument[];
}

export type ExercisesActionTypes =
	| AddExercisesAction
	| RemoveExercisesAction
	| UpdateExercisesAction
	| GetExercisessAction
	| FetchExercisessAction;
