import { CustomExam, CustomExamDocument } from '../../../../types';

export const GET_CUSTOM_EXAMS = 'GET_CUSTOM_EXAMS';
export const ADD_CUSTOM_EXAM = 'ADD_CUSTOM_EXAM';
export const REMOVE_CUSTOM_EXAM = 'REMOVE_CUSTOM_EXAM';
export const UPDATE_CUSTOM_EXAM = 'UPDATE_CUSTOM_EXAM';

export interface GetCustomExamAction {
	type: typeof GET_CUSTOM_EXAMS;
	payload: {
		success: boolean;
		customExams: CustomExam[];
	};
}

export interface AddCustomExamAction {
	type: typeof ADD_CUSTOM_EXAM;
	payload: {
		newCustomExam: CustomExam;
	};
}

export interface RemoveCustomExamAction {
	type: typeof REMOVE_CUSTOM_EXAM;
	payload: {
		customExamId: string;
		success: boolean;
	};
}

export interface UpdateCustomExamAction {
	type: typeof UPDATE_CUSTOM_EXAM;
	payload: {
		newCustomExam: CustomExamDocument;
	};
}

export type CustomExamActionTypes =
	| GetCustomExamAction
	| AddCustomExamAction
	| RemoveCustomExamAction
	| UpdateCustomExamAction;
