import axios from 'axios';
import { User } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const registerTeacher = (
	data: Omit<User & { password: string; passwordRepeat: string }, '_id'> & {
		schoolId: string;
		inviteId: string;
	}
) => {
	return axios.post(`${reducerConfig.api}/user/register-teacher`, data);
};

export default registerTeacher;
