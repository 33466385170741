import { ExamDocument } from '../../../types';
import {
	ADD_CLASSROOM,
	REMOVE_CLASSROOM,
	UPDATE_CLASSROOM,
	GET_CLASSROOMS,
	ClassroomActionTypes,
} from './constants';

const initialState: ExamDocument[] = [];

const exams = (state = initialState, action: ClassroomActionTypes) => {
	switch (action.type) {
		case GET_CLASSROOMS:
			return action.payload;

		case ADD_CLASSROOM:
			return [...state, action.payload.newClassroom];

		case REMOVE_CLASSROOM:
			return state.filter(classroom => classroom._id !== action.payload.classroomId);

		case UPDATE_CLASSROOM:
			return state.map(classroom => {
				if (classroom._id === action.payload.newClassroom._id) {
					return {
						...classroom,
						...action.payload.newClassroom,
					};
				}
				return classroom;
			});

		default:
			return state;
	}
};

export default exams;
