import axios from 'axios';
import reducerConfig from '../../reducerConfig';

const getById = (schoolId?: string) => {
	return axios.get(`${reducerConfig.api}/school/get-by-id`, {
		params: {
			schoolId,
		},
	});
};

export default getById;
