import { BookFeedbackDocument } from '../../../../types';

export const ADD_BOOK_FEEDBACK = 'ADD_BOOK_FEEDBACK';
export const GET_BOOK_FEEDBACK = 'GET_BOOK_FEEDBACK';

export interface GetBookFeedbackAction {
	type: typeof GET_BOOK_FEEDBACK;
	payload: {
		success: boolean;
		books: BookFeedbackDocument[];
	};
}

export interface AddBookPageAction {
	type: typeof ADD_BOOK_FEEDBACK;
	payload: {
		success: boolean;
		newBookFeedback: BookFeedbackDocument[];
	};
}

export type BookFeedbackActionTypes = GetBookFeedbackAction | AddBookPageAction;
