import { EDIT_USER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { Projects, Languages } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const registerProjectRequest = (userId: string, project: Projects, language: Languages) => {
	return axios.post(`${reducerConfig.api}/user/register-project`, {
		userId,
		project,
		language,
	});
};

const registerProjectAction = (res: AxiosResponse) => {
	return {
		type: EDIT_USER,
		payload: res.data,
	};
};

const registerProject = (userId: string, project: Projects, language: Languages): any => {
	return (dispatch: any) => {
		return registerProjectRequest(userId, project, language).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(registerProjectAction(res));
			}
			return res;
		});
	};
};

export default registerProject;
