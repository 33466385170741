import { EmailTemplateDocument } from '../../../types';
import {
	ADD_EMAIL_TEMPLATE,
	GET_EMAIL_TEMPLATES,
	REMOVE_EMAIL_TEMPLATE,
	UPDATE_EMAIL_TEMPLATE,
	EmailTemplateActionTypes,
} from './constants';

const initialState: EmailTemplateDocument[] = [];

const emailTemplates = (state = initialState, action: EmailTemplateActionTypes) => {
	switch (action.type) {
		case GET_EMAIL_TEMPLATES:
			return action.payload;

		case ADD_EMAIL_TEMPLATE:
			return [...state, action.payload.newEmailTemplate];

		case REMOVE_EMAIL_TEMPLATE:
			return state.filter(s => s._id !== action.payload.emailTemplateId);

		case UPDATE_EMAIL_TEMPLATE:
			return state.map(s => {
				if (s._id === action.payload.newEmailTemplate._id) {
					return action.payload.newEmailTemplate;
				}
				return s;
			});

		default:
			return state;
	}
};

export default emailTemplates;
