import { GET_DEVICE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';
import fetchUsersAction from '../../../common/users/actions/fetch';

const getDeviceRequest = (token: string, serialNumber: number) => {
	return axios.get(`${reducerConfig.api}/device/connect`, {
		params: {
			serialNumber,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getDeviceAction = (res: GetUsersResponse) => {
	return {
		type: GET_DEVICE,
		payload: res.data,
	};
};

const getDevices = (token: string, serialNumber: number): any => {
	return (dispatch: any) => {
		return getDeviceRequest(token, serialNumber).then((res: AxiosResponse) => {
			if (res.data.users) {
				dispatch(fetchUsersAction(res.data.users));
			}
			dispatch(getDeviceAction(res));
		});
	};
};

export default getDevices;
