import get from './get';
import getRandom from './getRandom';
import add from './add';
import remove from './remove';
import update from './update';

export default {
	get,
	getRandom,
	add,
	remove,
	update,
};
