import { RegistrationDocument } from '../../../types';
import {
	ADD_REGISTRATION,
	CONFIRM_REGISTRATION,
	REJECT_REGISTRATION,
	GET_REGISTRATIONS,
	RegistrationActionTypes,
} from './constants';

const initialState: RegistrationDocument[] = [];

const registrations = (state = initialState, action: RegistrationActionTypes) => {
	switch (action.type) {
		case GET_REGISTRATIONS:
			return action.payload;

		case ADD_REGISTRATION:
			if (action.payload.newRegistrations) {
				return [...state, ...action.payload.newRegistrations];
			}
			return state;

		case CONFIRM_REGISTRATION:
			return state.map(invite => {
				if (invite._id === action.payload.newRegistration._id) {
					return {
						...invite,
						...action.payload.newRegistration,
					};
				}
				return invite;
			});

		case REJECT_REGISTRATION:
			return state.map(invite => {
				if (invite._id === action.payload.newRegistration._id) {
					return action.payload.newRegistration;
				}
				return invite;
			});

		default:
			return state;
	}
};

export default registrations;
