import { ELASTICSEARCH } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { ElasticSearchRoutes } from '../../../../types';
import { getSegmentsAction } from '../../segments/actions/get';
import reducerConfig from '../../reducerConfig';

const elasticSearchRequest = (
	route: ElasticSearchRoutes,
	params: { query: string; subject?: string; grade?: number },
	token: string
) => {
	return axios.post(`${reducerConfig.api}/elastic/${route}`, params, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const elasticSearchAction = (res: AxiosResponse) => {
	return {
		type: ELASTICSEARCH,
		payload: res.data,
	};
};

const elasticSearch = (
	route: ElasticSearchRoutes,
	params: { query: string; subject?: string; grade?: number },
	token: string
): any => {
	return (dispatch: any) => {
		return elasticSearchRequest(route, params, token).then((res: AxiosResponse) => {
			dispatch(getSegmentsAction(res.data));
			dispatch(elasticSearchAction(res));
			return res.data;
		});
	};
};

export default elasticSearch;
