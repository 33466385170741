import { GET_SCHOOLS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse, MongooseQuerySort, SchoolFilter } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getSchoolRequest = (token: string, filter: SchoolFilter, sort: MongooseQuerySort) => {
	return axios.get(`${reducerConfig.api}/school/get`, {
		params: { ...filter, ...sort },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getSchoolAction = (res: GetUsersResponse) => {
	return {
		type: GET_SCHOOLS,
		payload: res.data.schools,
	};
};

const getSchools = (token: string, filter: SchoolFilter, sort: MongooseQuerySort): any => {
	return (dispatch: any) => {
		return getSchoolRequest(token, filter, sort).then((res: AxiosResponse) => {
			dispatch(getSchoolAction(res));
			return res;
		});
	};
};

export default getSchools;
