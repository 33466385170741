import { GET_EXERCISES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { ExerciseFilter, MongooseQuerySort, GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getExerciseRequest = (token: string, filter: ExerciseFilter, sort: MongooseQuerySort) => {
	return axios.get(`${reducerConfig.api}/exercise/get`, {
		params: { ...filter, ...sort },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExerciseAction = (res: GetUsersResponse) => {
	return {
		type: GET_EXERCISES,
		payload: res.data.exercises,
	};
};

const getExercise = (token: string, filter: ExerciseFilter, sort: MongooseQuerySort): any => {
	return (dispatch: any) => {
		return getExerciseRequest(token, filter, sort).then((res: AxiosResponse) => {
			dispatch(getExerciseAction(res));
			return res;
		});
	};
};

export default getExercise;
