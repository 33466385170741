import { GET_DEVICES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getDeviceRequest = (token: string, data?: { keyboardId?: string; schoolId?: string }) => {
	return axios.get(`${reducerConfig.api}/device/get`, {
		params: {
			schoolId: data?.schoolId,
			keyboardId: data?.keyboardId,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getDeviceAction = (res: GetUsersResponse) => {
	return {
		type: GET_DEVICES,
		payload: res.data,
	};
};

const getDevices = (token: string, data?: { keyboardId?: string; schoolId?: string }): any => {
	return (dispatch: any) => {
		return getDeviceRequest(token, data).then((res: AxiosResponse) => dispatch(getDeviceAction(res)));
	};
};

export default getDevices;
