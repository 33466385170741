import i18next from 'i18next';
import Types from '@taktik/common/types';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Reducers from '@taktik/common/reducers';

interface TranslationLoaderProps {
	translations: Types.TranslationDocument[];
	language: Types.Languages;
	onLoad: () => void;
}

const TranslationLoader = (props: TranslationLoaderProps) => {
	const translations = props.translations;
	const language = props.language;
	const dispatch = useDispatch();

	useEffect(() => {
		const translation = translations.find(t => t.lang === language);
		if (translation) {
			i18next.addResourceBundle(language, 'common', translation.data);
			i18next.changeLanguage(language);
			props.onLoad();
		}
	}, [translations, language]);

	useEffect(() => {
		dispatch(Reducers.common.translations.actions.get());
	}, []);

	return null;
};

export default connect((state: Types.RootState) => ({
	translations: state.translations,
}))(TranslationLoader);
