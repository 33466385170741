import Types from '../../../../types';

export const SET_BOOK_ACTIVE = 'SET_BOOK_ACTIVE';

export interface SetActiveBookAction {
	type: typeof SET_BOOK_ACTIVE;
	payload: Types.BookActiveProps;
}

export type ActiveBookActionTypes = SetActiveBookAction;
