import { GET_EXAMS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { ExamFilter, MongooseQuerySort, GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getRequest = (token: string, filter: ExamFilter, sort: MongooseQuerySort) => {
	return axios.get(`${reducerConfig.api}/exam/get`, {
		params: { ...filter, ...sort },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExamAction = (res: GetUsersResponse) => {
	return {
		type: GET_EXAMS,
		payload: res.data,
	};
};

const get = (token: string, filter: ExamFilter, sort: MongooseQuerySort): any => {
	return (dispatch: any) => {
		return getRequest(token, filter, sort).then((res: AxiosResponse) => {
			dispatch(getExamAction(res));
			return res;
		});
	};
};

export default get;
