import { FETCH_REVISION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { getExamAction } from '../../exams/actions/get';
import reducerConfig from '../../reducerConfig';

const fetchRevisionRequest = (id: string) => {
	return axios.get(`${reducerConfig.api}/revision/get-public`, {
		params: { id },
	});
};

const fetchRevisionAction = (res: AxiosResponse) => {
	return {
		type: FETCH_REVISION,
		payload: res.data,
	};
};

const fetchRevision = (id: string): any => {
	return (dispatch: any) => {
		return fetchRevisionRequest(id).then((res: AxiosResponse) => {
			dispatch(fetchRevisionAction(res));
			if (res.data.exams) {
				dispatch(
					getExamAction({
						...res,
						data: res.data.exams,
					})
				);
			}
			return res;
		});
	};
};

export default fetchRevision;
