import { GET_CLASSROOMS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import fetchUsersAction from '../../../common/users/actions/fetch';
import reducerConfig from '../../reducerConfig';

const getRequest = (token: string, schoolId?: string) => {
	return axios.get(`${reducerConfig.api}/classroom`, {
		params: {
			schoolId,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getAction = (res: GetUsersResponse) => {
	return {
		type: GET_CLASSROOMS,
		payload: res.data.classrooms,
	};
};

const get = (token: string, schoolId?: string): any => {
	return (dispatch: any) => {
		return getRequest(token, schoolId).then((res: AxiosResponse) => {
			if (res.data.users) {
				dispatch(fetchUsersAction(res.data.users));
			}
			dispatch(getAction(res));
		});
	};
};

export default get;
