import makeStyles from '@mui/styles/makeStyles';
import theme from './theme';

const drawerWidth = 240;

const useStyles = makeStyles({
	root: {
		display: 'flex',
	},
	toolbarIcon: {
		position: 'fixed',
		left: 0,
		bottom: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 12px',
		transition: theme.transitions.create(['left'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...theme.mixins.toolbar,
	},
	toolbarIconOpen: {
		left: drawerWidth - 72,
	},
	accordionRoot: {
		border: `1px solid ${theme.palette.grey[300]}`,
		borderBottom: 'none',
	},
	accordionRootExpanded: {
		'&:first-child, &:last-child': {
			marginBottom: `${theme.spacing(2)}px !important`,
			marginTop: `${theme.spacing(2)}px !important`,
		},
	},
	accordionDetailsRoot: {
		paddingBottom: 0,
	},
	accordionSummaryContent: {
		alignItems: 'center',
	},
	accordionDetailsTable: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	tabPanel: {
		background: '#fff',
	},
	tableRowDisabled: {
		opacity: 0.5,
	},
	tableGrid: {
		'& th, td': {
			border: '1px solid rgba(224, 224, 224, 1)',
		},
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	menuItemNested: {
		paddingLeft: theme.spacing(4),
	},
	logo: {
		flexGrow: 1,
		marginLeft: -6,
		marginTop: 6,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		paddingTop: 52,
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: {
		paddingTop: 52,
	},
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
		width: `calc(100vw - 71px)`,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	contentOpen: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		width: `calc(100vw - ${drawerWidth}px)`,
	},
	contentLanding: {
		flexGrow: 1,
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	landingContainer: {},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	paperButton: {
		background: theme.palette.grey[200],
		cursor: 'pointer',
		'&:hover': {
			background: theme.palette.grey[100],
		},
	},
	fixedHeight: {
		height: 240,
	},
	listItemAnswer: {
		paddingRight: theme.spacing(8),
	},
	listItemIcon: {
		marginLeft: 6,
	},
	listItemDivider: {
		borderBottom: 'none',
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	listItemIconAnswer: {
		minWidth: theme.spacing(8),
		justifyContent: 'center',
	},
	listItemSecondaryAction: {
		right: theme.spacing(2.5),
	},
	fileContainer: {
		position: 'relative',
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: 5,
		padding: theme.spacing(1),
	},
	fileActions: {
		position: 'absolute',
		top: theme.spacing(1.5),
		right: theme.spacing(2),
		background: 'rgba(255,255,255,0.5)',
	},
	depositContext: {
		flex: 1,
	},
	treeViewRoot: {
		flexGrow: 1,
	},
	dialogWithTabs: {
		padding: 0,
		'& .MuiTabs-root': {
			padding: `0 ${theme.spacing(3)}px`,
		},
	},
	dialogActions: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		justifyContent: 'space-between',
	},
	dialogActionsSingle: {
		paddingTop: 0,
		justifyContent: 'flex-end',
	},
	dialogBaseline: {
		'& .MuiDialog-container': {
			alignItems: 'baseline',
		},
	},
	chipId: {
		backgroundColor: '#fad176',
	},
	chipDiffRoot: {
		height: 'auto',
	},
	chipDiffBody: {
		whiteSpace: 'normal',
	},
	colorPickerPopover: {
		position: 'absolute',
		zIndex: 2,
	},
	colorPickerCover: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
	calendarEvent: {
		backgroundColor: '#fff',
		border: `1px solid ${theme.palette.divider}`,
		color: theme.palette.text.primary,
	},
	calendarEventLabel: {
		width: theme.spacing(1),
		height: theme.spacing(1),
		marginRight: theme.spacing(0.5),
		display: 'inline-table',
		borderRadius: 4,
	},
	calendarEventWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	timeline: {
		background: '#fff',
		zIndex: 9,
	},
	paperHighlight: {
		boxShadow: `0 0 0 1px ${theme.palette.secondary.light}`,
	},
	loaderContainer: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&.inline': {
			height: 'auto',
		},
	},
	inputQuestion: {
		fontSize: theme.typography.h4.fontSize,
		padding: theme.spacing(2),
		width: '100%',
	},
	inputAnswer: {
		fontSize: theme.typography.h5.fontSize,
		padding: theme.spacing(1),
	},
	questionTitle: {
		'& p': {
			display: 'inline',
		},
	},
	tabs: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		minWidth: 300,
		maxWidth: 300,
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	tabSelected: {
		background: theme.palette.grey[200],
	},
	tabWrapper: {
		alignItems: 'flex-start',
		textTransform: 'none',
	},
	tabAddButton: {
		opacity: 1,
	},
	tabLabelRoot: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	tabLabelText: {
		textAlign: 'left',
	},
	selectBig: {
		fontSize: theme.typography.h4.fontSize,
		paddingTop: 24,
		paddingBottom: 0,
	},
	selectBigItem: {
		fontSize: theme.typography.h4.fontSize,
	},
	selectMedium: {
		fontSize: theme.typography.body1.fontSize,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	selectMediumItem: {
		fontSize: theme.typography.h6.fontSize,
	},
	scrollPaper: {
		alignItems: 'normal',
	},
	stepperRoot: {
		background: '#fff',
		borderRadius: '4px 4px 0 0',
		borderBottom: `1px solid ${theme.palette.grey[400]}`,
		paddingBottom: theme.spacing(3),
	},
	searchRoot: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
	},
	searchIconButton: {
		padding: 10,
	},
	searchInput: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	rteEditor: {
		padding: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		fontSize: theme.typography.h5.fontSize,
	},
	rteEditorSmall: {
		fontSize: theme.typography.body1.fontSize,
	},
	rteContainer: {
		marginTop: '0px !important',
	},
	rteToolbar: {
		position: 'absolute',
		top: -20,
		background: theme.palette.grey[100],
	},
	innerHtml: {
		'& p, & div': {
			margin: 0,
			lineHeight: 1.2,
		},
	},
	rtePlaceholder: {
		minHeight: theme.spacing(6),
	},
	rteCharsLeft: {
		position: 'absolute',
		top: -12,
		right: 0,
		background: theme.palette.grey[100],
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	repeatingCheckbox: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	versionControl: {
		position: 'absolute',
		bottom: theme.spacing(1),
		fontSize: theme.typography.caption.fontSize,
		width: drawerWidth,
		textAlign: 'center',
		background: '#fff',
		color: theme.palette.grey[600],
	},
	revisionCheckbox: {
		position: 'absolute',
		left: theme.spacing(1),
		top: theme.spacing(1),
	},
	revisionContainer: {
		position: 'relative',
		paddingLeft: theme.spacing(3),
	},
});

export default useStyles;
