import add from './add';
import addClassroom from './addClassroom';
import addSubject from './addSubject';
import addLicence from './addLicence';
import editLicence from './editLicence';
import changePassword from './changePassword';
import edit from './edit';
import fetch from './fetch';
import get from './get';
import getBy from './getBy';
import remove from './remove';
import removeClassroom from './removeClassroom';
import removeSubject from './removeSubject';
import setDispatcher from './setDispatcher';
import setManager from './setManager';
import setState from './setState';
import fillEmail from './fillEmail';
import registerProject from './registerProject';
import registerVoucher from './registerVoucher';
import registerStudent from './registerStudent';
import registerTeacher from './registerTeacher';
import verifyEmail from './verifyEmail';
import updateLicences from './updateLicences';

export default {
	add,
	addClassroom,
	addSubject,
	addLicence,
	editLicence,
	changePassword,
	edit,
	fetch,
	get,
	getBy,
	remove,
	removeClassroom,
	removeSubject,
	setDispatcher,
	setManager,
	setState,
	fillEmail,
	registerProject,
	registerVoucher,
	registerStudent,
	registerTeacher,
	verifyEmail,
	updateLicences,
};
