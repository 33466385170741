import { UPDATE_BOOK_PAGES_SORT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';
import { UpdateBookPagesSort, UpdateBookPagesSortResponse } from '../../../../types';

const updateBookPagesSortRequest = (data: UpdateBookPagesSort, token: string) => {
	return axios.post(`${reducerConfig.api}/book/update-sort`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const updateBookPagesSortAction = (res: UpdateBookPagesSortResponse) => {
	return {
		type: UPDATE_BOOK_PAGES_SORT,
		payload: res.data,
	};
};

const updateBookPagesSort = (data: UpdateBookPagesSort, token: string): any => {
	return (dispatch: any) => {
		return updateBookPagesSortRequest(data, token).then((res: AxiosResponse) =>
			dispatch(updateBookPagesSortAction(res))
		);
	};
};

export default updateBookPagesSort;
