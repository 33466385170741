import axios from 'axios';
import { CustomerRoleTypes, UserData } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const registerVoucher = (
	data: UserData & {
		schoolId: string;
		password: string;
		projectRole: CustomerRoleTypes;
		voucherId: string;
		codeId: string;
	}
) => {
	return axios.post(`${reducerConfig.api}/user/register-voucher`, data);
};

export default registerVoucher;
