import { GET_REPEATINGS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const getRepeatingRequest = (token: string, ids?: string[]) => {
	return axios.get(`${reducerConfig.api}/repeating/get`, {
		params: { ids },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getRepeatingAction = (res: AxiosResponse) => {
	return {
		type: GET_REPEATINGS,
		payload: res.data,
	};
};

const getRepeating = (token: string, ids?: string[]): any => {
	return (dispatch: any) => {
		return getRepeatingRequest(token, ids).then((res: AxiosResponse) => {
			dispatch(getRepeatingAction(res));
			return res.data;
		});
	};
};

export default getRepeating;
