import { SET_DEVICE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveUserResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const setDeviceRequest = (deviceId: string | undefined, token: string) => {
	return axios.post(
		`${reducerConfig.api}/user/set-Device`,
		{ deviceId },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const setDeviceAction = (res: RemoveUserResponse) => {
	return {
		type: SET_DEVICE,
		payload: res.data,
	};
};

const setDevice = (deviceId: string | undefined, token: string): any => {
	return (dispatch: any) => {
		return setDeviceRequest(deviceId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(setDeviceAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default setDevice;
