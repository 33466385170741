import { GET_QUOTES } from '../constants';
import axios from 'axios';
import { GetTagsResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getQuotesRequest = (token: string, ids?: string[]) => {
	return axios.get(`${reducerConfig.api}/quote/get`, {
		params: { ids },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getQuotesAction = (res: GetTagsResponse) => {
	return {
		type: GET_QUOTES,
		payload: res.data,
	};
};

const getQuotes = (token: string, ids?: string[]): any => {
	return (dispatch: any) => {
		return getQuotesRequest(token, ids).then((res: GetTagsResponse) => {
			dispatch(getQuotesAction(res));
			return res.data;
		});
	};
};

export default getQuotes;
