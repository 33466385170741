import get from './get';
import add from './add';
import updateAnswer from './updateAnswer';
import updateState from './updateState';

export default {
	get,
	add,
	updateAnswer,
	updateState,
};
