import { Migration, MigrationTable } from '../../../../types';

export const GET_MIGRATION_TABLES = 'GET_MIGRATION_TABLES';
export const UPDATE_MIGRATION = 'UPDATE_MIGRATION';

export interface GetMigrationsAction {
	type: typeof GET_MIGRATION_TABLES;
	payload: Migration;
}

export interface UpdateMigrationsAction {
	type: typeof UPDATE_MIGRATION;
	payload: MigrationTable;
}

export type MigrationActionTypes = GetMigrationsAction | UpdateMigrationsAction;
