import { GENERATE_VOUCHER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const generateVoucherRequest = (count: number, token: string) => {
	return axios.put(
		`${reducerConfig.api}/voucher/generate`,
		{
			count,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const generateVoucherAction = (res: AxiosResponse) => {
	return {
		type: GENERATE_VOUCHER,
		payload: res.data.newVoucher,
	};
};

const generateVoucher = (count: number, token: string): any => {
	return (dispatch: any) => {
		return generateVoucherRequest(count, token).then((res: AxiosResponse) => {
			dispatch(generateVoucherAction(res));
			return res;
		});
	};
};

export default generateVoucher;
