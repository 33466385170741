import { REMOVE_BOOK_CATEGORY } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveSchoolResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const removeBookCategoryRequest = (categoryId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/book-category/remove/${categoryId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeBookCategoryAction = (res: RemoveSchoolResponse, categoryId: string) => {
	return {
		type: REMOVE_BOOK_CATEGORY,
		payload: {
			categoryId,
		},
	};
};

const removeBookCategory = (categoryId: string, token: string): any => {
	return (dispatch: any) => {
		return removeBookCategoryRequest(categoryId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeBookCategoryAction(res, categoryId));
			}
		});
	};
};

export default removeBookCategory;
