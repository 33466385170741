import { UPDATE_TEACHING_PLAN } from '../constants';
import axios, { AxiosResponse } from 'axios';
import {
	UpdateTeachingPlan,
	UpdateTeachingPlanActions,
	UpdateTeachingPlanResponse,
} from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const updateTeachingPlanRequest = (
	action: UpdateTeachingPlanActions,
	data: UpdateTeachingPlan,
	token: string
) => {
	const uri = `${reducerConfig.api}/teaching-plan/${action}`;
	const options = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	if (action.startsWith('add')) {
		return axios.put(uri, data, options);
	} else if (action.startsWith('remove')) {
		return axios.delete(uri, { ...options, data });
	}
	return axios.post(uri, data, options);
};

const updateTeachingPlanAction = (res: UpdateTeachingPlanResponse) => {
	return {
		type: UPDATE_TEACHING_PLAN,
		payload: res.data,
	};
};

const updateTeachingPlan = (
	action: UpdateTeachingPlanActions,
	data: UpdateTeachingPlan,
	token: string
): any => {
	return (dispatch: any) => {
		return updateTeachingPlanRequest(action, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateTeachingPlanAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data;
		});
	};
};

export default updateTeachingPlan;
