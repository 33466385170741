import { RootState, QuestionsStats, StudentsStats, SegmentsStats } from '../../../types';
import {
	GET_QUESTIONS_STATS,
	GET_STUDENTS_STATS,
	GET_SEGMENTS_STATS,
	GET_STATS_OVERVIEW,
	StatsActionTypes,
} from './constants';

const initialState: RootState<QuestionsStats | StudentsStats | SegmentsStats>['stats'] = [];

const stats = (state = initialState, action: StatsActionTypes) => {
	switch (action.type) {
		case GET_QUESTIONS_STATS:
			return action.payload;

		case GET_STUDENTS_STATS:
			return action.payload;

		case GET_SEGMENTS_STATS:
			return action.payload;

		case GET_STATS_OVERVIEW:
			return action.payload;

		default:
			return state;
	}
};

export default stats;
