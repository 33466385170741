import { REMOVE_CLASSROOM } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveSchoolResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeClassroomRequest = (classroomId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/classroom/remove/${classroomId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeClassroomAction = (res: RemoveSchoolResponse, classroomId: string) => {
	return {
		type: REMOVE_CLASSROOM,
		payload: {
			classroomId,
		},
	};
};

const removeClassroom = (classroomId: string, token: string): any => {
	return (dispatch: any) => {
		return removeClassroomRequest(classroomId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeClassroomAction(res, classroomId));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default removeClassroom;
