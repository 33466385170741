import { REJECT_INVITE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const rejectInviteRequest = (inviteId: string, token: string) => {
	return axios.post(`${reducerConfig.api}/invite/reject/${inviteId}`, null, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const rejectInviteAction = (res: AxiosResponse) => {
	return {
		type: REJECT_INVITE,
		payload: res.data,
	};
};

const rejectInvite = (inviteId: string, token: string): any => {
	return (dispatch: any) => {
		return rejectInviteRequest(inviteId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(rejectInviteAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newInvite;
		});
	};
};

export default rejectInvite;
