import { CHANGE_PASSWORD } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../snackbars/actions/add';
import { ChangeUserPassword } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const changePasswordRequest = (data: ChangeUserPassword, token: string) => {
	return axios.post(`${reducerConfig.api}/user/change-password`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const changePasswordAction = () => {
	return {
		type: CHANGE_PASSWORD,
	};
};

const changePassword = (data: ChangeUserPassword, token: string): any => {
	return (dispatch: any) => {
		return changePasswordRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.snackbars && res.data.snackbars.length > 0) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.success) {
				dispatch(changePasswordAction());
			}
			return res.data.success;
		});
	};
};

export default changePassword;
