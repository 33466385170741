import { ADD_REPEATING_ITEMS } from '../constants';
import { RevisionItemDocument } from '../../../../types';

const addRepeatingItems = (revisionItems: RevisionItemDocument[]): any => {
	return {
		type: ADD_REPEATING_ITEMS,
		payload: revisionItems,
	};
};

export default addRepeatingItems;
