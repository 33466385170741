import { User, RoleTypesList } from '../../../types';
import { SET_USER_SESSION, UserActionTypes } from './constants';
import { EDIT_USER, EditUserAction } from '../users/constants';

const initialState: User = {
	_id: '',
	email: '',
	phone: '',
	degree: '',
	firstName: '',
	lastName: '',
	role: RoleTypesList.unauthorized,
	state: 'active',
	isManager: false,
	token: '',
	projects: {
		voti: {
			sk: { active: false },
			cs: { active: false },
			ro: { active: false },
		},
		interaktiv: {
			sk: { active: false },
			cs: { active: false },
			ro: { active: false },
		},
		central: {
			sk: { active: false },
			cs: { active: false },
			ro: { active: false },
		},
		eshop: {
			sk: { active: false },
			cs: { active: false },
			ro: { active: false },
		},
	},
};

const token = (state = initialState, action: UserActionTypes | EditUserAction) => {
	switch (action.type) {
		case SET_USER_SESSION:
			return action.payload;

		case EDIT_USER:
			if (action.payload.newUser._id === state._id) {
				return {
					...action.payload.newUser,
					token: state.token,
				};
			}
			return state;

		default:
			return state;
	}
};

export default token;
