import { DeviceDocument } from '../../../../types';

export const ADD_DEVICES = 'ADD_DEVICES';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const REMOVE_DEVICES = 'REMOVE_DEVICES';
export const GET_DEVICES = 'GET_DEVICES';

export interface AddDevicesAction {
	type: typeof ADD_DEVICES;
	payload: {
		newDevice: DeviceDocument;
	};
}

export interface RemoveDevicesAction {
	type: typeof REMOVE_DEVICES;
	payload: {
		deviceId: string;
		token: string;
	};
}

export interface UpdateDevicesAction {
	type: typeof UPDATE_DEVICES;
	payload: {
		deviceId: string;
		newDevice: DeviceDocument;
		token: string;
	};
}

export interface GetDevicesAction {
	type: typeof GET_DEVICES;
	payload: {
		newDevice: DeviceDocument[];
	};
}

export type DeviceActionTypes =
	| AddDevicesAction
	| RemoveDevicesAction
	| UpdateDevicesAction
	| GetDevicesAction;
