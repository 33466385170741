import { HomeworkDocument } from '../../../types';
import { ADD_HOMEWORK, GET_HOMEWORKS, UPDATE_HOMEWORK, HomeworkActionTypes } from './constants';

const initialState: HomeworkDocument[] = [];

const tags = (state = initialState, action: HomeworkActionTypes) => {
	switch (action.type) {
		case GET_HOMEWORKS:
			return action.payload;

		case ADD_HOMEWORK:
			return [...state, action.payload.newHomework];

		case UPDATE_HOMEWORK:
			return state.map(homework => {
				if (homework._id === action.payload._id) {
					return {
						...homework,
						...action.payload,
					};
				}
				return homework;
			});

		default:
			return state;
	}
};

export default tags;
