import { TranslationPath } from '../../../../types';

export const SET_TRANSLATION_EDIT = 'SET_TRANSLATION_EDIT';

export interface SetTranslationEditAction {
	type: typeof SET_TRANSLATION_EDIT;
	payload: TranslationPath;
}

export type TranslationEditActionTypes = SetTranslationEditAction;
