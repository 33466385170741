import { ExerciseDocument } from '../../../types';
import {
	ADD_EXERCISE,
	REMOVE_EXERCISE,
	UPDATE_EXERCISE,
	GET_EXERCISES,
	FETCH_EXERCISES,
	ExercisesActionTypes,
} from './constants';

const initialState: ExerciseDocument[] = [];

const exercises = (state = initialState, action: ExercisesActionTypes) => {
	switch (action.type) {
		case GET_EXERCISES:
			return action.payload;

		case FETCH_EXERCISES:
			const newState: ExerciseDocument[] = [];
			// update existing
			state.forEach(exercise => {
				const newExercise = action.payload.find(e => e._id === exercise._id);
				if (newExercise) {
					newState.push(newExercise);
				} else {
					newState.push(exercise);
				}
			});
			// add new
			action.payload
				.filter(q => newState.findIndex(s => s._id === q._id) === -1)
				.forEach(exercise => {
					newState.push(exercise);
				});
			return newState;

		case ADD_EXERCISE:
			return [...state, action.payload.newExercise];

		case REMOVE_EXERCISE:
			return state.filter(exercise => exercise._id !== action.payload.exerciseId);

		case UPDATE_EXERCISE:
			return state.map(exercise => {
				if (exercise._id === action.payload.newExercise._id) {
					return action.payload.newExercise;
				}
				return exercise;
			});

		default:
			return state;
	}
};

export default exercises;
