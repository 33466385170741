import { FETCH_EXAMS } from '../constants';
import { ExamDocument } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const fetchExams = (exams: ExamDocument[]): any => {
	return {
		type: FETCH_EXAMS,
		payload: exams,
	};
};

export default fetchExams;
