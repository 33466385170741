import { SET_SOCKET } from '../constants';
import { VotiSocket } from '../../../../types';

const setSocketAction = (socket: VotiSocket) => {
	return {
		type: SET_SOCKET,
		payload: socket,
	};
};

const setSocket = (socket: VotiSocket, ids?: string[]): any => {
	return (dispatch: any) => {
		return dispatch(setSocketAction(socket));
	};
};

export default setSocket;
