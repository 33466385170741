import { SAVE_EXAM_RESULT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { SaveExamResult, SaveExamResultResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const saveExamResultRequest = (data: SaveExamResult, token: string) => {
	return axios.post(`${reducerConfig.api}/exam-result/save`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const saveExamResultAction = (res: SaveExamResultResponse) => {
	return {
		type: SAVE_EXAM_RESULT,
		payload: res.data.examResult,
	};
};

const saveExamResult = (data: SaveExamResult, token: string): any => {
	return (dispatch: any) => {
		return saveExamResultRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(saveExamResultAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res;
		});
	};
};

export default saveExamResult;
