import { UPDATE_DEVICE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateDeviceActions, UpdateDevice, UpdateDeviceResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateDeviceRequest = (action: UpdateDeviceActions, data: UpdateDevice, token: string) => {
	return axios.post(`${reducerConfig.api}/device/${action}`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const updateDeviceAction = (res: UpdateDeviceResponse) => {
	return {
		type: UPDATE_DEVICE,
		payload: res.data,
	};
};

const updateDevice = (action: UpdateDeviceActions, data: UpdateDevice, token: string): any => {
	return (dispatch: any) => {
		return updateDeviceRequest(action, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateDeviceAction(res));
			}
			return res.data;
		});
	};
};

export default updateDevice;
