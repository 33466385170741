import { UPDATE_BOOK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';
import { BookChapterDocument } from '../../../../types';

export interface UpdateBookChapter {
	bookId: string;
	data: BookChapterDocument;
}

const updateBookChapterRequest = (data: UpdateBookChapter, token: string) => {
	return axios.post(`${reducerConfig.api}/book/update/chapter`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const updateBookChapterAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_BOOK,
		payload: res.data,
	};
};

const updateBookChapter = (data: UpdateBookChapter, token: string): any => {
	return (dispatch: any) => {
		return updateBookChapterRequest(data, token).then((res: AxiosResponse) =>
			dispatch(updateBookChapterAction(res))
		);
	};
};

export default updateBookChapter;
