import React, { ReactNode, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

// import { MessagesProvider } from '../../contexts/messagesContext';

import { AuthContextInterface, RoleTypesList, User, UserStates } from '../types';
import { common } from '../reducers';
import { useDispatch } from 'react-redux';
import { createContext } from 'react';

export const authContext = createContext<AuthContextInterface>({
	authenticated: false, // to check if authenticated or not
	user: {
		_id: '',
		email: '',
		phone: '',
		degree: '',
		firstName: '',
		lastName: '',
		role: RoleTypesList.unauthorized,
		token: '',
		state: 'active',
		isManager: false,
		projects: {
			voti: {
				sk: { active: false },
				cs: { active: false },
				ro: { active: false },
			},
			interaktiv: {
				sk: { active: false },
				cs: { active: false },
				ro: { active: false },
			},
			central: {
				sk: { active: false },
				cs: { active: false },
				ro: { active: false },
			},
			eshop: {
				sk: { active: false },
				cs: { active: false },
				ro: { active: false },
			},
		},
	}, // store all the user details
	token: '', // token of user for Auth0
	login: (email, password) =>
		new Promise(resolve => {
			resolve(undefined);
		}), // to start the login process
	loginAs: userId =>
		new Promise(resolve => {
			resolve(undefined);
		}), // to start the login process
	logout: () => {}, // logout the user
	silentAuth: () => {},
	setSession: user => {},
});

const cookie = new Cookies();

const userDefault = {
	_id: '',
	email: '',
	degree: '',
	firstName: '',
	lastName: '',
	phone: '',
	role: RoleTypesList.unauthorized,
	token: '',
	state: 'active' as UserStates,
	isManager: false,
	projects: {
		voti: {
			sk: { active: false },
			cs: { active: false },
			ro: { active: false },
		},
		interaktiv: {
			sk: { active: false },
			cs: { active: false },
			ro: { active: false },
		},
		central: {
			sk: { active: false },
			cs: { active: false },
			ro: { active: false },
		},
		eshop: {
			sk: { active: false },
			cs: { active: false },
			ro: { active: false },
		},
	},
};

export interface AuthProps {
	children: ReactNode;
}

const Auth = (props: AuthProps) => {
	const dispatch = useDispatch();
	const [state, setState] = useState<Pick<AuthContextInterface, 'authenticated' | 'user' | 'token'>>(
		{
			authenticated: false,
			user: userDefault,
			token: cookie.get('taktik_token'),
		}
	);

	const logout: AuthContextInterface['logout'] = () => {
		cookie.remove('taktik_token', { path: '/' });
		dispatch(common.user.actions.set(userDefault));
		setState({
			authenticated: false,
			token: '',
			user: {
				_id: '',
				email: '',
				firstName: '',
				lastName: '',
				role: RoleTypesList.unauthorized,
				degree: '',
				phone: '',
				isManager: false,
				state: 'active',
				token: '',
				projects: {
					voti: {
						sk: { active: false },
						cs: { active: false },
						ro: { active: false },
					},
					interaktiv: {
						sk: { active: false },
						cs: { active: false },
						ro: { active: false },
					},
					central: {
						sk: { active: false },
						cs: { active: false },
						ro: { active: false },
					},
					eshop: {
						sk: { active: false },
						cs: { active: false },
						ro: { active: false },
					},
				},
			},
		});
	};

	const setSession: AuthContextInterface['setSession'] = (data: User) => {
		if (data.token) {
			cookie.set('taktik_token', data.token, { path: '/' });
			setState({
				authenticated: true,
				token: data.token,
				user: data,
			});
			dispatch(common.user.actions.set(data));
		} else {
			logout();
		}
	};

	const login: AuthContextInterface['login'] = async (email, password, project, language) => {
		return axios
			.post(`${process.env.REACT_APP_API_CENTRAL}/user/login`, {
				email,
				password,
				project,
				language,
			})
			.then(res => {
				if (res.data.success) {
					setSession(res.data.user);
				}
				return res;
			});
	};

	const loginAs: AuthContextInterface['loginAs'] = async (userId: string) => {
		return axios
			.post(
				`${process.env.REACT_APP_API_CENTRAL}/user/login-as`,
				{
					userId,
				},
				{
					headers: {
						Authorization: `Bearer ${state.token}`,
					},
				}
			)
			.then(res => {
				if (res.data.success) {
					setSession(res.data.user);
				}
				return res;
			});
	};

	const silentAuth: AuthContextInterface['silentAuth'] = async () => {
		return await axios
			.get(`${process.env.REACT_APP_API_CENTRAL}/user/silent-auth`, {
				headers: {
					Authorization: `Bearer ${state.token}`,
				},
			})
			.then(
				res => {
					if (res.data) {
						setSession(res.data);
					}
				},
				err => {
					logout();
				}
			);
	};

	// useEffect(() => {
	// 	dispatch(common.user.actions.set(state.user));
	// }, [dispatch, state]);

	return (
		<authContext.Provider
			value={{
				...state,
				login,
				loginAs,
				silentAuth,
				setSession,
				logout,
			}}
		>
			{props.children}
		</authContext.Provider>
	);
};

export default Auth;
