import { GET_TAGS } from '../constants';
import axios from 'axios';
import { GetTagsResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getTagsRequest = (token: string, ids?: string[]) => {
	return axios.get(`${reducerConfig.api}/tag/get`, {
		params: { ids },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getTagsAction = (res: GetTagsResponse) => {
	return {
		type: GET_TAGS,
		payload: res.data,
	};
};

const getTags = (token: string, ids?: string[]): any => {
	return (dispatch: any) => {
		return getTagsRequest(token, ids).then((res: GetTagsResponse) => {
			dispatch(getTagsAction(res));
			return res.data;
		});
	};
};

export default getTags;
