import { GET_BOOK_PAGES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getBookPagesRequest = (bookId: string, token: string) => {
	return axios.get(`${reducerConfig.api}/book/pages/${bookId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getBookPagesAction = (res: GetUsersResponse) => {
	return {
		type: GET_BOOK_PAGES,
		payload: res.data.newBookPages,
	};
};

const getBookPages = (bookId: string, token: string): any => {
	return (dispatch: any) => {
		return getBookPagesRequest(bookId, token).then((res: AxiosResponse) =>
			dispatch(getBookPagesAction(res))
		);
	};
};

export default getBookPages;
