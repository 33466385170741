import { CONFIRM_REGISTRATION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { ConfirmInviteResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const confirmInviteRequest = (registrationId: string, token: string) => {
	return axios.post(`${reducerConfig.api}/registration/confirm/${registrationId}`, null, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const confirmInviteAction = (res: ConfirmInviteResponse) => {
	return {
		type: CONFIRM_REGISTRATION,
		payload: res.data,
	};
};

const confirmInvite = (registrationId: string, token: string): any => {
	return (dispatch: any) => {
		return confirmInviteRequest(registrationId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(confirmInviteAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newJob;
		});
	};
};

export default confirmInvite;
