import { ADD_QUESTION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { Question } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addQuestionRequest = (data: { question: Question; count: number }, token: string) => {
	return axios.put(`${reducerConfig.api}/question/add`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addQuestionAction = (res: AxiosResponse) => {
	return {
		type: ADD_QUESTION,
		payload: res.data,
	};
};

const addQuestion = (data: { question: Question; count: number }, token: string): any => {
	return (dispatch: any) => {
		return addQuestionRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addQuestionAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res;
		});
	};
};

export default addQuestion;
