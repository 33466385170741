import { RevisionItemDocument } from '../../../../types';

export const GET_REVISION_ITEMS = 'GET_REVISION_ITEMS';
export const UPDATE_REVISION_ITEMS = 'UPDATE_REVISION_ITEMS';

export interface GetRevisionItemsAction {
	type: typeof GET_REVISION_ITEMS;
	payload: RevisionItemDocument[];
}

export interface UpdateRevisionItemsAction {
	type: typeof UPDATE_REVISION_ITEMS;
	payload: RevisionItemDocument[];
}

export type RevisionActionTypes = GetRevisionItemsAction | UpdateRevisionItemsAction;
