import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useStyles from '../styles';
import clsx from 'clsx';

export interface LoaderProps {
	inline?: boolean;
}

const Loader = (props: LoaderProps) => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.loaderContainer, props.inline && 'inline')}>
			<CircularProgress />
		</div>
	);
};

export default Loader;
