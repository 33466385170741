import { REMOVE_SEGMENT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeSegmentRequest = (segmentId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/segment/remove/${segmentId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeSegmentAction = (res: AxiosResponse, segmentId: string) => {
	return {
		type: REMOVE_SEGMENT,
		payload: {
			segmentId,
		},
	};
};

const removeSegment = (segmentId: string, token: string): any => {
	return (dispatch: any) => {
		return removeSegmentRequest(segmentId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeSegmentAction(res, segmentId));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default removeSegment;
