import { GET_REVISIONS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { getRevisionItemsAction } from '../../revisionItems/actions/get';
import reducerConfig from '../../reducerConfig';

const getRevisionHistoryRequest = (questionId: string, token: string) => {
	return axios.get(`${reducerConfig.api}/revision/get-history`, {
		params: { questionId },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getRevisionHistoryAction = (res: AxiosResponse) => {
	return {
		type: GET_REVISIONS,
		payload: res.data.revisions,
	};
};

const getRevisionHistory = (questionId: string, token: string): any => {
	return (dispatch: any) => {
		return getRevisionHistoryRequest(questionId, token).then((res: AxiosResponse) => {
			dispatch(getRevisionHistoryAction(res));
			if (res.data.revisionItems) {
				dispatch(
					getRevisionItemsAction({
						...res,
						data: res.data.revisionItems,
					})
				);
			}
			return res.data.revisions;
		});
	};
};

export default getRevisionHistory;
