import { ADD_TAG } from '../constants';
import axios from 'axios';
import { AddTagResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addTagRequest = (title: string, token: string) => {
	return axios.put(
		`${reducerConfig.api}/tag/add`,
		{ title },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const addTagAction = (res: AddTagResponse) => {
	return {
		type: ADD_TAG,
		payload: res.data,
	};
};

const addTag = (title: string, token: string): any => {
	return (dispatch: any) => {
		return addTagRequest(title, token).then((res: AddTagResponse) => {
			if (res.data.success) {
				dispatch(addTagAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newTag;
		});
	};
};

export default addTag;
