import { REMOVE_DEVICES } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveDeviceResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeDeviceRequest = (deviceId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/device/remove`, {
		data: { deviceId },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeDeviceAction = (res: RemoveDeviceResponse, deviceId: string) => {
	return {
		type: REMOVE_DEVICES,
		payload: {
			deviceId,
		},
	};
};

const removeDevice = (deviceId: string, token: string): any => {
	return (dispatch: any) => {
		return removeDeviceRequest(deviceId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeDeviceAction(res, deviceId));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default removeDevice;
