import { TeacherHistory } from '../../../types';
import { GET_HISTORY, DeviceActionTypes } from './constants';

const initialState: TeacherHistory[] = [];

const History = (state = initialState, action: DeviceActionTypes) => {
	switch (action.type) {
		case GET_HISTORY:
			return action.payload.newHistory;

		default:
			return state;
	}
};

export default History;
