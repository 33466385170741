// COMMMON
import classrooms from './common/classrooms/';
import classroomsActions from './common/classrooms/actions';
import schools from './common/schools';
import schoolsActions from './common/schools/actions';
import schoolHelpers from './common/schools/helpers';
import subjects from './common/subjects';
import subjectsActions from './common/subjects/actions';
import user from './common/user';
import userActions from './common/user/actions';
import users from './common/users';
import usersActions from './common/users/actions';
import invites from './common/invites';
import invitesActions from './common/invites/actions';
import translations from './common/translations';
import translationsActions from './common/translations/actions';
import translationEdit from './common/translationEdit';
import translationEditActions from './common/translationEdit/actions';
import snackbars from './common/snackbars';
import snackbarsActions from './common/snackbars/actions';
import notifications from './common/notifications';
import notificationsActions from './common/notifications/actions';
import emailTemplates from './common/emailTemplates';
import emailTemplatesActions from './common/emailTemplates/actions';
import uploadActions from './common/upload/actions';

// INTERAKTIV
import books from './interaktiv/books';
import booksActions from './interaktiv/books/actions';
import bookPages from './interaktiv/bookPages';
import bookPagesActions from './interaktiv/bookPages/actions';
import bookCategories from './interaktiv/bookCategories';
import bookCategoriesActions from './interaktiv/bookCategories/actions';
import bookActive from './interaktiv/bookActive';
import bookActiveActions from './interaktiv/bookActive/actions';
import bookFeedback from './interaktiv/bookFeedbacks';
import bookFeedbackActions from './interaktiv/bookFeedbacks/actions';
import migrate from './interaktiv/migrate';
import migrateActions from './interaktiv/migrate/actions';
import vouchers from './interaktiv/vouchers';
import vouchersActions from './interaktiv/vouchers/actions';

// VOTI
import customExams from './voti/customExams';
import customExamsActions from './voti/customExams/actions';
import device from './voti/device';
import deviceActions from './voti/device/actions';
import devices from './voti/devices';
import devicesActions from './voti/devices/actions';
import elastic from './voti/elastic';
import elasticActions from './voti/elastic/actions';
import examResults from './voti/examResults';
import examResultsActions from './voti/examResults/actions';
import exams from './voti/exams';
import examsActions from './voti/exams/actions';
import exercises from './voti/exercises';
import exercisesActions from './voti/exercises/actions';
import guide from './voti/guide';
import guideActions from './voti/guide/actions';
import history from './voti/history';
import historyActions from './voti/history/actions';
import homeworks from './voti/homeworks';
import homeworksActions from './voti/homeworks/actions';
import questionReports from './voti/questionReports';
import questionReportsActions from './voti/questionReports/actions';
import questions from './voti/questions';
import questionsActions from './voti/questions/actions';
import questionTypes from './voti/questionTypes';
import questionTypesActions from './voti/questionTypes/actions';
import quotes from './voti/quotes';
import quotesActions from './voti/quotes/actions';
import registrations from './voti/registrations';
import registrationsActions from './voti/registrations/actions';
import repeatingItems from './voti/repeatingItems';
import repeatingItemsActions from './voti/repeatingItems/actions';
import repeatings from './voti/repeatings';
import repeatingsActions from './voti/repeatings/actions';
import revisionItems from './voti/revisionItems';
import revisionItemsActions from './voti/revisionItems/actions';
import revisions from './voti/revisions';
import revisionsActions from './voti/revisions/actions';
import segments from './voti/segments';
import segmentsActions from './voti/segments/actions';
import socket from './voti/socket';
import socketActions from './voti/socket/actions';
import stats from './voti/stats';
import statsActions from './voti/stats/actions';
import tags from './voti/tags';
import tagsActions from './voti/tags/actions';
import teachingPlans from './voti/teachingPlans';
import teachingPlansActions from './voti/teachingPlans/actions';
import usbDevices from './voti/usbDevices';
import usbDevicesActions from './voti/usbDevices/actions';
import pairing from './voti/pairing';
import pairingActions from './voti/pairing/actions';

export const common = {
	emailTemplates: {
		actions: emailTemplatesActions,
		reducer: emailTemplates,
	},
	classrooms: {
		actions: classroomsActions,
		reducer: classrooms,
	},
	schools: {
		actions: schoolsActions,
		reducer: schools,
		helpers: schoolHelpers,
	},
	subjects: {
		actions: subjectsActions,
		reducer: subjects,
	},
	users: {
		actions: usersActions,
		reducer: users,
	},
	user: {
		actions: userActions,
		reducer: user,
	},
	invites: {
		actions: invitesActions,
		reducer: invites,
	},
	translations: {
		actions: translationsActions,
		reducer: translations,
	},
	translationEdit: {
		actions: translationEditActions,
		reducer: translationEdit,
	},
	snackbars: {
		actions: snackbarsActions,
		reducer: snackbars,
	},
	notifications: {
		actions: notificationsActions,
		reducer: notifications,
	},
	upload: {
		actions: uploadActions,
	},
};

export const interaktiv = {
	books: {
		actions: booksActions,
		reducer: books,
	},
	bookPages: {
		actions: bookPagesActions,
		reducer: bookPages,
	},
	bookCategories: {
		actions: bookCategoriesActions,
		reducers: bookCategories,
	},
	bookActive: {
		actions: bookActiveActions,
		reducer: bookActive,
	},
	bookFeedback: {
		actions: bookFeedbackActions,
		reducer: bookFeedback,
	},
	vouchers: {
		actions: vouchersActions,
		reducer: vouchers,
	},
	migrate: {
		actions: migrateActions,
		reducer: migrate,
	},
};

export const voti = {
	customExams: {
		actions: customExamsActions,
		reducer: customExams,
	},
	device: {
		actions: deviceActions,
		reducer: device,
	},
	devices: {
		actions: devicesActions,
		reducer: devices,
	},
	elastic: {
		actions: elasticActions,
		reducer: elastic,
	},
	examResults: {
		actions: examResultsActions,
		reducer: examResults,
	},
	exams: {
		actions: examsActions,
		reducer: exams,
	},
	exercises: {
		actions: exercisesActions,
		reducer: exercises,
	},
	guide: {
		actions: guideActions,
		reducer: guide,
	},
	history: {
		actions: historyActions,
		reducer: history,
	},
	homeworks: {
		actions: homeworksActions,
		reducer: homeworks,
	},
	questionReports: {
		actions: questionReportsActions,
		reducer: questionReports,
	},
	questions: {
		actions: questionsActions,
		reducer: questions,
	},
	questionTypes: {
		actions: questionTypesActions,
		reducer: questionTypes,
	},
	quotes: {
		actions: quotesActions,
		reducer: quotes,
	},
	registrations: {
		actions: registrationsActions,
		reducer: registrations,
	},
	repeatingItems: {
		actions: repeatingItemsActions,
		reducer: repeatingItems,
	},
	repeatings: {
		actions: repeatingsActions,
		reducer: repeatings,
	},
	revisionItems: {
		actions: revisionItemsActions,
		reducer: revisionItems,
	},
	revisions: {
		actions: revisionsActions,
		reducer: revisions,
	},
	segments: {
		actions: segmentsActions,
		reducer: segments,
	},
	socket: {
		actions: socketActions,
		reducer: socket,
	},
	stats: {
		actions: statsActions,
		reducer: stats,
	},
	tags: {
		actions: tagsActions,
		reducer: tags,
	},
	teachingPlans: {
		actions: teachingPlansActions,
		reducer: teachingPlans,
	},
	usbDevices: {
		actions: usbDevicesActions,
		reducer: usbDevices,
	},
	pairing: {
		actions: pairingActions,
		reducer: pairing,
	},
};

const reducers = {
	common,
	interaktiv,
	voti,
};

export default reducers;
