import get from './get';
import add from './add';
import move from './move';
import getHistory from './getHistory';
import getPublic from './getPublic';
import update from './update';
import fetch from './fetch';

export default {
	get,
	getHistory,
	getPublic,
	fetch,
	add,
	move,
	update,
};
