import { SET_PAIRED } from '../constants';

const setSocketAction = (paired: string[]) => {
	return {
		type: SET_PAIRED,
		payload: paired,
	};
};

const setSocket = (paired: string[]): any => {
	return (dispatch: any) => {
		return dispatch(setSocketAction(paired));
	};
};

export default setSocket;
