import { GET_NOTIFICATIONS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getNotificationsRequest = (token: string) => {
	return axios.get(`${reducerConfig.api}/notification/get`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getNotificationsAction = (res: GetUsersResponse) => {
	return {
		type: GET_NOTIFICATIONS,
		payload: res.data.notifications,
	};
};

const getNotifications = (token: string): any => {
	return (dispatch: any) => {
		return getNotificationsRequest(token).then((res: AxiosResponse) => {
			dispatch(getNotificationsAction(res));
			return res;
		});
	};
};

export default getNotifications;
