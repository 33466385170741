import { UsbDevice, DeviceDocument } from '../../../../types';

export const GET_USB_DEVICES = 'GET_USB_DEVICES';
export const FETCH_USB_DEVICE_INFO = 'FETCH_USB_DEVICE_INFO';

export interface GetUsbDevicesAction {
	type: typeof GET_USB_DEVICES;
	payload: UsbDevice[];
}

export interface FetchUsbDeviceInfoAction {
	type: typeof FETCH_USB_DEVICE_INFO;
	payload: DeviceDocument;
}

export type UsbDevicesActionTypes = GetUsbDevicesAction | FetchUsbDeviceInfoAction;
