import { Socket } from 'socket.io-client';

export const SET_SOCKET = 'SET_SOCKET';

export interface SetSocketAction {
	type: typeof SET_SOCKET;
	payload: Socket;
}

export type RegActionTypes = SetSocketAction;
