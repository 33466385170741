import { ADD_REPEATING } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { Repeating } from '../../../../types';
import addRepeatingItems from '../../repeatingItems/actions/add';
import reducerConfig from '../../reducerConfig';

const addRepeatingRequest = (repeating: Repeating, schoolId: string, token: string) => {
	return axios.put(
		`${reducerConfig.api}/repeating/add`,
		{ repeating, schoolId },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const addRepeatingAction = (res: AxiosResponse) => {
	return {
		type: ADD_REPEATING,
		payload: res.data,
	};
};

const addRepeating = (repeating: Repeating, schoolId: string, token: string): any => {
	return (dispatch: any) => {
		return addRepeatingRequest(repeating, schoolId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addRepeatingAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newItems) {
				dispatch(addRepeatingItems(res.data.newItems));
			}
			return res.data;
		});
	};
};

export default addRepeating;
