import { EDIT_USER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { EditUserLicence, EditUserResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const editUserLicenceRequest = (data: EditUserLicence, token: string) => {
	return axios.post(`${reducerConfig.api}/user/edit-licence`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const editUserLicenceAction = (res: EditUserResponse) => {
	return {
		type: EDIT_USER,
		payload: res.data,
	};
};

const editUserLicence = (data: EditUserLicence, token: string): any => {
	return (dispatch: any) => {
		return editUserLicenceRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(editUserLicenceAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newUser;
		});
	};
};

export default editUserLicence;
