import { RepeatingDocument } from '../../../types';
import {
	ADD_REPEATING,
	GET_REPEATINGS,
	FETCH_REPEATINGS,
	UPDATE_REPEATING,
	RepeatingActionTypes,
} from './constants';

const initialState: RepeatingDocument[] = [];

const tags = (state = initialState, action: RepeatingActionTypes) => {
	switch (action.type) {
		case GET_REPEATINGS:
			return action.payload;

		case FETCH_REPEATINGS:
			return state.map(exam => {
				const newExam = action.payload.find(item => item._id === exam._id);
				if (newExam) {
					return newExam;
				}
				return exam;
			});

		case ADD_REPEATING:
			return [...state, action.payload.newRepeating];

		case UPDATE_REPEATING:
			return state.map(repeating => {
				if (repeating._id === action.payload.newRepeating._id) {
					return action.payload.newRepeating;
				}
				return repeating;
			});

		default:
			return state;
	}
};

export default tags;
