import { UPDATE_BOOK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const addBookChapterRequest = (bookId: string, token: string) => {
	return axios.put(
		`${reducerConfig.api}/book/add/chapter`,
		{ bookId },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const addBookChapterAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_BOOK,
		payload: res.data,
	};
};

const addBookChapter = (bookId: string, token: string): any => {
	return (dispatch: any) => {
		return addBookChapterRequest(bookId, token).then((res: AxiosResponse) => {
			dispatch(addBookChapterAction(res));
			return res;
		});
	};
};

export default addBookChapter;
