import get from './get';
import add from './add';
import update from './update';
import fetch from './fetch';

export default {
	get,
	fetch,
	add,
	update,
};
