import { User } from '../../../types';
import {
	ADD_USER,
	REMOVE_USER,
	EDIT_USER,
	GET_USERS,
	FETCH_USERS,
	SET_DEVICE,
	CHANGE_PASSWORD,
	UserActionTypes,
} from './constants';

const initialState: User[] = [];

const users = (state = initialState, action: UserActionTypes) => {
	switch (action.type) {
		case GET_USERS:
			return action.payload;

		case FETCH_USERS:
			const newState: User[] = [];
			// replace existing
			state.forEach(user => {
				const newUser = action.payload.find(u => u._id === user._id);
				if (newUser) {
					newState.push(newUser);
				} else {
					newState.push(user);
				}
			});
			// add new users
			action.payload
				.filter(u => newState.findIndex(s => s._id === u._id) === -1)
				.forEach(user => {
					newState.push(user);
				});
			return newState;

		case ADD_USER:
			return [...state, action.payload.newUser];

		case REMOVE_USER:
			return state.filter(user => user._id !== action.payload.userId);

		case EDIT_USER || SET_DEVICE:
			return state.map(user => {
				if (user._id === action.payload.newUser._id) {
					return action.payload.newUser;
				}
				return user;
			});

		case CHANGE_PASSWORD:
			// @TODO
			return state;

		default:
			return state;
	}
};

export default users;
