import { UPDATE_REVISION } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import fetchExams from '../../exams/actions/fetch';
import { UpdateRevision } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateRevisionRequest = (data: UpdateRevision, token: string) => {
	return axios.post(`${reducerConfig.api}/revision/move`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const updateRevisionAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_REVISION,
		payload: res.data,
	};
};

const updateRevision = (data: UpdateRevision, token: string): any => {
	return (dispatch: any) => {
		return updateRevisionRequest(data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateRevisionAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newExams) {
				dispatch(fetchExams(res.data.newExams));
			}
			return res;
		});
	};
};

export default updateRevision;
