import { REMOVE_QUESTION_TYPE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveQuestionTypeResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeQuestionTypeRequest = (questionTypeId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/question-type/remove/${questionTypeId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeQuestionTypeAction = (res: RemoveQuestionTypeResponse) => {
	return {
		type: REMOVE_QUESTION_TYPE,
		payload: res,
	};
};

const removeQuestionType = (questionTypeId: string, token: string): any => {
	return (dispatch: any) => {
		return removeQuestionTypeRequest(questionTypeId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeQuestionTypeAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default removeQuestionType;
