import { RevisionItemDocument } from '../../../types';
import { GET_REVISION_ITEMS, RevisionActionTypes, UPDATE_REVISION_ITEMS } from './constants';

const initialState: RevisionItemDocument[] = [];

const tags = (state = initialState, action: RevisionActionTypes) => {
	switch (action.type) {
		case GET_REVISION_ITEMS:
			return action.payload;
		case UPDATE_REVISION_ITEMS:
			return state.map(item => {
				const newItem = action.payload.find(p => p._id === item._id);
				if (newItem) {
					return newItem;
				}
				return item;
			});
		default:
			return state;
	}
};

export default tags;
