import { GET_CUSTOM_EXAMS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import fetchQuestions from '../../questions/actions/fetch';
import fetchExercises from '../../exercises/actions/fetch';
import reducerConfig from '../../reducerConfig';

const getCustomExamsRequest = (token: string) => {
	return axios.get(`${reducerConfig.api}/custom-exam/get`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getCustomExamsAction = (res: GetUsersResponse) => {
	return {
		type: GET_CUSTOM_EXAMS,
		payload: res.data,
	};
};

const getCustomExams = (token: string): any => {
	return (dispatch: any) => {
		return getCustomExamsRequest(token).then((res: AxiosResponse) => {
			if (res.data.questions) {
				dispatch(fetchQuestions(res.data.questions));
			}
			if (res.data.exercises) {
				dispatch(fetchExercises(res.data.exercises));
			}
			dispatch(getCustomExamsAction(res));
		});
	};
};

export default getCustomExams;
