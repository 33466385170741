import { GET_USB_DEVICES } from '../constants';
import { UsbDevice } from '../../../../types';

export const getDevicesAction = (devices: UsbDevice[]) => {
	return {
		type: GET_USB_DEVICES,
		payload: devices,
	};
};

export default getDevicesAction;
