import { BookDocument } from '../../../types';
import { GET_BOOKS, ADD_BOOK, UPDATE_BOOK, BookActionTypes } from './constants';

const initialState: BookDocument[] = [];

const books = (state = initialState, action: BookActionTypes) => {
	switch (action.type) {
		case GET_BOOKS:
			return action.payload;

		case ADD_BOOK:
			return [...state, action.payload];

		case UPDATE_BOOK:
			return state.map(book => {
				if (book._id === action.payload.newBook._id) {
					return {
						...book,
						...action.payload.newBook,
					};
				}
				return book;
			});

		default:
			return state;
	}
};

export default books;
