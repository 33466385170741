import { REMOVE_TEACHING_PLAN } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveTeachingPlanResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeTeachingPlanRequest = (teachingPlanId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/teaching-plan/remove`, {
		data: {
			teachingPlanId,
		},
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeTeachingPlanAction = (res: RemoveTeachingPlanResponse, teachingPlanId: string) => {
	return {
		type: REMOVE_TEACHING_PLAN,
		payload: {
			teachingPlanId,
		},
	};
};

const removeTeachingPlan = (teachingPlanId: string, token: string): any => {
	return (dispatch: any) => {
		return removeTeachingPlanRequest(teachingPlanId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeTeachingPlanAction(res, teachingPlanId));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default removeTeachingPlan;
