import { ExamDocument, ExamResultDocument } from '../../../../types';

export const SAVE_EXAM_RESULT = 'SAVE_EXAM_RESULT';
export const GET_EXAM_RESULTS = 'GET_EXAM_RESULTS';

export interface SaveExamAction {
	type: typeof SAVE_EXAM_RESULT;
	payload: {
		newExam: ExamDocument;
	};
}

export interface GetExamAction {
	type: typeof GET_EXAM_RESULTS;
	payload: ExamResultDocument[];
}

export type ExamActionTypes = SaveExamAction | GetExamAction;
