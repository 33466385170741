import { Migration } from '../../../types';
import { GET_MIGRATION_TABLES, UPDATE_MIGRATION, MigrationActionTypes } from './constants';

const initialState: Migration[] = [];

const users = (state = initialState, action: MigrationActionTypes) => {
	switch (action.type) {
		case GET_MIGRATION_TABLES:
			return action.payload;

		case UPDATE_MIGRATION:
			return state.map(table => {
				if (table.action === action.payload.action) {
					return {
						...table,
						groups: action.payload.groups,
						state: action.payload.state,
						skip: action.payload.skip,
						limit: action.payload.limit,
						count: action.payload.count,
					};
				}
				return table;
			});

		default:
			return state;
	}
};

export default users;
