import { VoucherDocument } from '../../../../types';

export const GET_VOUCHERS = 'GET_VOUCHERS';
export const VERIFY_VOUCHER = 'VERIFY_VOUCHER';
export const GENERATE_VOUCHER = 'GENERATE_VOUCHER';
export const UPDATE_VOUCHER = 'UPDATE_VOUCHER';

export interface GetVouchersAction {
	type: typeof GET_VOUCHERS;
	payload: {
		success: boolean;
		vouchers: VoucherDocument[];
	};
}

export interface GenerateVoucherAction {
	type: typeof GENERATE_VOUCHER;
	payload: {
		success: boolean;
		newVoucher: VoucherDocument;
	};
}

export interface UpdateVoucherAction {
	type: typeof UPDATE_VOUCHER;
	payload: {
		success: boolean;
		newVoucher: VoucherDocument;
	};
}

export type VoucherActionTypes = GetVouchersAction | GenerateVoucherAction | UpdateVoucherAction;
