import get from './get';
import add from './add';
import addBook from './addBook';
import remove from './remove';
import removeBook from './removeBook';
import update from './update';

export default {
	get,
	add,
	addBook,
	remove,
	removeBook,
	update,
};
