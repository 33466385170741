import { START_GUIDE, CLOSE_GUIDE, GuideActionTypes } from './constants';
import { GuideState } from '../../../types';

const initialState: GuideState = {
	active: false,
	steps: [],
	options: {},
};

const guide = (state = initialState, action: GuideActionTypes) => {
	switch (action.type) {
		case START_GUIDE:
			return action.payload;

		case CLOSE_GUIDE:
			return initialState;

		default:
			return state;
	}
};

export default guide;
