import { GET_TRANSLATIONS } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { LanguagesList } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getTranslationsRequest = () => {
	const promises = [LanguagesList.cs, LanguagesList.sk].map(language => {
		return axios.get(`${reducerConfig.translations}/translations/${language}/common.json`);
	});

	return Promise.all(promises);
};

const getTranslationsAction = (res: AxiosResponse[]) => {
	const payload = res.map(language => {
		if (language.config.url?.endsWith('/cs/common.json')) {
			return {
				lang: 'cs',
				data: language.data,
			};
		}
		return {
			lang: 'sk',
			data: language.data,
		};
	});

	return {
		type: GET_TRANSLATIONS,
		payload,
	};
};

const getTranslations = (): any => {
	return (dispatch: any) => {
		return getTranslationsRequest().then((res: AxiosResponse[]) => {
			dispatch(getTranslationsAction(res));
			return res;
		});
	};
};

export default getTranslations;
