import { BookFeedbackDocument } from '../../../types';
import { GET_BOOK_FEEDBACK, ADD_BOOK_FEEDBACK, BookFeedbackActionTypes } from './constants';

const initialState: BookFeedbackDocument[] = [];

const bookFeedbacks = (state = initialState, action: BookFeedbackActionTypes) => {
	switch (action.type) {
		case GET_BOOK_FEEDBACK:
			return action.payload;

		case ADD_BOOK_FEEDBACK:
			if (!action.payload.success) {
				return state;
			}
			return [...state, action.payload.newBookFeedback];

		default:
			return state;
	}
};

export default bookFeedbacks;
