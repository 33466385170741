import { EDIT_USER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { RemoveUserResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const setUserManagerRequest = (userId: string, isManager: boolean, token: string) => {
	return axios.post(
		`${reducerConfig.api}/user/set-manager`,
		{
			userId,
			isManager,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const setUserManagerAction = (res: RemoveUserResponse) => {
	return {
		type: EDIT_USER,
		payload: res.data,
	};
};

const setUserManager = (userId: string, isManager: boolean, token: string): any => {
	return (dispatch: any) => {
		return setUserManagerRequest(userId, isManager, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(setUserManagerAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default setUserManager;
