import { UPDATE_SEGMENT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const updateCountRequest = (segmentId: string, count: number, token: string) => {
	return axios.post(
		`${reducerConfig.api}/segment/update/required-count`,
		{
			segmentId,
			data: {
				count,
			},
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateCountAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_SEGMENT,
		payload: res.data.newSegment,
	};
};

const updateCount = (segmentId: string, count: number, token: string): any => {
	return (dispatch: any) => {
		return updateCountRequest(segmentId, count, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateCountAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newSegments;
		});
	};
};

export default updateCount;
