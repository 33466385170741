import { UPDATE_BOOK } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateBookResponse, UpdateBook } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateBookRequest = (data: UpdateBook, token: string) => {
	return axios.post(`${reducerConfig.api}/book/update`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const updateBookAction = (res: UpdateBookResponse) => {
	return {
		type: UPDATE_BOOK,
		payload: res.data,
	};
};

const updateBook = (data: UpdateBook, token: string): any => {
	return (dispatch: any) => {
		return updateBookRequest(data, token).then((res: AxiosResponse) =>
			dispatch(updateBookAction(res))
		);
	};
};

export default updateBook;
