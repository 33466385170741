import { QuoteDocument } from '../../../types';
import { ADD_QUOTE, GET_QUOTES, UPDATE_QUOTE, REMOVE_QUOTE, QuoteActionTypes } from './constants';

const initialState: QuoteDocument[] = [];

const tags = (state = initialState, action: QuoteActionTypes) => {
	switch (action.type) {
		case GET_QUOTES:
			return action.payload;

		case ADD_QUOTE:
			return [...state, action.payload.newQuote];

		case UPDATE_QUOTE:
			return state.map(item => {
				if (item._id === action.payload.newQuote._id) {
					return action.payload.newQuote;
				}
				return item;
			});

		case REMOVE_QUOTE:
			return state.filter(s => s._id !== action.payload.quoteId);

		default:
			return state;
	}
};

export default tags;
