import { UPDATE_SEGMENT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeSegmentDependencyRequest = (segmentId: string, dependencyId: string, token: string) => {
	return axios.post(
		`${reducerConfig.api}/segment/remove/dependency`,
		{
			segmentId,
			data: {
				dependencyId,
			},
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const removeSegmentDependencyAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_SEGMENT,
		payload: res.data.newSegment,
	};
};

const removeSegmentDependency = (segmentId: string, dependencyId: string, token: string): any => {
	return (dispatch: any) => {
		return removeSegmentDependencyRequest(segmentId, dependencyId, token).then(
			(res: AxiosResponse) => {
				if (res.data.success) {
					dispatch(removeSegmentDependencyAction(res));
				}
				if (res.data.snackbars) {
					dispatch(addSnackbar(res.data.snackbars));
				}
				return res.data.newSegment;
			}
		);
	};
};

export default removeSegmentDependency;
