import { NotificationDocument } from '../../../../types';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

export interface GetNotificationsAction {
	type: typeof GET_NOTIFICATIONS;
	payload: NotificationDocument[];
}

export interface UpdateNotificationAction {
	type: typeof UPDATE_NOTIFICATION;
	payload: {
		newNotifications: NotificationDocument[];
	};
}

export type SnackbarActionTypes = GetNotificationsAction | UpdateNotificationAction;
