import { RepeatingDocument } from '../../../../types';

export const ADD_REPEATING = 'ADD_REPEATING';
export const GET_REPEATINGS = 'GET_REPEATINGS';
export const UPDATE_REPEATING = 'UPDATE_REPEATING';
export const FETCH_REPEATINGS = 'FETCH_REPEATINGS';

export interface AddRepeatingAction {
	type: typeof ADD_REPEATING;
	payload: {
		newRepeating: RepeatingDocument;
	};
}

export interface GetRepeatingsAction {
	type: typeof GET_REPEATINGS;
	payload: RepeatingDocument[];
}

export interface UpdateRepeatingAction {
	type: typeof UPDATE_REPEATING;
	payload: {
		newRepeating: RepeatingDocument;
	};
}

export interface FetchExamsAction {
	type: typeof FETCH_REPEATINGS;
	payload: RepeatingDocument[];
}

export type RepeatingActionTypes =
	| AddRepeatingAction
	| GetRepeatingsAction
	| UpdateRepeatingAction
	| FetchExamsAction;
