import { GuideState } from '../../../../types';

export const START_GUIDE = 'START_GUIDE';
export const CLOSE_GUIDE = 'CLOSE_GUIDE';

export interface StartGuideAction {
	type: typeof START_GUIDE;
	payload: GuideState;
}

export interface CloseGuideAction {
	type: typeof CLOSE_GUIDE;
}

export type GuideActionTypes = StartGuideAction | CloseGuideAction;
