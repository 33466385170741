import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useLocation } from 'react-router';
// import makeStyles from '@mui/styles/makeStyles';
// import { Types } from '@taktik/common';
// import Cookies from 'universal-cookie';
// import { useEffect, useState } from 'react';

// import translationsCommonCS from '../../translations/cs/common.json';
// import translationsCommonSK from '../../translations/sk/common.json';
// import translationsQuizCS from '../../translations/cs/quiz.json';
// import translationsQuizSK from '../../translations/sk/quiz.json';

// const cookie = new Cookies();

// const useStyles = makeStyles({
// 	translationWrapper: {
// 		position: 'relative',
// 		zIndex: 999,
// 	},
// 	translationBox: {
// 		display: 'block',
// 		textTransform: 'none',
// 		position: 'absolute',
// 		color: '#fff',
// 		right: -24,
// 		top: 'calc(50% - 8px)',
// 		width: 16,
// 		height: 16,
// 		background: '#1976d2',
// 		fontSize: 12,
// 		lineHeight: '16px',
// 		zIndex: 9999,
// 		cursor: 'pointer',
// 		opacity: 0.7,
// 		border: `1px solid #fff`,
// 		boxShadow: `0 3px 6px rgba(0,0,0,.2)`,
// 		textAlign: 'center',
// 		'&:hover': {
// 			background: '#115293',
// 		},
// 	},
// });

// const translations: Types.Translation[] = [
// 	{
// 		lang: 'cs',
// 		data: translationsCommonCS,
// 	},
// 	{
// 		lang: 'sk',
// 		data: translationsCommonSK,
// 	},
// ];

export interface TProps {
	// translations: Types.TranslationDocument[];
	// translationEdit: Types.TranslationPath | null;
	// onTranslationEdit: (path: Types.TranslationPath) => void;
	id: string;
	options?: { [key: string]: any };
	// user: Types.User;
	plural?: boolean;
}

const T = (props: TProps) => {
	const { t } = useTranslation('common');
	// const location = useLocation();
	// const [isDev, setIsDev] = useState(false);
	// const classes = useStyles();
	const plural = props.plural;
	const options = props.options;
	const count = options?.count;
	const translation = plural
		? count === 1
			? t(`${props.id}.one`, options)
			: count < 5 && count !== 0
			? t(`${props.id}.more`, options)
			: t(`${props.id}.many`, options)
		: t(props.id, options);

	// useEffect(() => {
	// 	if (props.user.role === Types.RoleTypesList.admin) {
	// 		if (location.search === '?no-t') {
	// 			setIsDev(false);
	// 			cookie.set('taktik_translation_mode', false, { path: '/' });
	// 		} else if (location.search === '?t') {
	// 			setIsDev(true);
	// 			cookie.set('taktik_translation_mode', true, { path: '/' });
	// 		} else {
	// 			setIsDev(cookie.get('taktik_translation_mode') === 'true' && props.translationEdit === null);
	// 		}
	// 	}
	// }, [location, props.translationEdit, props.user]);

	return <>{translation}</>;

	// return isDev ? (
	// 	<>
	// 		<span className={classes.translationWrapper}>
	// 			<span
	// 				className={classes.translationBox}
	// 				onClick={event => {
	// 					event.preventDefault();
	// 					event.stopPropagation();
	// 					const values: Types.TranslationValue[] = [];
	// 					props.translations.forEach(translation => {
	// 						if (plural) {
	// 							const valueOne = Helpers.objectGetValue(translation.data, `${props.id}.one`);
	// 							values.push({
	// 								lang: translation.lang,
	// 								pluralMode: 'one',
	// 								value: valueOne,
	// 							});

	// 							const valueMany = Helpers.objectGetValue(translation.data, `${props.id}.many`);
	// 							values.push({
	// 								lang: translation.lang,
	// 								pluralMode: 'many',
	// 								value: valueMany,
	// 							});

	// 							const valueMore = Helpers.objectGetValue(translation.data, `${props.id}.more`);
	// 							values.push({
	// 								lang: translation.lang,
	// 								pluralMode: 'more',
	// 								value: valueMore,
	// 							});
	// 						} else {
	// 							const value = Helpers.objectGetValue(translation.data, props.id);
	// 							if (typeof value === 'string') {
	// 								values.push({
	// 									lang: translation.lang,
	// 									value,
	// 								});
	// 							}
	// 						}
	// 					});
	// 					const newPath: Types.TranslationPath = {
	// 						path: props.id,
	// 						values,
	// 						plural,
	// 					};
	// 					props.onTranslationEdit(newPath);
	// 				}}
	// 			>
	// 				T
	// 			</span>
	// 			{translation}
	// 		</span>
	// 	</>
	// ) : (
	// 	<>{translation}</>
	// );
};

export default T;
