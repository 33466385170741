import { GET_EMAIL_TEMPLATES } from '../constants';
import axios from 'axios';
import { GetTagsResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getEmailTemplatesRequest = (token: string, ids?: string[]) => {
	return axios.get(`${reducerConfig.api}/email-template/get`, {
		params: { ids },
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getEmailTemplatesAction = (res: GetTagsResponse) => {
	return {
		type: GET_EMAIL_TEMPLATES,
		payload: res.data,
	};
};

const getEmailTemplates = (token: string, ids?: string[]): any => {
	return (dispatch: any) => {
		return getEmailTemplatesRequest(token, ids).then((res: GetTagsResponse) => {
			dispatch(getEmailTemplatesAction(res));
			return res.data;
		});
	};
};

export default getEmailTemplates;
