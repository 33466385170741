import { UPDATE_EXAM } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UpdateExamActions, UpdateExam, UpdateExamResponse } from '../../../../types';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const updateExamRequest = (action: UpdateExamActions, data: UpdateExam, token: string) => {
	const uri = `${reducerConfig.api}/exam/${action}`;
	const options = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	if (action.startsWith('add')) {
		return axios.put(uri, data, options);
	} else if (action.startsWith('remove')) {
		return axios.delete(uri, { ...options, data });
	}
	return axios.post(uri, data, options);
};

const updateExamAction = (res: UpdateExamResponse) => {
	return {
		type: UPDATE_EXAM,
		payload: res.data,
	};
};

const updateExam = (action: UpdateExamActions, data: UpdateExam, token: string): any => {
	return (dispatch: any) => {
		return updateExamRequest(action, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateExamAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data;
		});
	};
};

export default updateExam;
