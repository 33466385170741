import { UPDATE_DEVICE } from '../constants';
import { UserAnswersState, DeviceDocument } from '../../../../types';

export interface updateUserStateProps {
	userId: number;
	state: UserAnswersState;
	device: DeviceDocument;
}

const updateUserState = (props: updateUserStateProps): any => {
	const device = props.device;
	const cuProps = device.cuProps;

	if (cuProps) {
		const answer = cuProps.answers.find(a => a.keyId === props.userId);
		if (answer) {
			answer.state = props.state;
		}
	}

	return {
		type: UPDATE_DEVICE,
		payload: {
			newDevice: device,
		},
	};
};

export default updateUserState;
