import { EDIT_USER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

type UpdateLicenceData = {
	bookIds: string[];
	licenceAction: 'extendBy' | 'setTo';
	value: number | Date;
};

const updateLicencesRequest = (userId: string, data: UpdateLicenceData, token: string) => {
	return axios.post(
		`${reducerConfig.api}/user/update-licences`,
		{
			userId,
			data,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateLicencesAction = (res: AxiosResponse) => {
	return {
		type: EDIT_USER,
		payload: res.data,
	};
};

const updateLicences = (userId: string, data: UpdateLicenceData, token: string): any => {
	return (dispatch: any) => {
		return updateLicencesRequest(userId, data, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateLicencesAction(res));
			}
			return res;
		});
	};
};

export default updateLicences;
