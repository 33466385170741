import { RuleType } from '../../types';

const rules: RuleType = {
	static: [
		// Dashboard
		'admin:dashboard',

		// Profile
		'admin:profile',

		// User
		'admin:users', // Users list

		// Subjects
		'admin:subjects:get', // Get subjects

		// Exam
		'voti:exams', // exams list
		'voti:exams:update',
		'voti:exams:update:question', // Update exam question

		// Revision
		'voti:revisions:add',
		'voti:revisions:get',

		// Question
		'voti:questionTypes:get', // Get questionTypes

		// Segment
		'voti:segments:get',

		// Tags
		'voti:tags:get',

		// School
		'admin:schools', // Schools list

		// Classroom
		'admin:classrooms',

		// Books
		'interaktiv:books:get',
		'interaktiv:books:add',
		'interaktiv:books:update',

		// BookFeedbacks
		'interaktiv:bookFeedback:get',
		'interaktiv:bookFeedback:add',
		'interaktiv:bookFeedback:update',
	],
};

export default rules;
