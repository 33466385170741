import { EDIT_USER } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { UserStates, RemoveUserResponse } from '../../../../types';
import addSnackbar from '../../snackbars/actions/add';
import reducerConfig from '../../reducerConfig';
import setUserSessionAction from '../../user/actions/set';

const setUserStateRequest = (state: UserStates, token: string) => {
	return axios.post(
		`${reducerConfig.api}/user/set-state`,
		{
			state,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const setUserStateAction = (res: RemoveUserResponse) => {
	return {
		type: EDIT_USER,
		payload: res.data,
	};
};

const setUserState = (state: UserStates, token: string): any => {
	return (dispatch: any) => {
		return setUserStateRequest(state, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(setUserStateAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			if (res.data.newUser) {
				dispatch(setUserSessionAction(res.data.newUser));
			}
		});
	};
};

export default setUserState;
