import { GET_HISTORY } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const getHistoryRequest = (token: string, filter?: { subjectId: string; classroomId: string }) => {
	return axios.get(`${reducerConfig.api}/history/get`, {
		params: filter,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getHistoryAction = (res: GetUsersResponse) => {
	return {
		type: GET_HISTORY,
		payload: res.data,
	};
};

const getHistory = (token: string, filter?: { subjectId: string; classroomId: string }): any => {
	return (dispatch: any) => {
		return getHistoryRequest(token, filter).then((res: AxiosResponse) =>
			dispatch(getHistoryAction(res))
		);
	};
};

export default getHistory;
