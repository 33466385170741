import { RuleType } from '../../types';

const rules: RuleType = {
	static: [
		// Login As
		'admin:loginAs',

		// Dashboard
		'admin:dashboard',

		// Profile
		'admin:profile',

		// User
		'admin:users', // Users list
		'admin:users:add', // Add user
		'admin:users:edit', // Edit user
		'admin:users:remove', // Remove user
		// as Teacher
		'admin:users:addSubject',
		'admin:users:removeSubject',
		'admin:users:addLicence',
		'admin:users:editLicence',
		'admin:users:setDevice',
		'admin:users:getBySchool',
		'admin:users:addClassroom',
		'admin:users:removeClassroom',

		// Classrooms
		'admin:classrooms',
		'admin:classrooms:add',
		'admin:classrooms:remove',
		'admin:classrooms:update',
		'admin:classrooms:update:timetable',
		'admin:classrooms:update:teachingPlan',
		'admin:classrooms:update:groups',

		// Invite
		'admin:invite:add',

		// Subject
		'admin:subjects', // Subjects list
		'admin:subjects:add', // Add subject
		'admin:subjects:edit', // Edit subject
		'admin:subjects:get', // Get subjects

		// School
		'admin:schools', // Schools list
		'admin:schools:add', // Add school
		'admin:schools:remove', // Remove school
		'admin:schools:update', // Update school
		'admin:schools:update:addTeacher', // Add teacher

		// EmailTemplate
		'admin:emailTemplate',
		'admin:emailTemplate:get',
		'admin:emailTemplate:add',
		'admin:emailTemplate:update',
		'admin:emailTemplate:remove',

		// Exam
		'voti:exams', // exams list
		'voti:exams:add', // Add exam
		'voti:exams:update', // Update exam
		'voti:exams:update:layout', // Update exam layout
		'voti:exams:update:question', // Update exam question
		'voti:exams:remove', // Remove exam

		// Teaching plan
		'voti:teachingPlans', // Teaching plan list
		'voti:teachingPlans:add', // Add teaching plan
		'voti:teachingPlans:update', // Update teaching plan
		'voti:teachingPlans:get', // Get teachingPlans

		// Devices
		'voti:devices',
		'voti:devices:add',
		'voti:devices:remove',
		'voti:devices:update',

		// Segment
		'voti:segments',
		'voti:segments:get',
		'voti:segments:add',
		'voti:segments:update',
		'voti:segments:remove',

		// Revision
		'voti:revisions:add',
		'voti:revisions:get',
		'voti:revisions:remove',

		// Tag
		'voti:tags:get',
		'voti:tags:add',
		'voti:tags:remove',

		// Quote
		'voti:quotes:get',
		'voti:quotes:add',
		'voti:quotes:remove',

		// QuestionType
		'voti:questionTypes', // Question types list
		'voti:questionTypes:get', // Get question types
		'voti:questionTypes:add', // Add question type
		'voti:questionTypes:edit', // Edit question type
		'voti:questionTypes:remove', // Remove question type

		// Translations
		'admin:translations',

		// Registrations
		'voti:registrations',
		'voti:registrations:confirm',
		'voti:registrations:reject',

		// Repeating
		'voti:repeating:add',
		'voti:repeating:get',
		'voti:repeating:update',

		// Homeworks
		'voti:homeworks:add',
		'voti:homeworks:get',
		'voti:homeworks:update',

		// History
		'voti:history:get',

		// Devices
		'voti:device',

		// QuestionReport
		'voti:questionReport:add',
		'voti:questionReport:get',
		'voti:questionReport:resolve',
		'voti:questionReport:reject',

		// Books
		'interaktiv:books:get',
		'interaktiv:books:add',
		'interaktiv:books:update',

		// BookCategories
		'interaktiv:bookCategory:get',
		'interaktiv:bookCategory:add',
		'interaktiv:bookCategory:update',
		'interaktiv:bookCategory:remove',

		// BookFeedbacks
		'interaktiv:bookFeedback:get',
		'interaktiv:bookFeedback:add',
		'interaktiv:bookFeedback:update',

		// Vouchers
		'interaktiv:voucher:get',
		'interaktiv:voucher:generate',
		'interaktiv:voucher:update',
	],
};

export default rules;
