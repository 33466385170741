import { VotiSocket } from '../../../types';
import { SET_SOCKET, RegActionTypes } from './constants';

const initialState: VotiSocket = null;

const socket = (state = initialState, action: RegActionTypes) => {
	switch (action.type) {
		case SET_SOCKET:
			return action.payload;

		default:
			return state;
	}
};

export default socket;
