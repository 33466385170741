import { ExamDocument } from '../../../types';
import {
	ADD_EXAM,
	REMOVE_EXAM,
	UPDATE_EXAM,
	GET_EXAMS,
	FETCH_EXAMS,
	ExamActionTypes,
} from './constants';

const initialState: ExamDocument[] = [];

const exams = (state = initialState, action: ExamActionTypes) => {
	switch (action.type) {
		case GET_EXAMS:
			return action.payload.exams;

		case FETCH_EXAMS:
			return state.map(exam => {
				const newExam = action.payload.find(item => item._id === exam._id);
				if (newExam) {
					return newExam;
				}
				return exam;
			});

		case ADD_EXAM:
			return [...state, action.payload.newExam];

		case REMOVE_EXAM:
			return state.filter(exam => exam._id !== action.payload.examId);

		case UPDATE_EXAM:
			return state.map(exam => {
				if (exam._id === action.payload.newExam._id) {
					return {
						...exam,
						...action.payload.newExam,
					};
				}
				return exam;
			});

		default:
			return state;
	}
};

export default exams;
