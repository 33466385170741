import { ELASTICSEARCH, ElasticActionTypes } from './constants';

const initialState: any[] = [];

const elastic = (state = initialState, action: ElasticActionTypes) => {
	switch (action.type) {
		case ELASTICSEARCH:
			return action.payload;

		default:
			return state;
	}
};

export default elastic;
