import { ADD_CUSTOM_EXAM } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { CustomExam, GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const addCustomExamsRequest = (customExam: CustomExam, token: string) => {
	return axios.put(`${reducerConfig.api}/custom-exam/add`, customExam, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const addCustomExamsAction = (res: GetUsersResponse) => {
	return {
		type: ADD_CUSTOM_EXAM,
		payload: res.data,
	};
};

const addCustomExams = (customExam: CustomExam, token: string): any => {
	return (dispatch: any) => {
		return addCustomExamsRequest(customExam, token).then((res: AxiosResponse) => {
			dispatch(addCustomExamsAction(res));
			return res;
		});
	};
};

export default addCustomExams;
