import { User } from '../../../../types';

export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_USERS = 'GET_USERS';
export const FETCH_USERS = 'FETCH_USERS';
export const SET_DEVICE = 'SET_DEVICE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export interface AddUserAction {
	type: typeof ADD_USER;
	payload: {
		newUser: User;
	};
}

export interface RemoveUserAction {
	type: typeof REMOVE_USER;
	payload: {
		userId: string;
		token: string;
	};
}

export interface EditUserAction {
	type: typeof EDIT_USER;
	payload: {
		userId: string;
		newUser: User;
		token: string;
	};
}

export interface GetUsersAction {
	type: typeof GET_USERS;
	payload: User[];
}

export interface FetchUsersAction {
	type: typeof FETCH_USERS;
	payload: User[];
}

export interface ChangePasswordAction {
	type: typeof CHANGE_PASSWORD;
}

export interface SetDeviceAction {
	type: typeof SET_DEVICE;
	payload: {
		newUser: User;
	};
}

export type UserActionTypes =
	| AddUserAction
	| RemoveUserAction
	| EditUserAction
	| GetUsersAction
	| FetchUsersAction
	| ChangePasswordAction
	| SetDeviceAction;
