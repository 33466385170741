import { UPDATE_SEGMENT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const updateSubjectRequest = (segmentId: string, subjectId: string, token: string) => {
	return axios.post(
		`${reducerConfig.api}/segment/update/subject`,
		{
			segmentId,
			data: {
				subjectId,
			},
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateSubjectAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_SEGMENT,
		payload: res.data.newSegment,
	};
};

const updateSubject = (segmentId: string, subjectId: string, token: string): any => {
	return (dispatch: any) => {
		return updateSubjectRequest(segmentId, subjectId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateSubjectAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newSegments;
		});
	};
};

export default updateSubject;
