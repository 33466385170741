import { UPDATE_BOOK_CATEGORY } from '../constants';
import axios, { AxiosResponse } from 'axios';
import reducerConfig from '../../reducerConfig';

const addBookCategoryBookRequest = (categoryId: string, bookId: string, token: string) => {
	return axios.put(
		`${reducerConfig.api}/book-category/add-book`,
		{
			categoryId,
			bookId,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

export const addBookCategoryBookAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_BOOK_CATEGORY,
		payload: res.data,
	};
};

const addBookCategoryBook = (categoryId: string, bookId: string, token: string): any => {
	return (dispatch: any) => {
		return addBookCategoryBookRequest(categoryId, bookId, token).then((res: AxiosResponse) => {
			dispatch(addBookCategoryBookAction(res));
			return res;
		});
	};
};

export default addBookCategoryBook;
