import { REMOVE_CUSTOM_EXAM } from '../constants';
import axios, { AxiosResponse } from 'axios';
import { GetUsersResponse } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const removeCustomExamRequest = (customExamId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/custom-exam/remove/${customExamId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeCustomExamAction = (res: GetUsersResponse) => {
	return {
		type: REMOVE_CUSTOM_EXAM,
		payload: res.data,
	};
};

const removeCustomExam = (customExamId: string, token: string): any => {
	return (dispatch: any) => {
		return removeCustomExamRequest(customExamId, token).then((res: AxiosResponse) =>
			dispatch(removeCustomExamAction(res))
		);
	};
};

export default removeCustomExam;
