import { RegistrationDocument } from '../../../../types';

export const ADD_REGISTRATION = 'ADD_REGISTRATION';
export const CONFIRM_REGISTRATION = 'CONFIRM_REGISTRATION';
export const REJECT_REGISTRATION = 'REJECT_REGISTRATION';
export const GET_REGISTRATIONS = 'GET_REGISTRATIONS';

export interface AddRegistrationAction {
	type: typeof ADD_REGISTRATION;
	payload: {
		newRegistrations: RegistrationDocument[];
	};
}

export interface ConfirmRegistrationAction {
	type: typeof CONFIRM_REGISTRATION;
	payload: {
		newRegistration: RegistrationDocument;
	};
}

export interface RejectRegistrationAction {
	type: typeof REJECT_REGISTRATION;
	payload: {
		newRegistration: RegistrationDocument;
	};
}

export interface GetRegistrationsAction {
	type: typeof GET_REGISTRATIONS;
	payload: RegistrationDocument[];
}

export type RegistrationActionTypes =
	| AddRegistrationAction
	| ConfirmRegistrationAction
	| RejectRegistrationAction
	| GetRegistrationsAction;
