import { UPDATE_QUOTE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import { Quote } from '../../../../types';
import reducerConfig from '../../reducerConfig';

const updateQuoteRequest = (quoteId: string, quote: Quote, token: string) => {
	return axios.post(
		`${reducerConfig.api}/quote/update`,
		{
			quoteId,
			data: quote,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const updateQuoteAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_QUOTE,
		payload: res.data,
	};
};

const updateQuote = (quoteId: string, quote: Quote, token: string): any => {
	return (dispatch: any) => {
		return updateQuoteRequest(quoteId, quote, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(updateQuoteAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newSegment;
		});
	};
};

export default updateQuote;
