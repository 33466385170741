import { REMOVE_EXERCISE } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const removeExerciseRequest = (exerciseId: string, token: string) => {
	return axios.delete(`${reducerConfig.api}/exercise/remove/${exerciseId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const removeExerciseAction = (res: AxiosResponse, exerciseId: string) => {
	return {
		type: REMOVE_EXERCISE,
		payload: {
			exerciseId,
		},
	};
};

const removeExercise = (exerciseId: string, token: string): any => {
	return (dispatch: any) => {
		return removeExerciseRequest(exerciseId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(removeExerciseAction(res, exerciseId));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
		});
	};
};

export default removeExercise;
