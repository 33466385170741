import { BookDocument } from '../../../types';
import {
	GET_BOOK_CATEGORIES,
	ADD_BOOK_CATEGORY,
	UPDATE_BOOK_CATEGORY,
	BookCategoryActionTypes,
	REMOVE_BOOK_CATEGORY,
} from './constants';

const initialState: BookDocument[] = [];

const books = (state = initialState, action: BookCategoryActionTypes) => {
	switch (action.type) {
		case GET_BOOK_CATEGORIES:
			return action.payload;

		case ADD_BOOK_CATEGORY:
			return [...state, action.payload];

		case REMOVE_BOOK_CATEGORY:
			return state.filter(category => category._id !== action.payload.categoryId);

		case UPDATE_BOOK_CATEGORY:
			return state.map(book => {
				if (book._id === action.payload.newBookCategory._id) {
					return action.payload.newBookCategory;
				}
				return book;
			});

		default:
			return state;
	}
};

export default books;
