import { UPDATE_SEGMENT } from '../constants';
import axios, { AxiosResponse } from 'axios';
import addSnackbar from '../../../common/snackbars/actions/add';
import reducerConfig from '../../reducerConfig';

const addSegmentDependencyRequest = (segmentId: string, dependencyId: string, token: string) => {
	return axios.put(
		`${reducerConfig.api}/segment/add/dependency`,
		{
			segmentId,
			data: {
				dependencyId,
			},
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
};

const addSegmentDependencyAction = (res: AxiosResponse) => {
	return {
		type: UPDATE_SEGMENT,
		payload: res.data.newSegment,
	};
};

const addSegmentDependency = (segmentId: string, dependencyId: string, token: string): any => {
	return (dispatch: any) => {
		return addSegmentDependencyRequest(segmentId, dependencyId, token).then((res: AxiosResponse) => {
			if (res.data.success) {
				dispatch(addSegmentDependencyAction(res));
			}
			if (res.data.snackbars) {
				dispatch(addSnackbar(res.data.snackbars));
			}
			return res.data.newSegment;
		});
	};
};

export default addSegmentDependency;
