import get from './get';
import add from './add';
import update from './update';
import empty from './empty';
import remove from './remove';
import updateSort from './updateSort';

export default {
	get,
	add,
	update,
	empty,
	remove,
	updateSort,
};
