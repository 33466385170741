import { SegmentDocument } from '../../../../types';

export const ADD_SEGMENT = 'ADD_SEGMENT';
export const GET_SEGMENTS = 'GET_SEGMENTS';
export const UPDATE_SEGMENT = 'UPDATE_SEGMENT';
export const UPDATE_SEGMENT_SORT = 'UPDATE_SEGMENT_SORT';
export const REMOVE_SEGMENT = 'REMOVE';

export interface AddSegmentAction {
	type: typeof ADD_SEGMENT;
	payload: {
		newSegment: SegmentDocument;
	};
}

export interface UpdateSegmentAction {
	type: typeof UPDATE_SEGMENT;
	payload: SegmentDocument;
}

export interface UpdateSegmentSortAction {
	type: typeof UPDATE_SEGMENT_SORT;
	payload: SegmentDocument[];
}

export interface GetSegmentsAction {
	type: typeof GET_SEGMENTS;
	payload: SegmentDocument[];
}

export interface RemoveSegmentsAction {
	type: typeof REMOVE_SEGMENT;
	payload: {
		segmentId: string;
	};
}

export type SegmentActionTypes =
	| AddSegmentAction
	| GetSegmentsAction
	| UpdateSegmentAction
	| UpdateSegmentSortAction
	| RemoveSegmentsAction;
