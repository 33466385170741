import get from './get';
import getNew from './getNew';
import add from './add';
import addChapter from './addChapter';
import addEditor from './addEditor';
import update from './update';
import updateChapter from './updateChapter';
import removeChapter from './removeChapter';
import removeEditor from './removeEditor';

export default {
	get,
	getNew,
	add,
	addChapter,
	addEditor,
	update,
	updateChapter,
	removeChapter,
	removeEditor,
};
